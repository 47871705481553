import { requestHandler } from '../../api';

export const getActiveMoneyReports = async (
  dispatch: any,
  queryParams: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/report/activeMoney?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getTransactBookReport = async (
  dispatch: any,
  queryParams: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/report/transactBook?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getIncomeBookReport = async (
  dispatch: any,
  startDate: string,
  endDate: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/report/incomeBook`,
    data: { startDate, endDate },
    dispatch,
  });
  if (response) return response.data;
};

export const getInvoicingBookReport = async (
  dispatch: any,
  startDate: string,
  endDate: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/report/invoicingBook`,
    data: { startDate, endDate },
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentReport = async (dispatch: any, documentId: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/report/document/${documentId}`,
    dispatch,
  });
  if (response) return response.data;
};
