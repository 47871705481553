import { exportToExcel } from '../../../../../../../../lib/designSystem/DataExporter/utils/exportToExcel';
import { ExportProps, Headers } from '../../../Prospects.types';

const headers: Headers = [
  { key: 'businessName', label: 'Prospecto' },
  { key: 'businessIdentifier', label: 'Rut' },
  { key: '6M', label: '6M' },
  { key: 'payer1', label: 'Deudor 1' },
  { key: 'id1', label: 'Rut Deudor 1' },
  { key: 'amount1', label: 'Monto Deudor 1' },
  { key: 'payer2', label: 'Deudor 2' },
  { key: 'id2', label: 'Rut Deudor 2' },
  { key: 'amount2', label: 'Monto Deudor 2' },
  { key: 'payer3', label: 'Deudor 3' },
  { key: 'id3', label: 'Rut Deudor 3' },
  { key: 'amount3', label: 'Monto Deudor 3' },
];

export const export6MTop3PayersRolling = async ({
  prospectsMainTable,
  monthlyRollingData,
}: ExportProps) => {
  const data: any[] = [];

  for (const prospect of prospectsMainTable) {
    const row: any = {};
    row.businessName = prospect.businessName;
    row.businessIdentifier = prospect.businessIdentifier;
    row['6M'] = prospect['6M'];

    // Get three top payers by total amount per payer
    const payers: Record<string, any> = {};
    const prospectMonthlyRollingData =
      monthlyRollingData[prospect.businessIdentifier];
    for (const payer6M of prospectMonthlyRollingData.byStakeholder['6M']) {
      payers[payer6M.identifier] = {
        businessName: payer6M.name,
        identifier: payer6M.identifier,
        amount: payer6M.amount,
      };
    }
    const topThreePayers = Object.values(payers)
      .sort((a, b) => b.amount - a.amount)
      .slice(0, 3);

    // Add top three payers to row
    row.id1 = topThreePayers[0]?.identifier;
    row.payer1 = topThreePayers[0]?.businessName;
    row.amount1 = topThreePayers[0]?.amount;
    row.id2 = topThreePayers[1]?.identifier;
    row.payer2 = topThreePayers[1]?.businessName;
    row.amount2 = topThreePayers[1]?.amount;
    row.id3 = topThreePayers[2]?.identifier;
    row.payer3 = topThreePayers[2]?.businessName;
    row.amount3 = topThreePayers[2]?.amount;
    data.push(row);
  }

  await exportToExcel(data, headers, `Últimos 6 meses - top 3 deudores.xlsx`);
};
