import React from 'react';
import CollectionsIndicators from './components/Indicators';
import CollectionDocuments from './components/Documents';
import { validateCompanyTypes } from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { useSelector } from 'react-redux';

const CollectionsHome = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const canShowIndicators = validateCompanyTypes(user, 'FinancingHub');
  return (
    <div>
      {canShowIndicators && <CollectionsIndicators />}
      <CollectionDocuments />
    </div>
  );
};

export default CollectionsHome;
