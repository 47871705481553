import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import DateSelector from './components/Step1';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import {
  formatDate,
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../../lib/functions/utils';
import { getCompanyConfig } from '../../../../../../../lib/api/company';
import { expressGetCompanyConfig } from '../../../../../../../lib/api/express/company';
import { createExpressFactoringRequest } from '../../../../../../../lib/api/express';
import { Edit } from '@mui/icons-material';

const ShoppingCartFactoring = (props: any) => {
  const {
    business,
    selectedDocuments,
    setSelectedDocuments,
    openShoppingCart,
    setOpenShoppingcart,
    setSuccess,
    setFailed,
    setSuccesText,
    setFailedText,
    setContinueToSimulation,
    setSelectedRequest,
    subProductSelected,
    subProduct,
    setOpenDocumentsSelector,
    setBackwards,
    backwards,
    exchangeDay,
    setExchangeDay,
    operationDate,
    setOperationDate,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const [requestPayers, setRequestPayers] = useState<any>([]);
  const [canForcePaymentDate, setCanForcePaymentDate] = useState(false);
  const [fileStatusMandatory, setFileStatusMandatory] = useState(false);
  const [minimumDays, setMinimumDays] = useState(15);
  const [loading, setLoading] = useState(false);
  const [tempPaymentDateRows, setTempPaymentDateRows] = useState<any[]>(
    new Array(selectedDocuments.length).fill(null),
  );
  const [tempSubDocumentPaymentDateRows, setTempSubDocumentPaymentDateRows] =
    useState<any[]>(new Array(selectedDocuments.length).fill([]));
  const dispatch = useDispatch();
  const [confirmOperationDateNotToday, setConfirmOperationDateNotToday] =
    useState(false);
  const [
    hasToConfirmOperationDateNotToday,
    setHasToConfirmOperationDateNotToday,
  ] = useState(false);

  const handleCloseDialog = () => {
    setOpenShoppingcart(false);
  };

  const createSelectedRequestObject = () => {
    const documentsForRequest = selectedDocuments.map((document: any) => {
      const documents: any = [];
      if (document.subDocuments && document.subDocuments.length) {
        document.subDocuments.forEach((subDocument: any) => {
          documents.push({
            id: subDocument.id,
            amount: subDocument.amount,
            amountDocument: 0,
            amountFinanced: 0,
            amountRetention: 0,
            businessIdentifier: subDocument.businessIdentifier,
            issuedDate: subDocument.issuedDate,
            confirmDate: document.confirmDate,
            daysToCount: subDocument.daysToCount,
            folio: `${document.folio}-${subDocument.folio}`,
            subProductName: subProduct?.name,
            paymentDate: subDocument.paymentDate,
            cancelDate: subDocument.cancelDate,
            stakeholderName: subDocument.stakeholderName,
            stakeholderIdentifier: subDocument.stakeholderIdentifier,
            fatherDocumentId: document.id,
            active: true,
          });
        });
        return documents;
      } else {
        return [
          {
            id: document.id,
            amount: document.amount,
            amountDocument: 0,
            amountFinanced: 0,
            amountRetention: 0,
            businessIdentifier: document.businessIdentifier,
            issuedDate: document.issuedDate,
            daysToCount: document.daysToCount,
            confirmDate: document.confirmDate,
            cedded: 'No cedido',
            folio: document.folio,
            paymentDate: document.paymentDate,
            subProductName: subProduct?.name,
            cancelDate: document.cancelDate,
            stakeholderName: document.stakeholderName,
            stakeholderIdentifier: document.stakeholderIdentifier,
            active: true,
          },
        ];
      }
    });

    const documents = documentsForRequest.flat();

    console.log(operationDate);
    const request = {
      id: null,
      advance: 0,
      rate: 0,
      businessIdentifier: documents[0].businessIdentifier,
      businessName: business?.businessName,
      amount: documents.reduce((acc: any, curr: any) => acc + curr.amount, 0),
      amounToApply: 0,
      operationCost: 0,
      status: 'Creando',
      subProduct: subProductSelected,
      subProductName: subProduct?.name,
      documents: documents,
      requestDate: operationDate,
      x: 'aaa',
    };
    setSelectedRequest(request);
  };

  const validateDocumentsAndSubDocuments = () => {
    const duplicatedFolios: string[] = [];
    selectedDocuments.forEach((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        const subFolios = document.oldSubDocuments.map(
          (subDocument: any) => subDocument.folio,
        );
        document.subDocuments.forEach((subDocument: any) => {
          const key = `${document.folio}-${subDocument.folio}`;
          if (subFolios.includes(key)) {
            duplicatedFolios.push(key);
          }
        });
      }
    });
    if (duplicatedFolios.length) {
      alert(`Ya existen algunos sub folios. ${duplicatedFolios.join(', ')}`);
      return false;
    }

    const paymentDate = selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.paymentDate;
        });
      } else {
        return document.paymentDate;
      }
    });
    if (!paymentDate) {
      alert('Por favor, complete las fechas de pago');
      return false;
    }
    const amount = selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.amount;
        });
      } else {
        return document.amount;
      }
    });
    if (!amount) {
      alert('Por favor, complete los montos');
      return false;
    }
    const amountSubDocumentsLessThanFather = selectedDocuments.every(
      (document: any) => {
        if (document.subDocuments && document.subDocuments.length) {
          return (
            document.subDocuments.reduce(
              (acc: any, curr: any) => acc + curr.amount,
              0,
            ) <= document.amount
          );
        } else {
          return true;
        }
      },
    );
    if (!amountSubDocumentsLessThanFather) {
      alert('El monto de los documentos no puede ser mayor al del padre');
      return false;
    }
    return true;
  };

  const executeAccountantCreateRequest = async () => {
    if (!user || !user.clientId)
      return alert('No se ha podido obtener el usuario');
    if (!checkSelectedDocumentFileStatus()) {
      alert('Por favor, suba los PDF de las facturas en la solicitud.');
      return;
    }
    setLoading(true);
    try {
      await createExpressFactoringRequest(dispatch, user.clientId, {
        requestsDocuments: selectedDocuments,
        subProductId: 'electronic-invoice',
        documentsToApply: [],
      });
      setSuccesText('Solicitud enviada con éxito');
      setSuccess(true);
      setOpenShoppingcart(false);
      window.location.href = `/factoring/requests`;
    } catch (error) {
      setFailedText(`${error}`);
      setFailed(true);
    }
    setLoading(false);
  };

  const operationDateNotToday = () => {
    return (
      operationDate?.toISOString().split('T')[0] !==
      new Date().toISOString().split('T')[0]
    );
  };

  const handleFoward = async () => {
    if (validateCompanyTypes(user, 'FactoringQuoting')) {
      if (!validateDocumentsAndSubDocuments()) {
        return;
      }
      if (operationDateNotToday() && !confirmOperationDateNotToday) {
        alert('Por favor, confirma que la fecha de operación no es hoy');
        return;
      }
      createSelectedRequestObject();
      setContinueToSimulation();
      setOpenShoppingcart(false);
      return;
    } else if (validateCompanyTypes(user, 'Accountant')) {
      await executeAccountantCreateRequest();
      return;
    }
  };

  const handleBackwards = () => {
    if (validateCompanyTypes(user, 'Accountant')) {
      return setOpenShoppingcart(false);
    }
    setOpenDocumentsSelector(true);
    setOpenShoppingcart(false);
    setBackwards(true);
  };

  const stepChecker = () => {
    return !validatePaymentDays();
  };

  const validatePaymentDays = () => {
    return selectedDocuments.every((document: any) => {
      if (document.subDocuments && document.subDocuments.length) {
        return document.subDocuments.every((subDocument: any) => {
          return subDocument.paymentDate;
        });
      } else {
        return document.paymentDate;
      }
    });
  };

  const setUpRequestsInvoices = () => {
    const requestInvoicesArray = [];
    const payersObject: any = {};
    for (const document of selectedDocuments) {
      const paymentDate = document.paymentDate;
      const requestInvoice = { ...document, paymentDate };
      requestInvoicesArray.push(requestInvoice);
      if (!payersObject[requestInvoice.stakeholderIdentifier]) {
        payersObject[requestInvoice.stakeholderIdentifier] = {
          stakeholderName: requestInvoice.stakeholderName,
          paymentDays: null,
        };
      }
    }
    setSelectedDocuments(requestInvoicesArray);
    setRequestPayers(payersObject);
  };

  const setUp = async () => {
    setLoading(true);
    await fetchDateRestrictionsConfig();
    setUpRequestsInvoices();
    setLoading(false);
  };
  const getRestrictions = async () => {
    let system_operations_forcePaymentDate,
      system_operations_minimunDays,
      pruffConnect_operations_uploadPdfMandatory;
    if (user?.express) {
      if (!user.clientId) {
        return {
          system_operations_forcePaymentDate,
          system_operations_minimunDays,
          pruffConnect_operations_uploadPdfMandatory,
        };
      }
      ({
        system_operations_forcePaymentDate,
        system_operations_minimunDays,
        pruffConnect_operations_uploadPdfMandatory,
      } = await expressGetCompanyConfig(dispatch, user.clientId, [
        'system_operations_forcePaymentDate',
        'system_operations_minimunDays',
        'pruffConnect_operations_uploadPdfMandatory',
      ]));
    } else {
      system_operations_forcePaymentDate = await getCompanyConfig(
        dispatch,
        'system_operations_forcePaymentDate',
      );
      system_operations_minimunDays = await getCompanyConfig(
        dispatch,
        'system_operations_minimunDays',
      );
      pruffConnect_operations_uploadPdfMandatory = await getCompanyConfig(
        dispatch,
        'pruffConnect_operations_uploadPdfMandatory',
      );
    }
    return {
      system_operations_forcePaymentDate,
      system_operations_minimunDays,
      pruffConnect_operations_uploadPdfMandatory,
    };
  };

  const fetchDateRestrictionsConfig = async () => {
    try {
      const {
        system_operations_forcePaymentDate,
        system_operations_minimunDays,
        pruffConnect_operations_uploadPdfMandatory,
      } = await getRestrictions();

      setCanForcePaymentDate(Number(system_operations_forcePaymentDate) === 1);
      setFileStatusMandatory(
        Number(pruffConnect_operations_uploadPdfMandatory) === 1,
      );
      if (system_operations_minimunDays)
        setMinimumDays(Number(system_operations_minimunDays));
    } catch (error) {
      console.error('Error fetching date restrictions config', error);
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttonText = () => {
    if (validateCompanyTypes(user, 'Accountant')) {
      return 'Enviar solicitud';
    }
    if (validateCompanyTypes(user, 'FactoringQuoting')) {
      return 'Ir a simulador';
    }
  };

  const resetPaymentDates = () => {
    const requestsInvoicesWithDates = [];
    for (const requestInvoice of selectedDocuments) {
      const newRI = {
        ...requestInvoice,
        paymentDate: null,
        dueDate: null,
        daysToCount: 0,
      };
      if (requestInvoice.subDocuments) {
        for (const subDocument of newRI.subDocuments) {
          subDocument.paymentDate = null;
          subDocument.dueDate = null;
          subDocument.daysToCount = 0;
        }
      }
      requestsInvoicesWithDates.push(newRI);
    }
    const tempPaymentDateRows = new Array(selectedDocuments.length).fill(null);
    setTempPaymentDateRows(tempPaymentDateRows);
    const tempSubDocumentPaymentDateRows = selectedDocuments.map(
      (document: any) =>
        new Array(
          document.subDocuments ? document.subDocuments.length : 0,
        ).fill(null),
    );
    setTempSubDocumentPaymentDateRows(tempSubDocumentPaymentDateRows);
    setSelectedDocuments(requestsInvoicesWithDates);
  };

  const handleExchangeDay = () => {
    resetPaymentDates();
    setExchangeDay(!exchangeDay);
  };

  const checkSelectedDocumentFileStatus = () => {
    console.log('selectedDocuments', selectedDocuments);
    console.log('fileStatusMandatory', fileStatusMandatory);
    if (!fileStatusMandatory) return true;
    return selectedDocuments.every((document: any) => {
      return document.fileStatus;
    });
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleEditOperationDate = (event: any) => {
    // Can be less than issued date form document
    const inputDate = new Date(event.target.value);

    for (const document of selectedDocuments) {
      if (inputDate < new Date(document.issuedDate)) {
        alert('La fecha de operación no puede ser menor a la fecha de emisión');
        return;
      }
    }
    // We have to reset the document dates
    resetPaymentDates();
    // Set to 12:00:00
    inputDate.setUTCHours(12);
    setOperationDate(inputDate);
    setOpenEditDialog(false);
  };

  const disabledEditOperationDate =
    validateCompanyTypes(user, 'Accountant') ||
    !validatePermissions(user, 'financing_factoring_edit_operation_date');

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openShoppingCart}
        onClose={() => handleCloseDialog()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              Solicitud de financiamiento
            </span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
              }}
            >
              Fecha de operación:
              {operationDate?.toISOString().split('T')[0]}
              <IconButton
                onClick={() => setOpenEditDialog(true)}
                disabled={disabledEditOperationDate}
                style={{ marginLeft: '5px' }}
              >
                <Edit />
              </IconButton>
            </span>
          </div>
        </DialogTitle>

        <div style={{ minHeight: 100 }}>
          {loading ? (
            <CenteredCircularProgress type="dialog" />
          ) : (
            <div>
              <DateSelector
                selectedDocuments={selectedDocuments}
                requestPayers={requestPayers}
                setRequestPayers={setRequestPayers}
                setSelectedDocuments={setSelectedDocuments}
                exchangeDay={exchangeDay}
                setTempPaymentDateRows={setTempPaymentDateRows}
                tempPaymentDateRows={tempPaymentDateRows}
                setTempSubDocumentPaymentDateRows={
                  setTempSubDocumentPaymentDateRows
                }
                tempSubDocumentPaymentDateRows={tempSubDocumentPaymentDateRows}
                backwards={backwards}
                canForcePaymentDate={canForcePaymentDate}
                minimumDays={minimumDays}
                fileStatusMandatory={fileStatusMandatory}
                operationDate={operationDate}
                setOperationDate={setOperationDate}
              />
              {operationDateNotToday() && (
                <DialogContent>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmOperationDateNotToday}
                        onChange={() =>
                          setConfirmOperationDateNotToday(
                            !confirmOperationDateNotToday,
                          )
                        }
                      />
                    }
                    label="Confirmar que la fecha de operación no es hoy"
                  />
                </DialogContent>
              )}
              <DialogActions>
                {!user?.express && (
                  <CustomButton
                    onClick={() => handleExchangeDay()}
                    color="warning"
                  >
                    {exchangeDay ? 'Deshabilitar' : 'Habilitar'} canje
                  </CustomButton>
                )}
                <CustomButton
                  onClick={() => handleBackwards()}
                  color="secondary"
                >
                  {validateCompanyTypes(user, 'Accountant') ? 'Salir' : 'Atrás'}
                </CustomButton>
                <CustomButton
                  color="primary"
                  disabled={stepChecker()}
                  onClick={() => handleFoward()}
                >
                  {buttonText()}
                </CustomButton>
              </DialogActions>
            </div>
          )}
        </div>
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
          <DialogTitle>Editar fecha de operación</DialogTitle>
          <DialogContent style={{ paddingTop: 12 }}>
            <TextField
              fullWidth
              label="Fecha de operación"
              type="date"
              value={operationDate ? formatDate(operationDate) : null}
              onChange={handleEditOperationDate}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              color="secondary"
              onClick={() => setOpenEditDialog(false)}
            >
              Cerrar
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Dialog>
    </div>
  );
};

export default ShoppingCartFactoring;
