import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import Uploader from '../../../../../../../../../lib/designSystem/Uploader';
import Papa from 'papaparse';

import { formatDateBack } from '../../../../../../../../../lib/functions/utils';
import { checkSIIDocumentForUploadingInvoices } from '../../../../../../../../../lib/api/sii';

const AddInvoicesCSV = (props: any) => {
  const { loading, business, setInvoices } = props;
  const dispatch = useDispatch();
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const handleFileChange = async (event: any) => {
    const uploadedFile = event.target.files[0];
    const parsedFile = await parseCSV(uploadedFile);
    console.log('parsedFile', parsedFile);
    const formattedFile = formatedCSV(parsedFile);
    console.log('formattedFile', formattedFile);
    await validateDocuments(formattedFile);
  };

  const formatedCSV = (parsedFile: any) => {
    return parsedFile.map((invoice: any) => {
      const [day, month, year] = invoice['Fecha Recepcion']
        .split(' ')[0]
        .split('/');
      const issuedDate = `${day}${month}${year}`;
      return {
        folio: invoice.Folio,
        businessIdentifier: business.identifier,
        stakeholderName: invoice['Razon Social'],
        stakeholderIdentifier: invoice['Rut cliente'],
        issuedDate,
        amount: invoice['Monto total'],
      };
    });
  };

  const validateDocuments = async (invoices: any) => {
    try {
      const response = await checkSIIDocumentForUploadingInvoices(dispatch, {
        invoices: [...invoices],
      });
      if (response.length) {
        const failedInvoices = response.filter(
          (invoice: any) => !invoice.valid,
        );
        const validInvoicesFolios = response
          .filter((invoice: any) => invoice.valid)
          .map((invoice: any) => invoice.folio);
        if (failedInvoices.length) {
          const validInvoices = invoices.filter((invoice: any) =>
            validInvoicesFolios.includes(invoice.folio),
          );
          const invoicesWithDateFormat = validInvoices.map((invoice: any) => {
            return {
              ...invoice,
              issuedDate: formatDateBack(invoice.issuedDate),
            };
          });
          setInvoices(invoicesWithDateFormat);
          alert(
            `Los siguientes documentos no son validos: ${failedInvoices
              .map((invoice: any) => invoice.folio)
              .join(', ')}`,
          );
          return false;
        }
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const parseCSV = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div style={{ padding: '10px 0px' }}>
      <Typography variant="body1">
        Deja e csv para procesar las facturas que quieres ingresar
      </Typography>
      <Uploader
        handleFileChange={handleFileChange}
        buttonText="Agregar RCV"
        accept=".csv"
        uploadedFileName={uploadedFileName}
      />
    </div>
  );
};

export default AddInvoicesCSV;
