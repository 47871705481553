import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import GTRangeAssign from './GTRangeAssign';
import LTRangeAssign from './LTRangeAssign';
import EQRangeAssign from './EQRangeAssign';

const NewMatrixAssignation = (props: any) => {
  const {
    setDecisionNodesAssignation,
    decisionNodesAssignation,
    decisionNodes,
    openAddNode,
    setOpenAddNode,
    newNode,
    setNewNode,
  } = props;
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(
    decisionNodesAssignation.length
      ? decisionNodesAssignation[decisionNodesAssignation.length - 1].id + 1
      : 1,
  );
  const [operation, setOperation] = useState('gt');

  const validateRanges = () => {
    if (newNode.ranges.length < 2) {
      return alert('Debes agregar al menos dos rangos');
    }
    const ranges = newNode.ranges;
    for (let i = 0; i < ranges.length; i++) {
      if (ranges[i].decisionNodeId === '' || ranges[i].value === null) {
        console.log('i', i, ranges.length);
        if (i === ranges.length - 1) {
          return true;
        }
        return alert('Debes completar todos los rangos');
      }
    }
    return true;
  };
  const addNode = () => {
    console.log('newNode', newNode);
    if (!validateRanges()) {
      return;
    }
    if (newNode.originNodeId === '') {
      return alert('Debes completar todos los camposa');
    }
    const nodes = [...decisionNodesAssignation];
    nodes.push({ ...newNode });
    const nextCounter = counter;
    setCounter(nextCounter + 1);
    setDecisionNodesAssignation(nodes);
    setOpenAddNode(false);
    setNewNode({
      id: 0,
      sonIncorrectNodeId: null,
      sonCorrectNodeId: null,
      originNodeId: '',
      value: '',
      type: '',
      description: '',
      name: '',
      fatherNodeDecision: null,
      ranges: [],
    });
  };

  const addRange = () => {
    const range = {
      value: '',
      decisionNodeId: newNode.id,
    };
    setNewNode({ ...newNode, ranges: [...newNode.ranges, range] });
  };

  const setUpRangeForBoolean = () => {
    const ranges = [
      {
        value: 0,
        decisionNodeId: newNode.id,
        nodeId: '',
      },
      {
        value: 1,
        decisionNodeId: newNode.id,
        nodeId: '',
      },
    ];
    return ranges;
  };

  const handleChangeRange = (e: any, index: any) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, value);
    const ranges = newNode.ranges.map((x: any, i: any) => {
      if (i === index) {
        return { ...x, [name]: value };
      }
      return x;
    });
    setNewNode({ ...newNode, ranges });
  };

  const handleChangeBoolean = (e: any) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, value, newNode);
    const ranges = newNode.ranges.map((x: any, i: any) => {
      if (i === (name === 'correct' ? 1 : 0)) {
        return { ...x, nodeId: value };
      }
      return x;
    });
    console.log('ranges', ranges);
    setNewNode({ ...newNode, operation: 'eq', ranges });
  };

  const handleChangeOrigin = (e: any) => {
    const { value } = e.target;
    const { name } = e.target;
    console.log(name, value);
    if (name === 'originNodeId') {
      const node = decisionNodes.find((x: any) => x.id === value);
      console.log('node', node);

      return setNewNode({
        ...newNode,
        [name]: value,
        value: node.value,
        type: node.type,
        operation: node.operation || 'gt',
        description: node.description,
        name: node.name,
        id: counter,
        ranges: node.type === 'boolean' ? setUpRangeForBoolean() : [],
      });
    }
    setNewNode({ ...newNode, [name]: value });
  };

  const deleteRange = (index: any) => {
    const ranges = newNode.ranges.filter((x: any, i: any) => i !== index);
    ranges[ranges.length - 1].max = null;
    setNewNode({ ...newNode, ranges });
  };

  const availableNodeForSelection = () => {
    const selectedIds = decisionNodesAssignation.map(
      (x: any) => x.originNodeId,
    );
    return decisionNodes.filter(
      (x: any) => x.type !== 'final' && !selectedIds.includes(x.id),
    );
  };
  const availableFinalNodeForSelection = () => {
    return decisionNodes.filter((x: any) => x.type === 'final');
  };

  const handleChangeOperation = (e: any) => {
    const { value } = e.target;
    console.log('value', value);
    setOperation(value);
    setNewNode({ ...newNode, operation: value, ranges: [] });
  };

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <Dialog
      maxWidth="md"
      open={openAddNode}
      onClose={() => setOpenAddNode(false)}
    >
      <DialogTitle>Agregar evaluación</DialogTitle>
      <DialogContent style={{ width: '500px' }}>
        <Typography variant="body1" gutterBottom>
          Acá podrás definir una nueva evaluación dentro de la matriz.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Evaluación a agregar</b>
        </Typography>
        <div style={{ padding: '10px 0px' }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="filter-label">Evaluaciones disponibles</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              name="originNodeId"
              value={newNode.originNodeId}
              onChange={(e: any) => handleChangeOrigin(e)}
              label="Evaluaciones disponibles"
            >
              {availableNodeForSelection().map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {newNode.originNodeId !== '' && (
          <div style={{ padding: '20ox' }}>
            <Typography variant="body1" gutterBottom>
              {newNode.description}
            </Typography>
          </div>
        )}
        {newNode.type === 'boolean' ? (
          <div>
            <Typography variant="body1" gutterBottom>
              <b>Opciones</b>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel id="filter-label">
                {' '}
                Cuando se cumple la evaluación asignar
              </InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                name="correct"
                margin="dense"
                value={newNode.ranges[1]?.nodeId}
                onChange={(e: any) => handleChangeBoolean(e)}
                label="Cuando se cumple la evaluación asignar"
              >
                {availableFinalNodeForSelection().map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel id="filter-label">
                {' '}
                Cuando no se cumple la evaluación asignar
              </InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                name="incorrect"
                value={newNode.ranges[0]?.nodeId}
                onChange={(e: any) => handleChangeBoolean(e)}
                label="Cuando no se cumple la evaluación asignar"
              >
                {availableFinalNodeForSelection().map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div>
            <div>
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel id="filter-label">Operación</InputLabel>
                <Select
                  labelId="filter-label"
                  id="Filtrar por estado"
                  name="operation"
                  value={operation}
                  onChange={(e: any) => handleChangeOperation(e)}
                  label="Operación"
                >
                  <MenuItem value="gt">Mayor a menor</MenuItem>
                  <MenuItem value="lt">Menor a mayor</MenuItem>
                  <MenuItem value="eq">Igual a</MenuItem>
                </Select>
              </FormControl>
            </div>
            {operation === 'eq' && (
              <EQRangeAssign
                newNode={newNode}
                addRange={addRange}
                handleChangeRange={handleChangeRange}
                deleteRange={deleteRange}
                availableFinalNodeForSelection={availableFinalNodeForSelection}
              />
            )}
            {operation === 'gt' && (
              <GTRangeAssign
                newNode={newNode}
                addRange={addRange}
                handleChangeRange={handleChangeRange}
                deleteRange={deleteRange}
                availableFinalNodeForSelection={availableFinalNodeForSelection}
              />
            )}
            {operation === 'lt' && (
              <LTRangeAssign
                newNode={newNode}
                addRange={addRange}
                handleChangeRange={handleChangeRange}
                deleteRange={deleteRange}
                availableFinalNodeForSelection={availableFinalNodeForSelection}
              />
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenAddNode(false)}
          color="secondary"
          variant="contained"
        >
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => addNode()}
          color="primary"
          variant="contained"
        >
          Agregar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewMatrixAssignation;
