import React, { useEffect, useState } from 'react';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import { useNavigate, useParams } from 'react-router-dom';
import Payouts from './components/PayOuts';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import PayIns from './components/PayIns';
import Accounts from './components/Accounts';
import FNC from './components/FNC';
// import Discounts from './components/Discounts';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../lib/functions/utils';
import { AuthState } from '../../../../services/redux/authSlice';
import { useSelector } from 'react-redux';

const Payments = () => {
  const { subPage } = useParams<{ page: string; subPage: string }>();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const tabs: {
    label: string;
    component: any;
    path: string;
    index: number;
    disabled?: boolean;
  }[] = [];
  if (validatePermissions(user, 'financing_payout_view')) {
    tabs.push({
      label: 'Enviados',
      component: <Payouts />,
      path: 'out',
      index: 0,
    });
  }
  if (validatePermissions(user, 'financing_payIn_view')) {
    tabs.push({
      label: 'recibidos',
      component: <PayIns />,
      path: 'in',
      index: 1,
      disabled: !validatePermissions(user, 'financing_payIn_view'),
    });
    tabs.push({
      label: 'Cuentas',
      component: <Accounts />,
      path: 'accounts',
      index: 3,
      disabled: !validatePermissions(user, 'financing_payIn_view'),
    });
  }
  // if (validatePermissions(user, 'financing_discount_view')) {
  //   tabs.push({
  //     label: 'Descuentos',
  //     component: <Discounts />,
  //     path: 'discounts',
  //     index: 2,
  //     disabled: !validatePermissions(user, 'financing_discount_view'),
  //   });
  // }
  if (validatePermissions(user, 'financing_fnc_view')) {
    tabs.push({
      label: 'FNC',
      component: <FNC />,
      path: 'fnc',
      index: 4,
      disabled: !validatePermissions(user, 'financing_fnc_view'),
    });
  }

  const [loading, setLoading] = useState(true);
  const [viewTabs, setViewTabs] = useState<any>([]);
  const navigate = useNavigate();

  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/finance/payments/${viewTabs[index].path}`);
  };

  const setUpTabs = async () => {
    const newPaymentTabs: any = [tabs[0], tabs[1]];
    if (validateCompanyTypes(user, 'FinancingHub')) {
      tabs.forEach((tab) => {
        if (!tab.disabled) {
          newPaymentTabs.push(tab);
        }
      });
    }
    console.log(newPaymentTabs);

    const uniqueTabs = newPaymentTabs.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((t: any) => t.path === v.path) === i,
    );

    let startValueDefault = 0;
    startValueDefault = uniqueTabs.findIndex((x: any) => x.path === subPage);
    setViewTabs(uniqueTabs);
    console.log(uniqueTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUpTabs();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTab
        startValue={startValue}
        tabs={viewTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default Payments;
