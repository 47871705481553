import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import {
  getAllPayouts,
  getRecieverFactoringRequest,
} from '../../../../../../lib/api';
import { getCompanyPayoutGroup } from '../../../../../../lib/api/payments';
import { useDispatch, useSelector } from 'react-redux';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NewPayOut from './components/MainDialog';
import PayOutDetails from './components/Details';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { validatePermissions } from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';

const headers: { key: string; label: string; type?: any }[] = [
  // { key: 'businessOriginName', label: 'Cuenta Origen' },
  // { key: 'bankAccountOriginName', label: 'Banco Origen' },
  { key: 'businessName', label: 'Cuenta Destino' },
  // { key: 'bankAccountDestinationName', label: 'Banco Destino' },
  { key: 'sourceTranslation', label: 'Fuente' },
  { key: 'sourceId', label: 'Id' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'executedAt', label: 'Completada', type: 'date' },
];

const payoutsRawHeaders: any = [
  { key: 'bankAccountOriginName', label: 'Banco Origen' },
  // { key: 'bankAccountOriginName', label: 'Banco Origen' },
  { key: 'businessDestinationName', label: 'Cliente Destino' },
  { key: 'bankAccountDestinationName', label: 'Banco Destino' },
  { key: 'sourceTranslation', label: 'Fuente' },
  { key: 'sourceId', label: 'Id' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'executedAt', label: 'Completada', type: 'date' },
];

const Payouts: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [openDetails, setOpenDetails] = useState(false);
  const [filteredPayouts, setFilteredPayouts] = useState<any>([]);
  const [allPayOuts, setAllPayOuts] = useState<any>([]);
  const [paymentGroupedDetails, setPaymentGroupedDetails] = useState<any>({
    source: '',
    sourceId: null,
    sourceTranslation: '',
  });
  const [view, setView] = useState<any>('payments');

  const fetchPaymentsOut = async () => {
    setLoading(true);
    try {
      const response = await getAllPayouts(dispatch);
      setPayouts(response.payments);
      setFilteredPayouts(response.payments);
      setAllPayOuts(response.payouts);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPaymentsOut();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nuevo pago',
      action: handleOpen,
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_payout_new'),
    },
  ];

  const handleDetails = async (id: string) => {
    const [source, sourceId] = id.split('/');
    console.log('id', id);
    const payment: any = payouts.find(
      (payout: any) => payout.source === source && payout.sourceId === sourceId,
    );
    console.log('payment', payment);
    if (payment) {
      try {
        setLoading(true);
        let paymentGroupedDetailResponse = await getCompanyPayoutGroup(
          dispatch,
          payment.source,
          payment.sourceId,
        );
        if (payment.source === 'factoring-request') {
          const requestRsponse = await getRecieverFactoringRequest(dispatch, {
            requestId: payment.sourceId,
          });
          paymentGroupedDetailResponse = {
            ...paymentGroupedDetailResponse,
            ...requestRsponse,
          };
        }
        setPaymentGroupedDetails(paymentGroupedDetailResponse);
        setOpenDetails(true);
      } catch (error) {
        console.log('error', error);
        alert('Error al cargar los pagos');
      }
      setLoading(false);
    }
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Detalles',
    },
  ];

  const [filters, setFilters] = useState<any>({
    executedAt: null,
    source: null,
  });

  const updateOrAddFilter = (key: string, value: string) => {
    // Update filter if exists, or add new one if not
    const newFilters = { ...filters };
    newFilters[key] = null;
    if (value !== '') {
      // Assuming empty string means no filter
      newFilters[key] = value;
    }
    applyFilters(newFilters);
  };

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    let payoutsFiltered = payouts;

    if (Object.values(newFilters).every((value) => value === null)) {
      setFilteredPayouts(payoutsFiltered);
      return;
    }

    if (newFilters.executedAt !== null) {
      payoutsFiltered = payoutsFiltered.filter((payout: any) =>
        newFilters.executedAt === 'Pendientes'
          ? !payout.executedAt
          : payout.executedAt,
      );
    }
    if (newFilters.source !== null) {
      payoutsFiltered = payoutsFiltered.filter(
        (payout: any) => payout.sourceTranslation === newFilters.source,
      );
    }

    setFilteredPayouts(payoutsFiltered);
  };

  // Handler for the executed state filter
  const handleFilterPaid = (value: string) => {
    updateOrAddFilter('executedAt', value);
  };

  // Handler for the source filter
  const handleFilterSource = (value: string) => {
    updateOrAddFilter('source', value);
  };

  const Filter = (
    <div>
      <FormControl style={{ minWidth: '200px', marginRight: '4px' }}>
        <InputLabel id="filter-executed-label">Filtrar por estado</InputLabel>
        <Select
          labelId="filter-executed-label"
          id="filter-by-executed"
          fullWidth
          value={filters.executedAt}
          onChange={(e) => handleFilterPaid(e.target.value)}
          label="Filtrar por estado"
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="Pendientes">Pendientes</MenuItem>
          <MenuItem value="Ejecutados">Ejecutados</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ minWidth: '200px' }}>
        <InputLabel id="filter-source-label">Filtrar por fuente</InputLabel>
        <Select
          labelId="filter-source-label"
          id="filter-by-source"
          fullWidth
          value={filters.source}
          onChange={(e) => handleFilterSource(e.target.value)}
          label="Filtrar por fuente"
        >
          <MenuItem value="">Todos</MenuItem>
          {/* Assuming 'sources' is an array of sources */}
          {[
            ...new Set(payouts.map((payout: any) => payout.sourceTranslation)),
          ].map((source: any, index: number) => (
            <MenuItem key={index} value={source}>
              {source}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        customTitle={
          <Typography variant="body1">
            {view === 'payments'
              ? 'Acá puedes ver los pagos agrupados por fuente. '
              : 'Acá puedes ver los pagos realizados.'}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() =>
                setView(view === 'payments' ? 'grouped' : 'payments')
              }
            >
              {view === 'payments'
                ? ' Ver todos las transferencias'
                : ' Ver pagos agrupados'}
            </span>
          </Typography>
        }
        variant="body1"
        buttons={buttons}
        item={view === 'payments' ? Filter : null}
      />
      {view === 'payments' ? (
        <EnhancedTable
          exportToFile="xlsx"
          dataHeaders={headers}
          data={filteredPayouts}
          rowsPerPageDefault={50}
          actions={actions}
          showActions
        />
      ) : (
        <EnhancedTable
          exportToFile="xlsx"
          dataHeaders={payoutsRawHeaders}
          data={allPayOuts}
          rowsPerPageDefault={50}
        />
      )}
      {open && (
        <NewPayOut
          setOpen={setOpen}
          open={open}
          setSuccessOpen={setSuccess}
          setFailureOpen={setFailure}
          setUp={fetchPaymentsOut}
        />
      )}
      {openDetails && (
        <PayOutDetails
          open={openDetails}
          setOpen={setOpenDetails}
          paymentGroupedDetails={paymentGroupedDetails}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchPaymentsOut}
        />
      )}

      <CustomAlert
        open={success !== ''}
        title="Éxito"
        onClose={() => {
          setSuccess('');
        }}
        text={success}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure !== ''}
        title="Error"
        onClose={() => {
          setFailure('');
        }}
        text={failure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Payouts;
