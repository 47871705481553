import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import DebtProjector from './components/DebtProjector';
import {
  getActiveMoneyReports,
  getDocumentReport,
} from '../../../../../../lib/api/reports';
import TableWithPagination from '../../../../../../lib/designSystem/TableWithPagination';
import ActiveMoneySearcher from './components/ActiveMoneySearcher';
import { Receipt } from '@mui/icons-material';
import { getCompanyLogo } from '../../../../../../lib/api/company';
import { expressGetCompanyLogo } from '../../../../../../lib/api/express';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { generateDocumentReportPDF } from '../../../Financing/common/exportDocumentReport';

const ActiveMoney: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [dataType, setDataType] = useState('active');
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [documentType, setDocumentType] = useState('factoring');
  const [factoringRequestId, setFactoringRequestId] = useState('');
  const [folio, setFolio] = useState('');
  const dispatch = useDispatch();
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (documentType) query += `documentType=${documentType}&`;
    if (folio) query += `folio=${folio}&`;
    if (dataType) query += `dataType=${dataType}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (factoringRequestId)
      query += `factoringRequestId=${factoringRequestId}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const setUp = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    const query = generateQuery({ page, pageSize });
    try {
      const response = await getActiveMoneyReports(dispatch, query);
      setData(response.data);
      setPagination(response.pagination);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setUp({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  const buttons: CustomButtonProps[] = [
    {
      action: () => setOpen(true),
      name: 'Proyectar mora',
      color: 'secondary',
      disabled: selectedDocuments.length === 0 ? true : false,
    },
    {
      action: () => {},
      color: 'primary',
      customButton: (
        <ActiveMoneySearcher
          searchData={setUp}
          setData={setData}
          setLoading={setLoading}
          dataType={dataType}
          setBusinessIdentifier={setBusinessIdentifier}
          businessIdentifier={businessIdentifier}
          setStakeholderIdentifier={setStakeholderIdentifier}
          stakeholderIdentifier={stakeholderIdentifier}
          setFactoringRequestId={setFactoringRequestId}
          factoringRequestId={factoringRequestId}
          setDocumentType={setDocumentType}
          documentType={documentType}
          pagination={pagination}
          setDataType={setDataType}
          setFolio={setFolio}
          folio={folio}
        />
      ),
    },
  ];
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const handleDownloadReport = async (id: string) => {
    setLoading(true);
    try {
      // const documentsApplied = await getDocumentsApplied();
      const response = await getDocumentReport(dispatch, id);
      const imageBase64 = user?.express
        ? await expressGetCompanyLogo(dispatch, user.clientId as string)
        : await getCompanyLogo(dispatch);
      await generateDocumentReportPDF(response, user?.name || '', imageBase64);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDownloadReportObject = {
    key: 'downloadReport',
    label: '',
    handleAction: handleDownloadReport,
    icon: <Receipt />,
  };

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <Container style={{ padding: 0, margin: 0 }}>
      <div>
        <HeaderWithActions
          title="Acá encontrarás todos los documentos de tu cartera de clientes."
          variant="body1"
          buttons={buttons}
        />
        <TableWithPagination
          dataHeaders={[
            handleDownloadReportObject,
            { key: 'businessIdentifier', label: 'Rut cliente', type: 'report' },
            { key: 'businessName', label: 'Nombre cliente' },
            { key: 'economicSector', label: 'Sector económico' },
            { key: 'executive', label: 'Ejecutivo' },
            { key: 'stakeholderIdentifier', label: 'Rut deudor' },
            { key: 'stakeholderName', label: 'Nombre deudor' },
            { key: 'operationId', label: 'Id Solicitud' },
            { key: 'operationDate', label: 'Fecha operación', type: 'date' },
            { key: 'documentType', label: 'Tipo documento' },
            { key: 'documentNumber', label: 'Número documento' },
            { key: 'amount', label: 'Monto documento', type: 'money' },
            { key: 'dueDate', label: 'Fecha liberación', type: 'date' },
            { key: 'conciliationAmount', label: 'Abono', type: 'money' },
            {
              key: 'lastConciliationDate',
              label: 'Fecha último abono',
              type: 'date',
            },
            { key: 'leftAmount', label: 'Saldo documento', type: 'money' },
            {
              key: 'anticipatedAmount',
              label: 'Monto anticipado',
              type: 'money',
            },
            {
              key: 'priceDifference',
              label: 'Diferencia de precio',
              type: 'money',
            },
            { key: 'dailyAccrual', label: 'Devengo diario', type: 'money' },
            { key: 'collectionStatus', label: 'Estado cobranza' },
            { key: 'arrearDays', label: 'Días mora' },
            { key: 'arrearAmount', label: 'Interés mora', type: 'money' },
            { key: 'documentState', label: 'Estado documento' },
            { key: 'fundType', label: 'Fondo' },
          ]}
          data={data}
          selected={selectedDocuments}
          setSelected={setSelectedDocuments}
          rowsPerPageDefault={100}
          loading={loading}
          searchData={setUp}
          exportToFile="xlsx"
          pagination={pagination}
          setPagination={setPagination}
          setData={setData}
        />
        <DebtProjector
          open={open}
          documents={data.filter((document: any) =>
            selectedDocuments.includes(document.id),
          )}
          setOpen={setOpen}
        />
      </div>
    </Container>
  );
};

export default ActiveMoney;
