import React, { useEffect, useState } from 'react';
import {
  deleteCollectionManagementById,
  getCollectionManagementsByCompanyId,
} from '../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { validatePermissions } from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../lib/designSystem/HeaderWithActions';
import DocumentSearcher from '../../../../../../../../lib/common/DocumentSearcher';
import ConfirmAndExecuteDialog from '../../../../../../../../lib/designSystem/ConfrimAndExecuteDialog';
import {
  completeCollectionManagement,
  unDoCompleteCollectionManagement,
} from '../../../../../../../../lib/api/collections';
import TableWithPagination from '../../../../../../../../lib/designSystem/TableWithPagination';
import DetailedCollectionManagement from '../../../Home/components/Documents/components/DetailedManagement';
import NewManagement from '../../../Home/components/Documents/components/NewManagement';
import DocumentSelector from './components/DocumentSelector/indext';
import EditManagement from './components/EditManagement';

const CollectionsManagementsCreatedByUser = (props: any) => {
  const { completed } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [collectionsData, setCollectionsData] = useState<any[]>([]);
  const [sucessMessage, setSucessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [documentType, setDocumentType] = useState('factoring');
  const PAGE_SIZE = 10;
  const [openEditManagement, setOpenEditManagement] = useState(false);
  const [openEditManagementId, setOpenEditManagementId] = useState(0);
  const [description, setDescription] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
  const [confirmDialogDescription, setConfirmDialogDescription] = useState('');
  const [detailsManagementOpen, setDetailsManagementOpen] = useState(false);
  const [collectionManagementId, setCollectionManagementId] = useState(0);
  const [confirmAction, setConfirmAction] = useState<() => Promise<void>>(
    () => Promise.resolve,
  );
  const [buttonColor, setButtonColor] = useState<
    'primary' | 'error' | 'warning'
  >('primary');

  const handleConfirmCompleteDialog = (id: number) => {
    setConfirmDialogTitle('Completar gestión ' + `#${id}`);
    setConfirmDialogDescription(
      '¿Estás seguro de que deseas completar la gestión?',
    );
    setConfirmAction(() => async () => await handleCompleteFinal(id)); // Corregido
    setButtonColor('primary');
    setConfirmDialogOpen(true);
  };

  const handleConfirmUnDoCompleteDialog = (id: number) => {
    setConfirmDialogTitle('Descompletar gestión ' + `#${id}`);
    setConfirmDialogDescription(
      '¿Estás seguro de que deseas descompletar la gestión?',
    );
    setConfirmAction(() => async () => await handleUnDoComplete(id)); // Corregido
    setButtonColor('warning');
    setConfirmDialogOpen(true);
  };

  const handleConfirmDeleteDialog = (id: number) => {
    setConfirmDialogTitle('Eliminar gestión ' + `#${id}`);
    setConfirmDialogDescription(
      '¿Estás seguro de que deseas eliminar la gestión?',
    );
    setConfirmAction(() => async () => await handleDelete(id)); // Corregido
    setButtonColor('error');
    setConfirmDialogOpen(true);
  };

  const handleComplete = async (id: number) => {
    const management = collectionsData.find(
      (management) => management.id === id,
    );
    setOpenEditManagement(true);
    setOpenEditManagementId(id);
    setDescription(management.description);
  };

  const handleCompleteFinal = async (id: number) => {
    if (!id) return;

    setLoading(true);
    try {
      await completeCollectionManagement(dispatch, id, {
        newDescription: description,
      });
      setSucessMessage('Gestión completada');
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage('Error al completar la gestión');
    }
    setLoading(false);
  };

  const handleUnDoComplete = async (id: number) => {
    if (!id) return;

    setLoading(true);
    try {
      await unDoCompleteCollectionManagement(dispatch, id);
      setSucessMessage('Gestión descompletada');
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage('Error al descompletar la gestión');
    }
  };

  const handleDelete = async (id: number) => {
    if (!id) return;

    setLoading(true);
    try {
      await deleteCollectionManagementById(dispatch, id);
      setSucessMessage('Gestión eliminada');
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage('Error al eliminar la gestión');
    }
    setLoading(false);
  };

  const handleDetailsManagement = (id: number) => {
    setDetailsManagementOpen(true);
    setCollectionManagementId(id);
  };

  const actions = [
    {
      action: handleDetailsManagement,
      actionLabel: 'Ver detalles',
    },
    {
      action: handleConfirmDeleteDialog,
      actionLabel: 'Eliminar',
    },
    ...(!completed
      ? [
          {
            action: handleComplete,
            actionLabel: 'Completar',
          },
        ]
      : [
          {
            action: handleConfirmUnDoCompleteDialog,
            actionLabel: 'Descompletar',
          },
        ]),
  ];

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (documentType) query += `documentType=${documentType}&`;
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (completed) query += `completed=${completed}&`;

    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchCollectionsManagements = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getCollectionManagementsByCompanyId(
        dispatch,
        query,
      );
      setCollectionsData(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const isAdmin = validatePermissions(user, 'collection_admin');

  const headers: { key: string; label: string; type: any }[] = [
    { key: 'id', label: 'ID', type: 'id' },
    { key: 'elementType', label: 'Tipo', type: 'collection-type' },
    { key: 'description', label: 'Descripción', type: '' },
    { key: 'folio', label: 'Documento', type: '' },
    { key: 'stakeholderName', label: 'Deudor', type: '' },
    { key: 'stakeholderIdentifier', label: 'Rut', type: '' },
    { key: 'state', label: 'Estado gestión', type: '' },
    ...(isAdmin ? [{ key: 'userOwnerName', label: 'Usuario', type: '' }] : []),
    ...(completed
      ? [{ key: 'completedAt', label: 'Completado', type: 'date' }]
      : [{ key: 'dueDate', label: 'Vence', type: 'date' }]),
  ];

  const reloadPage = () => {
    window.location.reload();
  };

  const [openCreateNewManagement, setOpenCreateNewManagement] = useState(false);
  const [openDocumentSelector, setOpenDocumentSelector] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleConfirmSelection = (document: any) => {
    setSelectedDocument(document);
    console.log(document);
    setOpenDocumentSelector(false);
    setOpenCreateNewManagement(true);
  };

  useEffect(() => {
    searchCollectionsManagements({
      page: 0,
      pageSize: PAGE_SIZE,
    });
  }, []);

  const createButton: CustomButtonProps = {
    name: 'Crear gestión',
    action: () => setOpenDocumentSelector(true),
    color: 'primary',
  };

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title={completed ? 'Completadas' : 'Pendientes'}
        variant={'body1'}
        fontWeight="bold"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                documentType={documentType}
                setDocumentType={setDocumentType}
                searchData={searchCollectionsManagements}
                setData={setCollectionsData}
                setLoading={setLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                businessIdentifier={businessIdentifier}
                setBusinessIdentifier={setBusinessIdentifier}
                pagination={pagination}
              />
            ),
          },
          ...(!completed ? [createButton] : []),
        ]}
      />
      <TableWithPagination
        dataHeaders={headers}
        exportToFile="xlsx"
        data={collectionsData}
        rowsPerPageDefault={PAGE_SIZE}
        actions={actions}
        showActions
        setData={setCollectionsData}
        searchData={searchCollectionsManagements}
        pagination={pagination}
        setPagination={setPagination}
      />
      <ConfirmAndExecuteDialog
        open={!!confirmDialogOpen}
        title={confirmDialogTitle}
        description={confirmDialogDescription}
        onConfirm={confirmAction}
        onCancel={() => setConfirmDialogOpen(false)}
        setOpen={setConfirmDialogOpen}
        buttonColor={buttonColor}
      />
      {detailsManagementOpen && (
        <DetailedCollectionManagement
          openModal={detailsManagementOpen}
          setOpenModal={setDetailsManagementOpen}
          collectionManagementId={collectionManagementId}
          handleComplete={handleComplete}
        />
      )}
      {openCreateNewManagement && (
        <NewManagement
          openModal={openCreateNewManagement}
          setOpenModal={setOpenCreateNewManagement}
          document={selectedDocument}
          setOpenSucessAlert={setSucessMessage}
          setOpenErrorAlert={setErrorMessage}
          setUp={reloadPage}
        />
      )}
      {openDocumentSelector && (
        <DocumentSelector
          open={openDocumentSelector}
          setOpen={setOpenDocumentSelector}
          handleConfirmSelection={handleConfirmSelection}
        />
      )}
      {openEditManagement && (
        <EditManagement
          openEditManagementId={openEditManagementId}
          open={openEditManagement}
          setOpen={setOpenEditManagement}
          setDescription={setDescription}
          description={description}
          handleCompleteFinal={handleConfirmCompleteDialog}
        />
      )}
      <CustomAlert
        open={!!sucessMessage}
        title={sucessMessage}
        onClose={() => {
          setSucessMessage('');
        }}
        text=""
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={!!errorMessage}
        title={errorMessage}
        onClose={() => {
          setErrorMessage('');
        }}
        text=""
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default CollectionsManagementsCreatedByUser;
