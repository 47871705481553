import React, { useState } from 'react';
import { Typography } from '@mui/material';
import EnhancedTable from '../../../../../../../../../../../../../../../lib/designSystem/Table';

const SubProductConcentrationContainer = (props: any) => {
  const { subProductsConcentrations } = props;

  return (
    <div style={{ padding: '10px 0px' }}>
      {' '}
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Producto' },
          { key: 'advance', label: 'Anticipo' },
          { key: 'spread', label: 'Spread' },
          { key: 'arrearRate', label: 'Tasa mora' },
          { key: 'maxFinancingDays', label: 'Días' },
          { key: 'operationCostFixed', label: 'C. fijo', type: 'money' },
          {
            key: 'operationCostVariable',
            label: 'C. variable',
          },
          { key: 'amount', label: 'Monto', type: 'money' },
        ]}
        data={subProductsConcentrations}
      />
    </div>
  );
};

const StakeholderConcentrationContainer = (props: any) => {
  const { stakeholderConcentrations } = props;

  return (
    <div style={{ padding: '10px 0px' }}>
      {' '}
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Nombre' },
          { key: 'businessIdentifier', label: 'Rut' },
          { key: 'amount', label: 'Monto', type: 'money' },
        ]}
        data={stakeholderConcentrations}
      />
    </div>
  );
};
const CreditLineConcentrations = (props: any) => {
  const { stakeholdersConcentrations, subProductsConcentrations } = props;
  const [concentrationExpanded, setConcentrationExpanded] =
    useState('stakeholder');

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body1">
          <b>Concentraciones</b>
        </Typography>
        <div style={{ display: 'flex' }}>
          <Typography
            variant="caption"
            color={concentrationExpanded !== 'stakeholder' ? 'primary' : 'grey'}
            style={{
              cursor:
                concentrationExpanded !== 'stakeholder' ? 'pointer' : 'default',
            }}
            onClick={() => setConcentrationExpanded('stakeholder')}
          >
            Deudor
          </Typography>
          <Typography
            variant="caption"
            color={concentrationExpanded !== 'subproduct' ? 'primary' : 'grey'}
            style={{
              marginLeft: 10,
              cursor:
                concentrationExpanded !== 'subproduct' ? 'pointer' : 'default',
            }}
            onClick={() => setConcentrationExpanded('subproduct')}
          >
            Subproductos
          </Typography>
        </div>
      </div>
      {concentrationExpanded === 'stakeholder' ? (
        <StakeholderConcentrationContainer
          stakeholderConcentrations={stakeholdersConcentrations}
        />
      ) : (
        <SubProductConcentrationContainer
          subProductsConcentrations={subProductsConcentrations}
        />
      )}
    </div>
  );
};

export default CreditLineConcentrations;
