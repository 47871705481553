import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { Add } from '@mui/icons-material';
import {
  formatDateBack,
  formatMoney,
  formatMoneyBack,
  validaRut,
} from '../../../../../../../../lib/functions/utils';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { createDocumentsFromCompany } from '../../../../../../../../lib/api';

const AddDocumentsManually = (props: any) => {
  const { open, setOpen, subProductSelected, business, setSuccessOpen } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [newDocument, setNewDocument] = useState<any>({
    folio: '',
    businessIdentifier: business.identifier,
    stakeholderIdentifier: '',
    stakeholderName: '',
    issuedDate: '',
    amount: '',
  });

  const existingFolio = (folio: string) => {
    return documents.some((invoice: any) => invoice.folio === folio);
  };

  const validateRuts = () => {
    if (validaRut(newDocument.stakeholderIdentifier)) {
      return true;
    }
    return false;
  };

  const checkAllFields = () => {
    if (
      newDocument.folio === '' ||
      newDocument.businessIdentifier === '' ||
      newDocument.stakeholderIdentifier === '' ||
      newDocument.issuedDate === '' ||
      newDocument.amount === '' ||
      newDocument.stakeholderName === ''
    ) {
      alert('Debes llenar todos los campos');
      return false;
    }
    return true;
  };

  const handleChangeDate = (e: any) => {
    const [year, month, day] = e.target.value.split('-');
    setNewDocument({ ...newDocument, issuedDate: `${day}${month}${year}` });
  };

  const uploadDocuments = async () => {
    setLoading(true);
    try {
      const formatDocumentsWithDate = documents.map((invoice) => {
        return {
          ...invoice,
          issuedDate: new Date(invoice.issuedDate).toISOString(),
          amount: parseFloat(invoice.amount),
        };
      });
      const response = await createDocumentsFromCompany(dispatch, {
        documents: formatDocumentsWithDate,
        businessIdentifier: business.identifier,
        subProductId: subProductSelected,
      });
      console.log(response);
      setOpen(false);
      setSuccessOpen('Documentos agregados correctamente');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleAddInvoice = async () => {
    setLoading(true);
    if (existingFolio(newDocument.folio)) {
      alert('Ya existe una factura con este folio');
      return;
    }
    if (!checkAllFields()) return;
    if (!validateRuts()) {
      alert('RUT inválido');
      return;
    }
    newDocument.issuedDate = formatDateBack(newDocument.issuedDate);
    setDocuments((prev: any) => [...prev, newDocument]);
    setNewDocument({
      folio: '',
      businessIdentifier: '',
      stakeholderIdentifier: '',
      stakeholderName: '',
      issuedDate: '',
      amount: '',
    });
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <HeaderWithActions title="Agregar documentos" type="dialogHeader" />
      </DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          <Typography variant="body1">
            A continuación, ingresa los datos de los documentos que quieres
            ingresar.
          </Typography>
          <div style={{ padding: '20px 0px' }}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Folio"
                  value={newDocument.folio}
                  onChange={(e) =>
                    setNewDocument({ ...newDocument, folio: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="RUT receptor *"
                  value={newDocument.stakeholderIdentifier}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      stakeholderIdentifier: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={newDocument.stakeholderName}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      stakeholderName: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Fecha de Emisión"
                  type="date"
                  value={formatDateBack(newDocument.issuedDate)}
                  onChange={(e) => handleChangeDate(e)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Monto"
                  value={formatMoney(newDocument.amount)}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      amount: formatMoneyBack(e.target.value),
                    })
                  }
                />
              </Grid>

              <Grid item xs={1}>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleAddInvoice();
                    }}
                  >
                    <Add />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Typography variant="caption">
              * Rut sin puntos y con guión
            </Typography>
          </div>
          {documents.length > 0 && (
            <div>
              <Typography variant="body1">Documentos:</Typography>
              <div>
                <EnhancedTable
                  dataHeaders={[
                    { key: 'folio', label: 'Folio' },
                    { key: 'stakeholderIdentifier', label: 'Rut receptor' },
                    { key: 'stakeholderName', label: 'Nombre receptor' },
                    {
                      key: 'issuedDate',
                      label: 'Fecha emisión',
                    },
                    { key: 'amount', label: 'Monto', type: 'money' },
                  ]}
                  data={documents}
                  finalSumRow
                />
              </div>
            </div>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          variant="contained"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          {' '}
          Salir
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          disabled={!documents.length}
          onClick={() => {
            uploadDocuments();
          }}
        >
          {' '}
          Subir
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddDocumentsManually;
