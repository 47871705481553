import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCollectionsManagementsIndicators } from '../../../../../../../../lib/api/collections';
import { getCollectionsDocumentIndicators } from '../../../../../../../../lib/api/collections';
import { useDispatch } from 'react-redux';
import EnhacedBox from '../../../../../../../../lib/designSystem/Box';
import CustomPieChart from './components/PieChart';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';

const CollectionsIndicators = () => {
  const [loading, setLoading] = useState(true);
  const [documentsCountData, setDocumentsCountData] = useState([]);
  const [documentsAmountData, setDocumentsAmountData] = useState([]);
  const [showDocumentType, setShowDocumentType] = useState('count');

  const [dueByBusiness, setDueByBusiness] = useState([]);
  const [dueByStakeholder, setDueByStakeholder] = useState([]);
  const [showDueBy, setShowDueBy] = useState('business');

  const [dueManagementsData, setDueManagementsData] = useState([]);
  const [nextManagementsData, setNextManagementsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [selectedEntryType, setSelectedEntryType] = useState<any>(null);

  const dispatch = useDispatch();

  const setUpCollectionsIndicators = async () => {
    setLoading(true);
    try {
      const documentsIndicators =
        await getCollectionsDocumentIndicators(dispatch);
      setDocumentsCountData(documentsIndicators.chartData.countData);
      setDocumentsAmountData(documentsIndicators.chartData.amountData);

      setDueByBusiness(documentsIndicators.chartData2.dueByBusiness);
      setDueByStakeholder(documentsIndicators.chartData2.dueByStakeholder);

      const managementsIndicators =
        await getCollectionsManagementsIndicators(dispatch);
      setDueManagementsData(managementsIndicators.chartData.dueManagementsData);
      setNextManagementsData(
        managementsIndicators.chartData.nextManagementsData,
      );
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handlePieDocumentsChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setSelectedEntryType('documents');
    setOpenDialog(true);
  };

  const handlePieManagementsChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setSelectedEntryType('managements');
    setOpenDialog(true);
  };

  useEffect(() => {
    setUpCollectionsIndicators();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  const minHeight = 270;

  return (
    <div style={{ padding: '16px 0px' }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                {showDocumentType === 'count'
                  ? 'Documentos por vencer'
                  : 'Monto por vencer'}
              </Typography>
              <div style={{ padding: '12px 0px' }}>
                <CustomPieChart
                  data={
                    showDocumentType === 'count'
                      ? documentsCountData
                      : documentsAmountData
                  }
                  onClick={handlePieDocumentsChartClick}
                  money={showDocumentType === 'amount'}
                  colors={['#14ba25', '#e7eb2a', '#db6723', '#D62839']}
                />
              </div>
              <Typography
                variant="body2"
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: 'blue',
                }}
                onClick={() =>
                  setShowDocumentType(
                    showDocumentType === 'count' ? 'amount' : 'count',
                  )
                }
              >
                {showDocumentType === 'count'
                  ? 'Ver por monto'
                  : 'Ver por documentos'}
              </Typography>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                {showDueBy === 'business'
                  ? 'Monto vencido por cliente'
                  : 'Monto vencido por deudor'}
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={
                    showDueBy === 'business' ? dueByBusiness : dueByStakeholder
                  }
                  onClick={handlePieDocumentsChartClick}
                  money
                  colors={['#07D2FE', '#D62839']}
                />
              </div>
              <Typography
                variant="body2"
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: 'blue',
                }}
                onClick={() =>
                  setShowDueBy(
                    showDueBy === 'business' ? 'stakeholder' : 'business',
                  )
                }
              >
                {showDueBy === 'business'
                  ? 'Ver por deudor'
                  : 'Ver por cliente'}
              </Typography>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                Gestiones vencidas
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={dueManagementsData}
                  onClick={handlePieManagementsChartClick}
                  colors={['#db6723', '#D62839']}
                />
              </div>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                Gestiones por vencer
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={nextManagementsData}
                  onClick={handlePieManagementsChartClick}
                  colors={['#14ba25', '#e7eb2a']}
                />
              </div>
            </div>
          </EnhacedBox>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{selectedEntry?.name}</DialogTitle>
        <DialogContent>
          <EnhancedTable
            dataHeaders={
              selectedEntryType === 'documents'
                ? [
                    { key: 'folio', label: 'Folio' },
                    { key: 'businessName', label: 'Cliente' },
                    { key: 'stakeholderName', label: 'Pagador' },
                    { key: 'paymentDate', label: 'Vencimiento', type: 'date' },
                    { key: 'amount', label: 'Monto', type: 'money' },
                  ]
                : [
                    { key: 'id', label: 'ID' },
                    { key: 'type', label: 'Tipo' },
                    { key: 'folio', label: 'Folio' },
                    { key: 'businessName', label: 'Cliente' },
                    { key: 'stakeholderName', label: 'Pagador' },
                    { key: 'dueDate', label: 'Vencimiento', type: 'date' },
                    { key: 'createdAt', label: 'Creado', type: 'date' },
                  ]
            }
            data={selectedEntry?.documents || []}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CollectionsIndicators;
