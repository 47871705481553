import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const formatMoney = (value: number) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0, // No decimals
    maximumFractionDigits: 0, // No decimals
  });
  if (!value) return `$0`;
  if (formatter.format(value) === 'NaN') return `${value}`;
  return `$${formatter.format(value)}`;
};

export const formatDate = (date: Date, format?: string) => {
  const dateObject = new Date(date);
  dateObject.setHours(dateObject.getHours());
  const year = dateObject.getUTCFullYear();
  const month = dateObject.getUTCMonth() + 1; // Add 1 because months are zero-based
  const day = dateObject.getUTCDate();
  const hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes();

  switch (format) {
    case 'large':
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    case 'yyyy-mm-dd':
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    case 'dd-mm-yyyy':
      return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    default:
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }
};

export const validateChileanPhone = (phone: string) => {
  // Nospaces
  // +56912345678
  // 56912345678
  return phone.match(/(\+56|56)?(\s)?(\d{9})/) && phone.length === 11;
};

export const visibilityOptions = [
  { id: 'full_visibility', name: 'Visiblidad total' },
  { id: 'partial_visibility', name: 'Visiblidad parcial' },
  { id: 'no_visibility', name: 'Sin visiblidad' },
];

export const visibilityDurationOptions = [
  { id: 'always_visible', name: 'Sin límite' },
  { id: 'temporary_visible', name: 'Temporal' },
];

export function validateParams(
  params: any,
  requiredFields: string[],
  setFormErrorMessage: any,
): boolean {
  for (const field of requiredFields) {
    if (
      params[field] === null ||
      params[field] === undefined ||
      params[field] === ''
    ) {
      setFormErrorMessage(`Missing required field: ${field}`);
      return false;
    }
  }
  return true;
}
const RUT_REGEX = /^\d{1,2}.\d{3}.\d{1,3}-[k|K|\d]{1}$/;

export function validaRut(rut: string): boolean {
  // Check for the presence of a period and return false if found
  if (rut.includes('.')) return false;

  // Your existing RUT_REGEX test
  if (!RUT_REGEX.test(rut)) return false;

  const parts = rut.replace(/\./g, '').split('-');
  const numero: string = parts[0];
  const dv: string = parts[1].toUpperCase();
  let factor: number = 2;
  let suma: number = 0;

  for (let i = numero.length - 1; i >= 0; i--) {
    suma += parseInt(numero.charAt(i), 10) * factor;
    factor = factor === 7 ? 2 : factor + 1;
  }

  let mod: number | string = 11 - (suma % 11);
  if (mod === 11) mod = 0;
  if (mod === 10) mod = 'K';

  return mod.toString() === dv;
}

export function validatePermissions(user: any, requiredPermission?: string) {
  console.log(user);
  const admin = user.roles.find((x: any) => x.id === 4 || x.id === 1);
  if (admin) return true;

  if (!requiredPermission) return false;
  const hasPermission = user?.roles.some((role: any) =>
    role.permissions.some(
      (permission: any) => permission.name === requiredPermission,
    ),
  );
  return hasPermission;
}

export function validateCompanyTypes(user: any, requiredType?: string) {
  // const admin = user.roles.find((x: any) => x.id === 4 ||  x.id === 1)
  // if (admin) return true

  if (!requiredType) return false;

  const hasPermission = user?.companyTypes.some(
    (type: any) => type.name === requiredType,
  );

  return hasPermission;
}

export function isAdmin(user: any) {
  const admin = user.roles.find((x: any) => x.id === 4 || x.id === 1);
  if (admin) return true;
  return false;
}

export function isSuperAdmin(user: any) {
  const superAdmin = user.roles.find((x: any) => x.id === 4);
  if (superAdmin) return true;
  return false;
}

export function calculateInterest(
  monthlyRate: number,
  retention: number,
  amountTotal: number,
  paymentDate: Date,
) {
  const dailyRate = monthlyRate / 100 / 30;
  const retentionDecimal = retention / 100;
  const effectiveAmount = amountTotal * (1 - retentionDecimal);

  const today = new Date();
  const timeDiff = paymentDate.getTime() - today.getTime();
  const daysUntilPayment = Math.ceil(timeDiff / (1000 * 3600 * 24));

  const totalInterest = effectiveAmount * dailyRate * daysUntilPayment;
  return totalInterest;
}

export const renderSelectedNames = (selectedIds: any, array: any) => {
  return selectedIds
    .map((id: any) => {
      const foundItem = array.find((item: any) => item.id === id);
      return foundItem ? foundItem.name : '';
    })
    .filter((name: any) => name) // Filter out any undefined or empty names
    .join(', ');
};

export const handleSelectChange = (e: any, setMethod: any, oldValue: any) => {
  const value = e.target.value;
  // Check if the target has the 'options' property, which is true for multiple selects
  const isMultiple = e.target.options;

  if (isMultiple) {
    // If it's a multiple select, create an array of the selected values
    const selectedValues = Array.from(e.target.options)
      .filter((option: any) => option.selected)
      .map((option: any) => option.value);
    setMethod({
      ...oldValue,
      [e.target.name]: selectedValues,
    });
  } else {
    // If it's a single select, set the value as it is
    setMethod({
      ...oldValue,
      [e.target.name]: value,
    });
  }
};

export const generatScorePDF = (assgination: any, rows: any, user: any) => {
  const doc = new jsPDF();
  let startY = 20; // Starting Y position for the first table

  const { name, businessName, businessIdentifier, rating, score } = assgination;
  doc.setFontSize(14);
  // summary table
  const leftStart = 14;
  const left2column = 120;
  doc.text('Ficha Clasificación Riesgo', leftStart, startY); // Adjust the Y position for the title
  //
  startY = startY + 10;
  const startVariables = leftStart + 25;
  const startVariables2column = left2column + 30;

  doc.setFontSize(10);
  doc.text('Razón social: ', leftStart, startY);
  doc.text(businessName, startVariables, startY);

  doc.text('Rut: ', leftStart, startY + 5);
  doc.text(businessIdentifier, startVariables, startY + 5);

  doc.text('Clasficación: ', leftStart, startY + 15);
  doc.text(rating, startVariables, startY + 15);

  doc.text('Score: ', leftStart, startY + 20);
  doc.text(score, startVariables, startY + 20);

  doc.text('Plantilla utilizada: ', left2column, startY + 15);
  doc.text(name, startVariables2column, startY + 15);

  doc.text('Fecha evaluación: ', left2column, startY + 20);
  doc.text(
    new Date().toISOString().split('T')[0],
    startVariables2column,
    startY + 20,
  );

  doc.text('Ejecutivo: ', leftStart, startY + 30);
  doc.text(user.name, startVariables, startY + 30);

  // if (user.companyId){
  //   doc.addImage(`https://pruff-public.s3.amazonaws.com/company-logos/${user.companyId}.png`, 'JPEG', 100, startY, 60, 40);
  // }

  startY = startY + 30;

  const columns = [
    { title: 'Concepto', dataKey: 'name' },
    { title: 'Peso', dataKey: 'weight' },
    { title: 'Evaluación', dataKey: 'description' },
    { title: 'Input Score', dataKey: 'inputScore' },
    { title: 'Observaciones', dataKey: 'observation' },
  ];

  autoTable(doc, {
    columns: columns,
    startY: startY + 5,
    body: rows,
    theme: 'grid',
    headStyles: {
      fillColor: '#efefef', // or '#2980b9' in hex format
      textColor: '#1b1b1b',
      fontStyle: 'bold',
      halign: 'left', // horizontal alignment
      valign: 'middle', // vertical alignment
      lineColor: [44, 62, 80],
      fontSize: 12,
    },
    bodyStyles: {
      textColor: [50, 50, 50],
      fontSize: 8,
    },
    styles: {
      font: 'helvetica', // choose the font
      lineColor: [44, 62, 80],
      lineWidth: 0.1,
    },
    // For each column, you can define individual styles as well, for example:
    columnStyles: {
      0: { fontStyle: 'bold' }, // Specific styles for the first column (index 0)
    },
    // Add other options as needed
  });

  // Save the PDF
  doc.save(`${businessName}/Score.pdf`);
};

export const flattenArrayObject = (array: any[]): any => {
  // receives an array of object with sub object inside and returns an array of objects with all the keys
  return array.map((item: any) => {
    const newItem: any = {};
    for (const key of Object.keys(item)) {
      if (typeof item[key] === 'object' && item[key] !== null) {
        for (const subKey of Object.keys(item[key])) {
          if (
            typeof item[key][subKey] === 'object' &&
            item[key][subKey] !== null
          ) {
            for (const subSubKey of Object.keys(item[key][subKey])) {
              newItem[key + '_' + subKey + '_' + subSubKey] =
                item[key][subKey][subSubKey];
            }
          } else {
            newItem[key + '_' + subKey] = item[key][subKey];
          }
        }
      } else if ('type' === key) {
        newItem['element_' + key] = item[key];
      } else {
        newItem[key] = item[key];
      }
    }
    return newItem;
  });
};

export const calculateRequestDetails = (selectedRequest: any) => {
  const formData = {
    advance: selectedRequest.advance,
    rate: selectedRequest.rate,
    operationCostComissionFixed:
      selectedRequest.operationCostComissionFixed ||
      selectedRequest.operationCostFixed ||
      0,
    operationCostComissionVariable:
      selectedRequest.operationCostComissionVariable ||
      selectedRequest.operationCostVariable ||
      0,
    expensesCost: selectedRequest.expensesCost || 0,
    amountToApply: selectedRequest.amountToApply,
  };

  let operationAmount = 0;
  let operationAdvanced = 0;
  let operationCostRate = 0;
  const documentsWithDetails = [];
  for (const document of selectedRequest.documents) {
    const documentAmount = document.amount * (document.active ? 1 : 0);
    const { daysToCount } = document;
    const amountFinanced = Math.round(
      documentAmount * (formData.advance / 100),
    );
    const amountRetention = Math.round(
      documentAmount * (1 - formData.advance / 100),
    );
    const amountDocument = documentAmount - amountRetention;
    const costRate = daysToCount
      ? Math.round((daysToCount / 30) * (formData.rate / 100) * amountFinanced)
      : 0;
    operationAmount += documentAmount;
    operationAdvanced += amountFinanced;
    operationCostRate += costRate;
    const amountToDeposit = amountFinanced - costRate;

    documentsWithDetails.push({
      ...document,
      amountDocument,
      amountFinanced,
      amountRetention,
      amountToDeposit,
      costRate,
    });
  }
  const operationIva = Math.round(
    0.19 *
      (formData.operationCostComissionFixed +
        Math.round(formData.operationCostComissionVariable)),
  );

  const expensesIva = Math.round(0.19 * formData.expensesCost);
  const operationToDepost =
    operationAdvanced -
    formData.operationCostComissionVariable -
    formData.operationCostComissionFixed -
    formData.expensesCost -
    operationCostRate -
    formData.amountToApply -
    operationIva -
    expensesIva;
  return {
    advance: formData.advance,
    rate: formData.rate,
    operationAmount,
    operationAdvanced,
    operationCostRate,
    operationAmountToApply: formData.amountToApply,
    operationToDepost,
    operationIva,
    expensesIva,
    operationCostComissionFixed: formData.operationCostComissionFixed,
    operationCostComissionVariable: formData.operationCostComissionVariable,
    expensesCost: formData.expensesCost,
    documentsWithDetails,
  };
};

export const formatDateBack = (date: string | Date) => {
  if (!date) return '';
  if (typeof date !== 'string') date = date.toString();
  console.log(date);
  const day = date.slice(0, 2);
  const month = date.slice(2, 4);
  const year = date.slice(4, 8);
  return `${year}-${month}-${day}`;
};

export const formatMoneyBack = (value: string): number => {
  return parseInt(value.split('$').join('').split('.').join('')) || 0;
};

export const isValidRate = (value: string) => {
  if (value === '') {
    return true;
  }
  if (isNaN(Number(value))) {
    return false;
  }
  const valueNumber = parseFloat(value);
  if (valueNumber >= 0 && valueNumber <= 100) {
    return true;
  } else {
    return false;
  }
};

export const copyToClipboard = async (str: string) => {
  try {
    await navigator.clipboard.writeText(str);
    console.log('Texto copiado al portapapeles!');
  } catch (err) {
    console.error('No se pudo copiar el texto: ', err);
  }
};
