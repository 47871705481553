import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import { formatMoney } from '../../../../../../../lib/functions/utils';
import { formatDateToLocal } from '../../../../../../../lib/designSystem/Table/cellFormat';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%',
    },
    tableCell: {
      padding: '8px !important',
      overflow: 'hidden',
      minWidth: '50px',
      maxWidth: '180px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableCellTasa: {
      padding: '8px !important',
      overflow: 'hidden',
      maxWidth: '50px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
      textAlign: 'right',
    },
    tableCellNames: {
      padding: '8px !important',
      overflow: 'hidden',
      maxWidth: '80px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    textField: {
      fontSize: '14px !important',
      padding: '8px !important',
    },
    textFieldAutocomplete: {
      fontSize: '14px !important',
      padding: '0px !important',
      minWidth: '120px !important',
    },
    iconButton: {
      padding: '5px',
    },
  }),
);

const MajorBookEntriesTable = (props: any) => {
  const { entries } = props;
  const [expandedDays, setExpandedDays] = useState<Record<number, boolean>>({});
  const classes = useStyles();

  const toggleDay = (dayIndex: number) => {
    setExpandedDays((prev) => ({ ...prev, [dayIndex]: !prev[dayIndex] }));
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Cuenta</TableCell>
            <TableCell>Nombre </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {entries.map((day: any, dayIndex: number) => (
            <React.Fragment key={dayIndex}>
              {/* Nivel 1: Día */}
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {day.account}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {day.accountName}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  <IconButton onClick={() => toggleDay(dayIndex)}>
                    {expandedDays[dayIndex] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </TableCell>
              </TableRow>

              {expandedDays[dayIndex] && (
                <TableRow>
                  <TableCell colSpan={3} style={{ padding: 0 }}>
                    <Collapse
                      in={expandedDays[dayIndex]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Descripción</TableCell>
                            <TableCell>Debe</TableCell>
                            <TableCell>Haber</TableCell>
                            <TableCell>Débito</TableCell>
                            <TableCell>Crédito</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {day.groupedLines.map(
                            (category: any, catIndex: number) => (
                              <React.Fragment key={`${dayIndex}-${catIndex}`}>
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    {formatDateToLocal(category.date)}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {category.description}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {formatMoney(category.debit)}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {formatMoney(category.credit)}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {formatMoney(category.debitFinal)}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {formatMoney(category.creditFinal)}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ),
                          )}
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'left',
                              }}
                            >
                              Totales:
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                              {formatMoney(
                                day.groupedLines.reduce(
                                  (sum: number, line: any) => sum + line.debit,
                                  0,
                                ),
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                              {formatMoney(
                                day.groupedLines.reduce(
                                  (sum: number, line: any) => sum + line.credit,
                                  0,
                                ),
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                              {formatMoney(
                                day.groupedLines.reduce(
                                  (sum: number, line: any) =>
                                    sum + line.debitFinal,
                                  0,
                                ),
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                              {formatMoney(
                                day.groupedLines.reduce(
                                  (sum: number, line: any) =>
                                    sum + line.creditFinal,
                                  0,
                                ),
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MajorBookEntriesTable;
