import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import Collection from './Components/Collection';
import Financing from './Components/Financing';
import Payments from './Components/Payments';
import { useSelector } from 'react-redux';
import { AuthState } from '../../services/redux/authSlice';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../lib/functions/utils';
import Reporting from './Components/Reporting';
import FinanceSII from './Components/SII';
import {
  checkCollectionsMainPage,
  checkFinancingMainPage,
  checkPaymentsMainPage,
} from '../../lib/permissionChecks';
import { setInitialTab } from '../../lib/functions/setInitialTab';
import Accounting from './Components/Accounting';

const Finance: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const companyTabs: any = [];
  if (!checkFinancingMainPage(user))
    companyTabs.push({
      label: 'Financiamiento',
      component: <Financing />,
      mainPage: 'financing',
      subPage: 'creditLine',
      index: 1,
      disabled: checkFinancingMainPage(user),
    });
  if (!checkPaymentsMainPage(user))
    companyTabs.push({
      label: 'Pagos',
      component: <Payments />,
      mainPage: 'payments',
      subPage: 'out',
      index: 2,
    });
  if (!checkCollectionsMainPage(user))
    companyTabs.push({
      label: 'Cobranza',
      component: <Collection />,
      mainPage: 'collections',
      subPage: 'documents',
      index: 3,
    });
  console.log(validatePermissions(user, 'businesses_read_all'));
  if (validatePermissions(user, 'businesses_read_all'))
    companyTabs.push({
      label: 'Reportes',
      component: <Reporting />,
      mainPage: 'reporting',
      subPage: 'activeMoney',
      index: 4,
      disabled: !validatePermissions(user, 'businesses_read_all'),
    });
  if (validatePermissions(user, 'accountability_read_all'))
    companyTabs.push({
      label: 'Contabilidad',
      component: <Accounting />,
      mainPage: 'accounting',
      subPage: 'dailyBook',
      index: 5,
    });
  console.log(companyTabs);

  const theBiller = {
    label: 'SII',
    component: <FinanceSII />,
    mainPage: 'sii',
    subPage: 'invoicing',
    index: 5,
  };
  const { mainPage } = useParams<{ page: string; mainPage: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [compnayTabs, setCompanyTabs] = useState<any>(companyTabs);
  const [startValue, setStartValue] = useState(0);

  const handleNavigation = (index: number) => {
    navigate(
      `/finance/${compnayTabs[index].mainPage}/${compnayTabs[index].subPage}`,
    );
  };

  const setTabs = async () => {
    setLoading(true);
    const newCompanyTabs: any = [];
    const canAccessToAllTabs = validateCompanyTypes(user, 'FinancingHub');

    if (canAccessToAllTabs) {
      newCompanyTabs.push(...companyTabs);
    } else {
      const factoringQuoteAvailable = validateCompanyTypes(
        user,
        'FactoringQuoting',
      );
      if (factoringQuoteAvailable) {
        newCompanyTabs.push(companyTabs[0]);
      }
      if (validateCompanyTypes(user, 'AccountantCollections')) {
        newCompanyTabs.push(companyTabs[1], companyTabs[2]);
      }
    }

    if (validateCompanyTypes(user, 'SII_Biller')) {
      newCompanyTabs.push(theBiller);
    }
    setInitialTab(
      setCompanyTabs,
      setStartValue,
      mainPage,
      newCompanyTabs,
      'finance',
    );
    setLoading(false);
  };

  useEffect(() => {
    setTabs();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTab
        title="Finanzas"
        startValue={startValue}
        tabs={compnayTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default Finance;
