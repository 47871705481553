import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import FilesDialog from '../../../../../common/filesView';
import EnhancedTab from '../../../../../../../../../lib/designSystem/TabSimulation';
import Summary from './components/Summary';
import ChatWindow from '../../../../../common/chat';
import RiskReviews from '../../../../../common/riskReviews';
import PaymentModule from './components/PaymentModule';
import InvoicesDetailsModule from './components/InvoicesModule';
import { validateCompanyTypes } from '../../../../../../../../../lib/functions/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../services/redux/authSlice';
import FactoringApprovalModule from './components/ApprovalModule';
import { cellFormat } from '../../../../../../../../../lib/designSystem/Table/cellFormat';
import OperativeInfo from '../../../../../../../../BusinessProfile/Profile/components/Operative/components/OperativeInfo';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import { getSubProductConditions } from '../../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import { addOwnCompanyConfirmationsToDocuments } from '../../../../../../../../../lib/api/factoring';
import { Close } from '@mui/icons-material';

export const PAYOUT_AVAILABLE_STAGES = [
  'Aceptada',
  'Cedida',
  'Activa',
  'Completada',
  'En mora',
];
export const EXECUTED_STAGES = [
  'Activa',
  'Completada',
  'En mora',
  'Completada',
];

const ShoppingDialogRecieved = (props: any) => {
  const {
    openDatesSelection,
    setOpenDatesSelection,
    setBackwards,
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    setUp,
    selectedRequest,
    setSelectedRequest,
    setSuccessOpen,
    setFailureOpen,
    executiveCreating,
    businessId,
    subProduct,
    setSubProduct,
    onlySimulation,
    resetShoppingCart,
    handleOpenDeleteConfirmModal,
  } = props;
  const creating =
    !selectedRequest?.status ||
    selectedRequest?.status === 'CREATED' ||
    selectedRequest?.status === 'Creada' ||
    selectedRequest?.status === 'Creando';
  const [openFiles, setOpenFiles] = useState(false);
  const [startValue, setStartValue] = useState(0);
  const [goToRiskTimes, setGoToRiskTimes] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>({
    advance: 0,
    rate: 0,
    arrearRate: 0,
    operationCost: 0,
    amountToApply: 0,
    operationCostComissionFixed: 0,
    operationCostComissionVariablePercentage: 0,
    operationCostComissionVariable: 0,
    expensesCost: 0,
    contactData: onlySimulation ? {} : null,
    selectedDocumentsToApply: [],
  });
  const [openCloseModal, setOpenCloseModal] = useState(false);

  // Prepara object for editing
  const setUpProductConditions = async () => {
    try {
      const subProductConditions = await getSubProductConditions(dispatch, {
        businessId: selectedRequest.businessIdentifier,
        subProductId: selectedRequest.subProduct,
      });
      setSubProduct({ ...subProduct, conditions: subProductConditions });
      setFormData({
        ...formData,
        advance: subProductConditions.advance,
        rate: subProductConditions.spread + subProductConditions.fundRate,
        arrearRate: subProductConditions.arrearRate,
        operationCostComissionFixed: subProductConditions.operationCostFixed,
        operationCostComissionVariablePercentage:
          subProductConditions.operationCostVariable,
        operationCostComissionVariable:
          (subProductConditions.operationCostVariable / 100) *
          selectedRequest.amount,
        amountToApply: selectedRequest.amountToApply,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const addPreviousDocumentDataToRequest = async () => {
    try {
      const newDocuments = await addOwnCompanyConfirmationsToDocuments(
        dispatch,
        {
          documents: selectedRequest.documents,
        },
      );
      setSelectedRequest({ ...selectedRequest, documents: newDocuments });
    } catch (error) {
      console.log(error);
    }
  };

  const prepareRequestForCreating = async () => {
    setLoading(true);
    if (validateCompanyTypes(user, 'FinancingHub')) {
      await setUpProductConditions();
      await addPreviousDocumentDataToRequest();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (creating) {
      prepareRequestForCreating();
    }
  }, []);

  const goToRisk = () => {
    setStartValue(1);
    setGoToRiskTimes(goToRiskTimes + 1);
  };
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const canSeeAllTabs = validateCompanyTypes(user, 'FinancingHub');
  const canPayoutFromAccepted =
    selectedRequest?.status === 'Aceptada' &&
    selectedRequest.subProductCategory !== 'invoice';

  const ImportedModuleLayout = (props: any) => {
    return (
      <div>
        {props.children}
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="secondary"
            style={{ marginTop: '10px' }}
          >
            {' '}
            Salir{' '}
          </CustomButton>
        </DialogActions>
      </div>
    );
  };

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <Summary
          subProduct={subProduct}
          setSubProduct={setSubProduct}
          shoppingCartType={shoppingCartType}
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          setUp={setUp}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          executiveCreating={executiveCreating}
          goToRisk={goToRisk}
          businessId={businessId}
          onlySimulation={onlySimulation}
          openDatesSelection={openDatesSelection}
          setOpenDatesSelection={setOpenDatesSelection}
          setBackwards={setBackwards}
          formData={formData}
          setFormData={setFormData}
          handleOpenDeleteConfirmModal={handleOpenDeleteConfirmModal}
        />
      ),
    },
  ];

  const financingHubTabs = [
    ...tabs,
    {
      index: 1,
      label: 'Documentos',
      component: (
        <div>
          <InvoicesDetailsModule
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            setOpenShoppingCart={setOpenShoppingCart}
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
            resetShoppingCart={resetShoppingCart}
          />
        </div>
      ),
      path: 'documents',
    },
    {
      index: 2,
      label: 'Aprobación',
      disabled: creating,
      component: (
        <div>
          <FactoringApprovalModule
            selectedRequest={selectedRequest}
            requestType="factoring-request"
            goToRisk={goToRisk}
            setOpenShoppingCart={setOpenShoppingCart}
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
            setUp={setUp}
          />
        </div>
      ),
      path: 'recieved',
    },
    {
      index: 3,
      label: 'Cliente',
      component: (
        <ImportedModuleLayout>
          <OperativeInfo
            businessIdentifier={selectedRequest.businessIdentifier}
          />
        </ImportedModuleLayout>
      ),
      path: 'client',
    },
    {
      index: 3,
      label: 'Riesgo',
      disabled: creating,
      component: (
        <ImportedModuleLayout>
          <RiskReviews
            selectedRequest={selectedRequest}
            requestType="factoring-request"
          />
          {/* <RiskReviews
            selectedRequest={selectedRequest}
            requestType="invoice"
          /> */}
        </ImportedModuleLayout>
      ),
      path: 'recieved',
    },
    {
      index: 4,
      label: 'Giro',
      component: (
        <div>
          <PaymentModule
            selectedRequest={selectedRequest}
            setOpenShoppingCart={setOpenShoppingCart}
            setSuccessOpen={setSuccessOpen}
            setUp={setUp}
            setFailureOpen={setFailureOpen}
          />
        </div>
      ),
      disabled:
        !PAYOUT_AVAILABLE_STAGES.includes(selectedRequest?.status) &&
        !canPayoutFromAccepted,
      path: 'confirmations',
    },
    {
      index: 5,
      label: 'Archivos',
      disabled: creating,
      component: (
        <FilesDialog
          openRequest={selectedRequest}
          openFiles={openFiles}
          requestType="factoring"
          setOpenFiles={setOpenFiles}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          executiveCreating={executiveCreating}
          setOpenShoppingCart={setOpenShoppingCart}
        />
      ),
      path: 'recieved',
    },
    {
      index: 6,
      label: 'Chat',
      component: (
        <ChatWindow
          selectedRequest={selectedRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          requestType="factoring"
          setOpenShoppingCart={setOpenShoppingCart}
        />
      ),
      path: 'recieved',
    },
  ];

  if (selectedRequest && selectedRequest.status === 'REJECTED') {
    financingHubTabs.pop();
  }

  if (executiveCreating) {
    financingHubTabs.pop();
  }

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={openShoppingCart}>
        <div style={{ padding: 20, minHeight: 200 }}>
          {loading ? (
            <CenteredCircularProgress type="dialog" />
          ) : (
            <EnhancedTab
              title={
                !executiveCreating
                  ? `Solicitud #${selectedRequest?.id}`
                  : 'Nueva solicitud'
              }
              startValue={startValue}
              tabs={!canSeeAllTabs ? tabs : financingHubTabs}
              updater={goToRiskTimes}
              chip={
                selectedRequest?.status === 'Creando' ? (
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenCloseModal(true)}
                  >
                    <Close />
                  </IconButton>
                ) : (
                  cellFormat(selectedRequest?.status || 'Nuevo', 'status')
                )
              }
            />
          )}
        </div>
      </Dialog>
      <Dialog title="Salir de simulador" open={openCloseModal}>
        <DialogContent>
          <Typography variant="h4">
            ¿Estás seguro que deseas salir del simulador?
          </Typography>{' '}
          <Typography variant="body1">
            Podrías perder los cambios realizados.
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setOpenCloseModal(false)}
            color="secondary"
          >
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="primary"
          >
            Salir
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShoppingDialogRecieved;
