import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { ExportDialogProps, ExportHandlerType } from '../../Prospects.types';
import { exportFullRolling, export6MTop3PayersRolling } from './exports';

const ExportDialog: React.FC<ExportDialogProps> = ({
  openModal,
  setOpenModal,
  prospectsMainTable,
  monthlyRollingData,
}) => {
  const [loading, setLoading] = useState(false);
  const [exportType, setExportType] = useState('FullRolling');

  const exportHandlers: ExportHandlerType = {
    '6MTop3Rolling': async () =>
      await export6MTop3PayersRolling({
        prospectsMainTable,
        monthlyRollingData,
      }),
    FullRolling: async () =>
      await exportFullRolling({
        prospectsMainTable,
        monthlyRollingData,
      }),
  };

  const handleExport = async (type: string) => {
    setLoading(true);
    const handler = exportHandlers[type];
    if (handler) {
      await handler();
    }
    setLoading(false);
    setOpenModal(false);
  };

  if (loading)
    return (
      <Dialog
        open={openModal}
        maxWidth="md"
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle>Exportando...</DialogTitle>
        <CenteredCircularProgress type="dialog" relative={true} />
      </Dialog>
    );

  return (
    <Dialog open={openModal} maxWidth="md" onClose={() => setOpenModal(false)}>
      <DialogTitle>Selecciona el formato de exportación</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Select
            value={exportType}
            onChange={(e) => setExportType(e.target.value)}
          >
            <MenuItem value="FullRolling">Completo</MenuItem>
            <MenuItem value="6MTop3Rolling">
              Últimos 6 meses - top 3 deudores
            </MenuItem>
            {/* <MenuItem value="Full">Completo - estricto</MenuItem>
            <MenuItem value="6MTop3">Últimos 6 meses - top 3 deudores - estricto</MenuItem> */}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpenModal(false)}>
          Cerrar
        </CustomButton>
        <CustomButton color="primary" onClick={() => handleExport(exportType)}>
          Exportar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
