import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  DialogActions,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Autocomplete,
  Divider,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import {
  createAccountingEntry,
  deleteAccountingEntry,
  editAccountingEntry,
  getAccountingEntryTypes,
  getAccountingNodes,
} from '../../../../../../../../lib/api/accounting';
import { useDispatch } from 'react-redux';
import { Add, Delete } from '@mui/icons-material';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { formatMoney } from '../../../../../../../../lib/functions/utils';

const EditEntryWithLines = (props: any) => {
  const { entry, setEntry, nodes, free } = props;

  const handleChange = (e: any, lineIndex: any, attribute: string) => {
    let value = e.target.value;
    if (attribute === 'debit' || attribute === 'credit') {
      value = parseInt(value.split('.').join('').split('$').join('')) || 0;
    }
    console.log(value);
    const newEntry = { ...entry };
    newEntry.lines = newEntry.lines.map((line: any, index: number) => {
      if (index === lineIndex) {
        return { ...line, [attribute]: value };
      }
      return line;
    });
    setEntry(newEntry);
  };

  const handleDeleteLine = (lineIndex: number) => {
    const newEntry = { ...entry };
    newEntry.lines = newEntry.lines.filter(
      (line: any, index: number) => index !== lineIndex,
    );
    setEntry(newEntry);
  };

  const handleAddLine = () => {
    const newLine = {
      nodeId: null,
      debit: 0,
      credit: 0,
    };
    setEntry({ ...entry, lines: [...entry.lines, newLine] });
  };

  const totalSumEquals = entry.lines.reduce((acc: any, line: any) => {
    return acc + line.debit - line.credit;
  }, 0);

  return (
    <div style={{ padding: '20px 0px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {free ? (
          <TextField
            label="Nombre asiento"
            value={entry.name}
            onChange={(e) => setEntry({ ...entry, name: e.target.value })}
          />
        ) : (
          <Typography variant="h6">{entry.name}</Typography>
        )}
        <div>
          <CustomButton onClick={handleAddLine} disabled={!free}>
            <Add />
          </CustomButton>
        </div>
      </div>
      <div style={{ padding: '10px 0px' }}>
        {entry.lines.map((line: any, index: number) => (
          <Grid
            container
            spacing={2}
            style={{ padding: '4px 0px' }}
            key={line.id}
          >
            <Grid item xs={5}>
              <Autocomplete
                fullWidth
                options={nodes}
                disabled={!free}
                getOptionLabel={(node: any) => node.key}
                value={
                  nodes.find((node: any) => node.id === line.nodeId) || null
                }
                onChange={(e, newValue) =>
                  handleChange(
                    { target: { value: newValue ? newValue.id : null } },
                    index,
                    'nodeId',
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Cuenta" disabled={!free} />
                )}
              />
            </Grid>
            <Grid item xs={3} key={line.id}>
              <TextField
                label="Debe"
                fullWidth
                style={{ textAlign: 'right' }}
                value={formatMoney(line.debit)}
                onChange={(e) => handleChange(e, index, 'debit')}
              />
            </Grid>
            <Grid item xs={3} key={line.id}>
              <TextField
                style={{ textAlign: 'right' }}
                label="Haber"
                value={formatMoney(line.credit)}
                onChange={(e) => handleChange(e, index, 'credit')}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                disabled={!free}
                onClick={() => handleDeleteLine(index)}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Divider />
        <Grid container spacing={2} style={{ padding: '4px 0px' }}>
          <Grid item xs={5}>
            <Typography fontWeight="bold">Totales</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              fontWeight="bold"
              color={totalSumEquals === 0 ? 'black' : 'red'}
              variant="body1"
              style={{ textAlign: 'right' }}
            >
              {formatMoney(
                entry.lines.reduce(
                  (acc: any, line: any) => acc + line.debit,
                  0,
                ),
              )}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              fontWeight="bold"
              color={totalSumEquals === 0 ? 'black' : 'red'}
              variant="body1"
              style={{ textAlign: 'right' }}
            >
              {formatMoney(
                entry.lines.reduce(
                  (acc: any, line: any) => acc + line.credit,
                  0,
                ),
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const EditEntry = (props: any) => {
  const {
    entry,
    setEntry,
    open,
    setOpen,
    setUp,
    setSuccessText,
    setFailedText,
  } = props;

  const [loading, setLoading] = useState(false);
  const [entryTypes, setEntryTypes] = useState<any>([]);
  const [nodes, setNodes] = useState<any>([]);
  const editing = entry.id ? true : false;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch;

  const handleSave = () => {
    if (!canSave()) return;
    if (editing) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      const day12Hours = new Date(entry.date);
      day12Hours.setUTCHours(12);
      await createAccountingEntry(dispatch, {
        ...entry,
        date: day12Hours,
      });
      await setUp();
      setSuccessText('Asiento creado correctamente');
      setOpen(false);
    } catch (error) {
      console.log(error);
      setFailedText('Error al crear asiento');
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    console.log(entry);
    try {
      await deleteAccountingEntry(dispatch, entry.id);
      await setUp();
      setOpen(false);
      setSuccessText('Asiento eliminado correctamente');
    } catch (error) {
      console.log(error);
      setFailedText('Error al eliminar asiento');
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    setLoading(true);
    console.log(entry);
    try {
      const day12Hours = new Date(entry.date);
      day12Hours.setUTCHours(12);
      await editAccountingEntry(dispatch, {
        ...entry,
        date: day12Hours,
      });
      await setUp();
      setSuccessText('Asiento editado correctamente');
      setOpen(false);
    } catch (error) {
      console.log(error);
      setFailedText('Error al editar asiento');
    }
    setLoading(false);
  };

  const setUpEntryEditing = async () => {
    setLoading(true);
    try {
      const response = await getAccountingEntryTypes(dispatch);
      const responseNodes = await getAccountingNodes(dispatch);
      setEntryTypes(response);
      setNodes(responseNodes);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const totalSumEquals = entry.lines.reduce((acc: any, line: any) => {
    return acc + line.debit - line.credit;
  }, 0);

  const hasName = entry.name !== '';

  const canSave = () => {
    console.log(entry);
    if (!hasName) return false;

    if (totalSumEquals !== 0) return false;

    if (entry.typeId === 'free') {
      if (entry.lines.length === 0) return false;
      if (entry.lines.some((line: any) => line.nodeId === null)) return false;
    }

    if (entry.lines.some((line: any) => line.debit === 0 && line.credit === 0))
      return false;

    return true;
  };

  useEffect(() => {
    setUpEntryEditing();
  }, []);

  const handleSelectEntryType = (e: any) => {
    if (e.target.value === 'free') {
      setEntry({ ...entry, name: '', typeId: e.target.value, lines: [] });
    } else {
      const type = entryTypes.find((type: any) => type.id === e.target.value);
      setEntry({
        ...entry,
        typeId: e.target.value,
        name: type.name,
        lines: type.lines,
      });
    }
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{editing ? 'Editar asiento' : 'Crear asiento'}</DialogTitle>
      <DialogContent style={{ minWidth: '600px' }}>
        <Grid container spacing={2} paddingTop={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Tipo de asiento</InputLabel>
              <Select
                disabled={loading || editing}
                fullWidth
                value={entry.typeId}
                label="Tipo de asiento"
                onChange={handleSelectEntryType}
              >
                {entryTypes.map((type: any) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Fecha"
              fullWidth
              type="date"
              value={entry.date}
              onChange={(e) => setEntry({ ...entry, date: e.target.value })}
            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20 }} />
        {entry.typeId && (
          <div style={{ marginTop: 20 }}>
            <EditEntryWithLines
              entry={entry}
              setEntry={setEntry}
              nodes={nodes}
              free={entry.typeId === 'free'}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {editing && (
          <CustomButton color="error" onClick={() => setOpenDeleteDialog(true)}>
            Eliminar
          </CustomButton>
        )}
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          Cancelar
        </CustomButton>
        <CustomButton disabled={!canSave()} onClick={() => handleSave()}>
          Guardar
        </CustomButton>
      </DialogActions>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Eliminar asiento</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar este asiento?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => setOpenDeleteDialog(false)}
          >
            Cancelar
          </CustomButton>
          <CustomButton color="error" onClick={() => handleDelete()}>
            Eliminar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default EditEntry;
