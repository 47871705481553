import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  getDailyBook,
  getMajorBook,
} from '../../../../../../lib/api/accounting';
import DailyBookEntriesTable from './components';
import MenuDialog from '../../../../../../lib/designSystem/MenuDialog';
import { InputLabel, Select } from '@mui/material';
import { FormControl } from '@mui/material';
import { MenuItem } from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const YEARS = Array.from({ length: 10 }, (_, index) => 2025 - index);

import ExcelJS from 'exceljs';

const exportToExcel = async (entries: any[]) => {
  // Mapeo de datos planos
  const plainData = entries.flatMap((category: any) => {
    return category.groupedLines.map((line: any) => {
      return [
        line.date, // Fecha
        line.businessIdentifier, // Rut cliente
        line.businessName, // Nombre cliente
        line.account, // Cuenta
        line.accountName, // Nombre de Cuenta
        line.description, // Descripción
        line.documentId, // Documento
        line.debit, // Débito
        line.credit, // Crédito
        line.debitFinal, // Débito final
        line.creditFinal, // Crédito final
      ];
    });
  });

  // Encabezados de la hoja de Excel
  const headers = [
    'Fecha',
    'Rut cliente',
    'Nombre cliente',
    'Cuenta',
    'Nombre de Cuenta',
    'Descripción',
    'Documento',
    'Debe',
    'Haber',
    'Débito',
    'Crédito',
  ];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Agregar datos a la hoja
  [headers, ...plainData].forEach((rowData, rowIndex) => {
    const row = worksheet.addRow(rowData);

    if (rowIndex === 0) {
      // Formato para la primera fila (encabezados)
      row.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getColumn(cell.col).width = 20; // Ajustar ancho de columna
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFDDDDDD' }, // Color gris claro
        };
      });
    }
  });

  // Guardar el archivo
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);

  // Descargar el archivo
  const a = document.createElement('a');
  a.href = url;
  a.download = 'major_book_entries.xlsx';
  a.click();

  URL.revokeObjectURL(url);
};

const DatesSearcher = (props: any) => {
  const { setUp, year, month, setMonth, setYear } = props;
  const handleSearch = async () => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 1);
    await setUp(startDate, endDate);
  };

  return (
    <MenuDialog buttonLabel="Buscar" buttonColor="secondary">
      <div style={{ padding: 10 }}>
        <div style={{ padding: '10px 0px' }}>
          <FormControl fullWidth>
            <InputLabel>Mes</InputLabel>
            <Select
              label="Mes"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              {MONTHS.map((month, index) => (
                <MenuItem key={index} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ padding: '10px 0px' }}>
          <FormControl fullWidth>
            <InputLabel>Año</InputLabel>
            <Select
              label="Año"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {YEARS.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ padding: '10px 0px', textAlign: 'center' }}>
          <CustomButton color="primary" onClick={() => handleSearch()}>
            Buscar
          </CustomButton>
        </div>
      </div>
    </MenuDialog>
  );
};

const MajorBook: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const startDateDefault = new Date();
  startDateDefault.setDate(1);
  const endDateDefault = new Date();
  endDateDefault.setMonth(endDateDefault.getMonth() + 1, 1);
  const date = new Date();
  const [startDate, setStartDate] = useState<any>(startDateDefault);
  const [endDate, setEndDate] = useState<any>(endDateDefault);
  const [month, setMonth] = useState<any>(date.getMonth() + 1);
  const [year, setYear] = useState<any>(date.getFullYear());

  const setUp = async (startDate: Date, endDate: Date) => {
    const startDateString = startDate.toISOString().split('T')[0];
    const endDateString = endDate.toISOString().split('T')[0];
    setStartDate(startDate);
    setEndDate(endDate);
    const queryParams = `startDate=${startDateString}&endDate=${endDateString}`;
    try {
      setLoading(true);
      const response = await getMajorBook(dispatch, queryParams);
      setData(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setUp(startDateDefault, endDateDefault);
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <Container style={{ padding: 0, margin: 0 }}>
      <div>
        <HeaderWithActions
          title={`Período: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
          variant="body1"
          item={
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}
            >
              <div>
                <DatesSearcher
                  setUp={setUp}
                  year={year}
                  month={month}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </div>
              <div>
                <CustomButton
                  color="primary"
                  onClick={() => exportToExcel(data)}
                >
                  Exportar
                </CustomButton>
              </div>
            </div>
          }
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <DailyBookEntriesTable entries={data} />
      </div>
    </Container>
  );
};

export default MajorBook;
