import React from 'react';
import { useState } from 'react';
import { formatMoney } from '../../../../../../../../../../../../../lib/functions/utils';
import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';

const availableCuts = (amount: number) => [
  { id: 7000000, name: '$7.000.000' },
  { id: 5000000, name: '$5.000.000' },
  { id: amount, name: 'Sin corte' },
];

const GenerateNomineeCreditRequest = (props: any) => {
  const { selectedRequest, availableAccounts, originAccounts, setPayments } =
    props;

  const newPaymentGrouped = {
    bankAccountDestinationId: '',
    bankAccountOriginId: '',
    amount: 0,
    cut: '',
  };

  const [paymentGrouped, setPaymentGrouped] = useState<any>([]);

  const handleAmountChange = (e: any, index: any) => {
    console.log(index);
    const value = e.target.value.split('.').join('').split('$').join('');
    const newPayments = [...paymentGrouped];
    newPayments[index].amount = parseInt(value);
    setPaymentGrouped(newPayments);
  };

  const amounInNominee = paymentGrouped.reduce(
    (a: any, b: any) => a + b.amount,
    0,
  );

  const handleGenerateNominee = async () => {
    const paymentGenerated = [];
    for (let i = 0; i < paymentGrouped.length; i++) {
      let remainingAmount = Number(paymentGrouped[i].amount);
      const account = availableAccounts.find(
        (x: any) => x.id === paymentGrouped[i].bankAccountDestinationId,
      );
      const accountOrigin = originAccounts.find(
        (x: any) => x.id === paymentGrouped[i].bankAccountOriginId,
      );
      while (remainingAmount > 0) {
        const amountToDeposit =
          remainingAmount > paymentGrouped[i].cut
            ? paymentGrouped[i].cut
            : remainingAmount;
        paymentGenerated.push({
          businessName: selectedRequest.businessName,
          bankAccountDestinationId: paymentGrouped[i].bankAccountDestinationId,
          bankAccountOriginId: paymentGrouped[i].bankAccountOriginId,
          bankAccountDestinationName: account.bankName,
          bankAccountDestinationNumber: account.accountNumber,
          bankAccountDestinationAccountType: account.accountType,
          bankAccountDestinationHolderName: account.holderName,
          bankAccountDestinationHolderIdentifier: account.holderIdentifier,
          bankAccountOriginName: accountOrigin.bankName,
          bankName: account.bankName,
          accountNumber: account.accountNumber,
          accountType: account.accountType,
          holderName: account.holderName,
          holderIdentifier: account.holderIdentifier,
          businessIdentifier: account.businessIdentifier,
          amount: amountToDeposit,
          operationId: selectedRequest.id,
          executedAt: null,
        });
        remainingAmount -= paymentGrouped[i].cut;
      }
    }
    console.log(paymentGenerated);
    setPayments(paymentGenerated);
  };

  const disabled = !availableAccounts.length || !originAccounts.length;

  return (
    <div>
      <Typography fontWeight={600} variant="h6">
        {'Generación nómina'}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ padding: '20px 0px', display: 'flex' }}>
          <Typography variant="body1" fontWeight={600}>
            Monto solicitud: {formatMoney(selectedRequest.amount)}
          </Typography>
          <Typography variant="body1" marginLeft={4} fontWeight={600}>
            Monto en nómina:{' '}
            {formatMoney(
              paymentGrouped.reduce((a: any, b: any) => a + b.amount, 0),
            )}
          </Typography>
        </div>
        {selectedRequest.amount > 0 && (
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 4 }}>
              <CustomButton
                onClick={() => {
                  setPaymentGrouped([...paymentGrouped, newPaymentGrouped]);
                }}
                color="primary"
                disabled={disabled}
              >
                Agregar
              </CustomButton>
            </div>
            <div style={{ marginLeft: 4 }}>
              <CustomButton
                disabled={
                  formatMoney(amounInNominee) !==
                  formatMoney(selectedRequest.amount) ||
                  paymentGrouped.some((x: any) => !x.cut) ||
                  paymentGrouped.some((x: any) => !x.amount) ||
                  paymentGrouped.some(
                    (x: any) => !x.bankAccountDestinationId,
                  ) ||
                  paymentGrouped.some((x: any) => !x.bankAccountOriginId)
                }
                color="primary"
                onClick={() => handleGenerateNominee()}
              >
                Generar
              </CustomButton>
            </div>
          </div>
        )}
      </div>
      {disabled ? (
        <Typography variant="body1" padding="20px 0px">
          No tienes cuentas registradas para este cliente. Crea una para poder
          generar una nómina. Puedes hacerlo desde{' '}
          <a href="/network/businesses">acá</a>
        </Typography>
      ) : null}
      {!disabled &&
        paymentGrouped.length === 0 &&
        selectedRequest.amount > 0 && (
          <Typography variant="body1" padding="20px 0px">
            Agrega transferencias para generar la nómina.
          </Typography>
        )}
      {paymentGrouped.map((x: any, index: any) => (
        <Grid
          key={index}
          container
          spacing={2}
          alignItems="center"
          rowSpacing={4}
          style={{ padding: '5px 0px' }}
        >
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Monto"
              value={formatMoney(x.amount)}
              onChange={(e) => handleAmountChange(e, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">{'Cuenta Origen'}</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={paymentGrouped[index].bankAccountOriginId}
                onChange={(e) => {
                  const newPayments = [...paymentGrouped];
                  newPayments[index].bankAccountOriginId = e.target.value;
                  setPaymentGrouped(newPayments);
                }}
                label={'Cuenta bancaria'}
              >
                {originAccounts.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.bankName} ({x.accountNumber})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">{'Cuenta destino'}</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={paymentGrouped[index].bankAccountDestinationId}
                onChange={(e) => {
                  const newPayments = [...paymentGrouped];
                  newPayments[index].bankAccountDestinationId = e.target.value;
                  setPaymentGrouped(newPayments);
                }}
                label={'Cuenta bancaria'}
              >
                {availableAccounts.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.bankName} ({x.accountNumber})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">{'Corte'}</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={paymentGrouped[index].cut}
                onChange={(e) => {
                  const newPayments = [...paymentGrouped];
                  newPayments[index].cut = e.target.value;
                  setPaymentGrouped(newPayments);
                }}
                label={'Corte'}
              >
                {availableCuts(paymentGrouped[index].amount).map(
                  (x: any, index: any) => (
                    <MenuItem key={index} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Grid container>
              <Grid item xs={9}>
                <Typography textAlign={'center'}>
                  {paymentGrouped[index].amount && paymentGrouped[index].cut
                    ? ` N° de trasferencias: ${Math.ceil(
                      paymentGrouped[index].amount /
                      paymentGrouped[index].cut,
                    )}`
                    : 'Complete los campos'}{' '}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  onClick={() => {
                    const newPayments = [...paymentGrouped];
                    newPayments.splice(index, 1);
                    setPaymentGrouped(newPayments);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default GenerateNomineeCreditRequest;
