import { requestHandler } from '../../../api';

export const getCreditRequestEvaluation = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest/${data.id}/evaluation`,
    dispatch,
  });
  if (response) return response.data;
};

export const editCreditRequestEvaluation = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditRequest/${id}/evaluation`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCreditRequestEvaluation = async (
  dispatch: any,
  id: number,
  approvalId: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/creditRequest/${id}/evaluation/${approvalId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditRequestEvaluationMissingApprovals = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest/${id}/missingApprovals`,
    dispatch,
  });
  if (response) return response.data;
};

export const makeCreditRequestApproval = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditRequest/${id}/approval`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const makeCreditRequestReject = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditRequest/${id}/reject`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
