import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface DialogTitleProps {
  children: React.ReactNode;
  // eslint-disable-next-line
  setOpen: (value: boolean) => void;
  item?: any;
}

const CustomDialogTitle: React.FC<DialogTitleProps> = ({
  children,
  setOpen,
  item,
}) => {
  console.log(item);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '4px 20px',
        }}
      >
        <Typography variant="h5">{children}</Typography>
        {item ? (
          item
        ) : (
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default CustomDialogTitle;
