import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';

import { FormControl } from '@mui/material';
import React from 'react';
import { InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { formatMoney } from '../../../../../../../../../../../lib/functions/utils';
import { Add, Delete, Info } from '@mui/icons-material';
import { LightTooltip } from '../../../../../../../../../../../lib/designSystem/WhiteTooltip';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';

const WARRANTY_TYPES = [
  'Auto',
  'Inmuebles',
  'Maquinaria y Equipo',
  'Muebles y Enseres',
  'Equipos de Computación y Telecomunicaciones',
  'Equipos de Audio y Video',
  'Otros',
];

const WarrantyPopper = (props: any) => {
  const { creditRequest, setCreditRequest, canEdit } = props;
  const [openPopper, setOpenPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>('right');
  const handleClickPopper =
    (newPlacement: PopperPlacementType) =>
      async (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log('click', placement, newPlacement);
        setAnchorEl(event.currentTarget);
        setOpenPopper((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
      };

  const handleChangeAmount = (e: any, index: number) => {
    const value = e.target.value.split('.').join('').split('$').join('');
    const name = e.target.name;

    if (isNaN(value)) return;
    const newArray = [...creditRequest.warranties];
    newArray[index][name] = Number(value) || 0;
    setCreditRequest({
      ...creditRequest,
      warranties: newArray,
    });
  };

  const handleChangeText = (e: any, index: number) => {
    const value = e.target.value;
    const name = e.target.name;
    const newArray = [...creditRequest.warranties];
    newArray[index][name] = value;
    setCreditRequest({
      ...creditRequest,
      warranties: newArray,
    });
  };

  const handleAddWarranty = () => {
    const newArray = [...creditRequest.warranties];
    newArray.push({
      type: '',
      description: '',
      value: 0,
    });
    setCreditRequest({ ...creditRequest, warranties: newArray });
  };

  const handleRemoveWarranty = (index: number) => {
    const newArray = [...creditRequest.warranties];
    newArray.splice(index, 1);
    setCreditRequest({ ...creditRequest, warranties: newArray });
  };

  const amount = creditRequest.warranties.reduce(
    (acc: number, curr: any) => acc + curr.value,
    0,
  );

  const handleSaveWarranties = () => {
    for (const warranty of creditRequest.warranties) {
      if (warranty.type === '') {
        alert('Debe seleccionar un tipo de garantía');
        return;
      }
      if (warranty.value === 0) {
        alert('Debe ingresar un monto para la garantía');
        return;
      }
      if (warranty.description === '') {
        alert('Debe ingresar una descripción para la garantía');
        return;
      }
    }
    setOpenPopper(false);
  };

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          label="Garantías"
          value={formatMoney(amount)}
          fullWidth
          placeholder="0"
          disabled
          size="small"
        />
        <IconButton
          onClick={handleClickPopper(
            // addIconPlacement === 'end' ? 'right' : 'left-end',
            'right',
          )}
          disabled={!canEdit}
        >
          <Add />
          {creditRequest.warranties.length !== 0 ? (
            <Typography
              style={{
                position: 'absolute',
                bottom: -5,
                right: 0,
                color: 'white',
                fontSize: 10,
                padding: '2px 6px',
                borderRadius: 50,
                fontWeight: '400',
                margin: '1px 2px',
                backgroundColor: '#297dff',
              }}
            >
              {creditRequest.warranties.length}
            </Typography>
          ) : null}
        </IconButton>
      </div>
      <Popper
        sx={{ zIndex: 1300 }}
        open={openPopper}
        anchorEl={anchorEl}
        placement={'bottom'}
        transition={false}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <Paper
          style={{
            padding: 20,
            maxWidth: 800,
            minWidth: 500,
            maxHeight: 400,
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">Garantías</Typography>
              <LightTooltip
                title={
                  <Typography variant="body1" padding={1}>
                    Selecciona los articulos en garantía que se van a asignar al
                    crédito.
                  </Typography>
                }
              >
                <Info sx={{ marginLeft: 1, cursor: 'pointer' }} />
              </LightTooltip>
            </div>
            <IconButton onClick={handleAddWarranty}>
              <Add />
            </IconButton>
          </div>
          {creditRequest.warranties.map((warranty: any, index: number) => (
            <Grid key={index} container spacing={2} alignItems={'center'}>
              <Grid item xs={4}>
                <FormControl
                  disabled={!canEdit}
                  key={index}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="filter-label">Seleccionar</InputLabel>
                  <Select
                    labelId="filter-label"
                    fullWidth
                    name="type"
                    id="Filtrar por estado"
                    value={creditRequest.warranties[index].type}
                    onChange={(e) => handleChangeText(e, index)}
                    label="Seleccionar"
                  >
                    {WARRANTY_TYPES.map((x: any, index: any) => (
                      <MenuItem key={index} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled={!canEdit}
                  autoFocus
                  margin="dense"
                  label="Descripción"
                  name="description"
                  value={creditRequest.warranties[index].description}
                  onChange={(e) => handleChangeText(e, index)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled={!canEdit}
                  autoFocus
                  margin="dense"
                  label="Monto ($)"
                  name="value"
                  value={formatMoney(creditRequest.warranties[index].value)}
                  onChange={(e) => handleChangeAmount(e, index)}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveWarranty(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}
          >
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleSaveWarranties}
            >
              Guardar
            </CustomButton>
          </div>
        </Paper>
      </Popper>
    </div>
  );
};

export default WarrantyPopper;
