import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  DialogActions,
} from '@mui/material';
import React from 'react';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';

interface propsType {
  setDescription: any;
  description: string;
  open: boolean;
  setOpen: any;
  handleCompleteFinal: any;
  openEditManagementId: number;
}

const EditManagement: React.FC<propsType> = (props: propsType) => {
  const {
    setDescription,
    description,
    open,
    setOpen,
    handleCompleteFinal,
    openEditManagementId,
  } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Editar gestión</DialogTitle>
      <DialogContent>
        <Typography marginBottom={2}>
          Si quieres puedes editar la descripción de la gestión para que quede
          completada con la nueva información
        </Typography>
        <TextField
          fullWidth
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleCompleteFinal(openEditManagementId)}>
          Avanzar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditManagement;
