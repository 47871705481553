import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { datesShortCuts } from '../../functions/const/datesShortCuts';
import CustomButton from '../../designSystem/Button';
import CustomAlert from '../../designSystem/Alert';
import { validaRut } from '../../functions/utils';
import MenuDialog from '../../designSystem/MenuDialog';

interface DateSearchProps {
  documentTypes?: any;
  requestId?: any;
  setRequestId?: any;
  startDate?: any;
  endDate?: any;
  setData: any;
  setLoading: any;
  setStartDate?: any;
  setEndDate?: any;
  businessIdentifier?: any;
  setBusinessIdentifier?: any;
  blockInvoicesInOperations?: any;
  setStakeholderIdentifier?: any;
  stakeholderIdentifier?: any;
  searchData: any;
  pagination: any;
  searchBusiness?: any;
  setConfirmationType?: any;
  confirmationType?: any;
  documentType?: any;
  setDocumentType?: any;
}
dayjs.extend(utc);

const DocumentSearcher: React.FC<DateSearchProps> = (props) => {
  const {
    documentTypes,
    requestId,
    setRequestId,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    businessIdentifier,
    setBusinessIdentifier,
    setStakeholderIdentifier,
    stakeholderIdentifier,
    searchData,
    pagination,
    setConfirmationType,
    confirmationType,
    documentType,
    setDocumentType,
  } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [useShortcuts, setUseShortcuts] = useState(false);
  const [shortcut, setShortcut] = useState('');

  const documentTypesOptions = documentTypes || [
    { label: 'Solicitud de factoring', value: 'factoring' },
    { label: 'Solicitud de crédito', value: 'credit' },
  ];

  const handleSearch = async () => {
    if (setStartDate && setEndDate) {
      if (startDate > endDate && endDate !== null && startDate !== null) {
        setAlertMessage('La fecha de inicio no puede ser mayor a la fecha fin');
        setOpenAlert(true);
        return;
      }
      if (
        (startDate === null && endDate !== null) ||
        (startDate !== null && endDate === null)
      ) {
        setAlertMessage('Ambas fechas son requeridas');
        setOpenAlert(true);
        return;
      }
    }

    if (!validaRut(stakeholderIdentifier) && stakeholderIdentifier !== '') {
      setAlertMessage('El rut ingresado no es válido');
      setOpenAlert(true);
      return;
    }

    await searchData({ page: 0, pageSize: pagination.pageSize });
  };

  const handleChangeDateNormal = (value: any, type: string) => {
    if (type === 'start') {
      if (!value) return setStartDate(null);
      setStartDate(value.startOf('day').utc());
    } else {
      if (!value) return setEndDate(null);
      setEndDate(value.endOf('day').utc());
    }
  };

  const handleChangeShortcut = (shortcut: any) => {
    const shorcutSelected = datesShortCuts.find((sc) => sc.label === shortcut);
    if (!shorcutSelected) return;

    const startDateJs = dayjs(shorcutSelected.startDate);
    const endDateJs = dayjs(shorcutSelected.endDate);
    setStartDate(startDateJs.startOf('day').utc());
    setEndDate(endDateJs.endOf('day').utc());
    setShortcut(shortcut);
  };
  console.log(startDate, endDate);

  return (
    <MenuDialog buttonLabel="Buscar" buttonColor="secondary">
      {setStartDate || setEndDate ? (
        <div style={{ padding: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" fontWeight="bold">
              Fechas
            </Typography>
            {setStartDate && setEndDate && (
              <Typography
                variant="body1"
                fontWeight="bold"
                gutterBottom
                onClick={() => setUseShortcuts(!useShortcuts)}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {useShortcuts ? 'Ocultar' : 'Mostrar'} atajos
              </Typography>
            )}
          </div>
          {useShortcuts ? (
            <FormControl fullWidth>
              <InputLabel id="role-label">{'Atajo'}</InputLabel>
              <Select
                labelId="role-label"
                id="role-select"
                fullWidth
                value={shortcut}
                onChange={(e) => handleChangeShortcut(e.target.value)}
                label={'Atajo'}
              >
                {datesShortCuts.map((shortcut: any) => (
                  <MenuItem key={shortcut.label} value={shortcut.label}>
                    {shortcut.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div>
              {setStartDate && (
                <div style={{ padding: '5px 0px' }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es-mx"
                  >
                    <DatePicker
                      label={'Fecha inicio búsqueda'}
                      value={startDate}
                      onChange={(e: any) => handleChangeDateNormal(e, 'start')}
                      sx={{ minWidth: '100%' }}
                      slotProps={{ field: { clearable: true } }}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {setEndDate && (
                <div style={{ padding: '5px 0px' }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es-mx"
                  >
                    <DatePicker
                      label={'Fecha fin búsqueda'}
                      value={endDate}
                      onChange={(e: any) => handleChangeDateNormal(e, 'end')}
                      sx={{ minWidth: '100%' }}
                      slotProps={{ field: { clearable: true } }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}

      <div style={{ padding: '10px' }}>
        <Typography variant="body1" fontWeight="bold" gutterBottom>
          Otros
        </Typography>
        {setDocumentType && (
          <div style={{ padding: '5px 0px' }}>
            <FormControl fullWidth>
              <InputLabel id="document-type-label">
                Tipo de documento
              </InputLabel>
              <Select
                labelId="document-type-label"
                id="document-type-select"
                label="Tipo de documento"
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                fullWidth
                variant="outlined"
              >
                {documentTypesOptions.map((type: any) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {setRequestId && (
          <div style={{ padding: '5px 0px' }}>
            <TextField
              label="ID Solicitud"
              value={requestId}
              onChange={(e) => setRequestId(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </div>
        )}
        {setBusinessIdentifier && (
          <div style={{ padding: '5px 0px' }}>
            <TextField
              label="Rut emisor"
              value={businessIdentifier}
              onChange={(e) => setBusinessIdentifier(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </div>
        )}
        {setStakeholderIdentifier && (
          <div style={{ padding: '5px 0px' }}>
            <TextField
              label="Rut contraparte"
              value={stakeholderIdentifier}
              onChange={(e) => setStakeholderIdentifier(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </div>
        )}
        {setConfirmationType && (
          <div style={{ padding: '5px 0px' }}>
            <Select
              label="Buscar por confirmación"
              value={confirmationType}
              onChange={(e) => setConfirmationType(e.target.value)}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="all">Todos los documentos</MenuItem>
              <MenuItem value="none">Sin Confirmación</MenuItem>
              <MenuItem value="sii">Por SII</MenuItem>
              <MenuItem value="own">Por Propios</MenuItem>
            </Select>
          </div>
        )}
        <div style={{ padding: '10px 0px', textAlign: 'right' }}>
          <CustomButton color="primary" onClick={() => handleSearch()}>
            Buscar
          </CustomButton>
        </div>
        <CustomAlert
          open={openAlert}
          title="Problemas con la búsqueda"
          text={alertMessage}
          onClose={() => setOpenAlert(false)}
          severity="error"
          setOpen={setOpenAlert}
        />
      </div>
    </MenuDialog>
  );
};

export default DocumentSearcher;
