import React, { useEffect, useState } from 'react';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import ActiveMoney from './components/ActiveMoney';
import { useNavigate, useParams } from 'react-router-dom';
import CurseBook from './components/TransactBook';
import IncomeBook from './components/IncomeBook';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import Alerts from './components/Alerts';
import InvoicingBook from './components/InvoicingBook';

const Reporting: React.FC = () => {
  const { subPage } = useParams<{ page: string; subPage: string }>();
  const navigate = useNavigate();
  const handleNavigation = (index: number) => {
    navigate(`/finance/reporting/${reportsTabs[index].path}`);
  };
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const [startValue, setStartValue] = useState(0);
  const [loading, setLoading] = useState(true);

  const reportsTabs = [
    {
      label: 'Cartera',
      component: <ActiveMoney />,
      path: 'activeMoney',
    },
    {
      label: 'Libro curse',
      component: <CurseBook />,
      path: 'curseBook',
    },
    {
      label: 'Ingresos',
      component: <IncomeBook />,
      path: 'incomeBook',
    },
    {
      label: 'Alertas',
      component: <Alerts />,
      path: 'alerts',
    },
    {
      label: 'Facturación',
      component: <InvoicingBook />,
      path: 'invoicingBook',
    },
  ];

  const setUpTabs = async () => {
    const newReportTabs: any = [...reportsTabs];
    let startValueDefault = 0;
    startValueDefault = newReportTabs.findIndex((x: any) => x.path === subPage);
    setCompanyTabs(newReportTabs);
    console.log(newReportTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUpTabs();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <div>
        <EnhancedTab
          startValue={startValue}
          tabs={companyTabs}
          onTabChange={handleNavigation}
        />
      </div>
    </div>
  );
};

export default Reporting;
