import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import { AuthState } from '../../../../services/redux/authSlice';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../lib/functions/utils';
import FactoringRequestsSent from './Factoring/components/Sent';
import FactoringRequestsRecieved from './Factoring/components/Recieved';
import CreditLineRequested from './CreditLine/components/Recieved';
import Extensions from './Extensions';
import FactoringResells from './FactoringFundReSell';
import VerificationModule from './Vetification';
import CreditsRequested from './CreditsRequests/components/Recieved';
import { getOwnCompanyProducts } from '../../../../lib/api';

const Financing: React.FC = () => {
  const { page, subPage } = useParams<{ page: string; subPage: string }>();

  const [loading, setLoading] = useState(true);
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [startValue, setStartValue] = useState(0);
  const navigate = useNavigate();

  const handleNavigation = (index: number) => {
    navigate(`/finance/financing/${companyTabs[index].path}`);
  };

  const fetchCompanyProducts = async () => {
    try {
      const availableProducts = await getOwnCompanyProducts(user);
      return availableProducts.map((x: any) => x.typeId);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fetchRelations = async () => {
    setLoading(true);
    console.log('a');
    const newCompanyTabs = [];
    if (validateCompanyTypes(user, 'Accountant')) {
      if (validatePermissions(user, 'financing_factoring_receive'))
        newCompanyTabs.push({
          label: 'Factoring',
          component: <FactoringRequestsSent />,
          path: 'factoring',
        });
    }
    if (validateCompanyTypes(user, 'FactoringQuoting')) {
      if (validateCompanyTypes(user, 'FinancingHub')) {
        const availableProducts = await fetchCompanyProducts();
        if (validatePermissions(user, 'financing_creditLine_receive'))
          newCompanyTabs.push({
            label: 'Líneas de crédito ',
            component: <CreditLineRequested />,
            path: 'creditLine',
          });
        if (availableProducts.includes('factoring')) {
          if (validatePermissions(user, 'financing_factoring_receive'))
            newCompanyTabs.push({
              label: 'Factoring ',
              component: <FactoringRequestsRecieved />,
              path: 'factoring',
            });
        }
        if (availableProducts.includes('credit')) {
          if (validatePermissions(user, 'financing_credit_receive'))
            newCompanyTabs.push({
              label: 'Créditos ',
              component: <CreditsRequested />,
              path: 'credits',
            });
        }
        if (validatePermissions(user, 'financing_fundSale_view'))
          newCompanyTabs.push({
            label: 'Fondos',
            component: <FactoringResells />,
            path: 'funds',
            disabled: !validatePermissions(user, 'financing_fundSale_view'),
          });
        if (validatePermissions(user, 'financing_extension_view'))
          newCompanyTabs.push({
            label: 'Prórrrogas',
            component: <Extensions />,
            path: 'extensions',
            disabled: !validatePermissions(user, 'financing_extension_view'),
          });
        // if (validatePermissions(user, 'financing_verification_view'))
        newCompanyTabs.push({
          label: 'Verificaciones',
          component: <VerificationModule />,
          path: 'verification',
          // disabled: !validatePermissions(user, 'financing_verification_view'),
        });
      } else {
        if (validatePermissions(user, 'financing_factoring_receive'))
          newCompanyTabs.push({
            label: 'Factoring ',
            component: <FactoringRequestsRecieved />,
            path: 'factoring',
            disabled: !validatePermissions(user, 'financing_factoring_receive'),
          });
      }
    }
    const startingIndex = newCompanyTabs.findIndex(
      (x: any) => x.path === subPage,
    );
    setCompanyTabs(newCompanyTabs);
    setStartValue(startingIndex > 0 ? startingIndex : 0);
    console.log(page);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        startValue={startValue}
        tabs={companyTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default Financing;
