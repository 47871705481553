import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import CompanyBusinessSearcher from '../../../../../../../../../lib/common/CompanyBusinessSearcher';
import CreditSimulator from './components/Simulator';
import CustomDialogTitle from '../../../../../../../../../lib/designSystem/DialogTitle';
import { createCredit } from '../../../../../../../../../lib/api/creditRequest';

interface AmortizationRow {
  month: number;
  paymentDate: string;
  balance: number;
  amount: number;
  interest: number;
  principal: number;
  operationCost: number;
}

const CreateCreditDialog = (props: any) => {
  const dispatch = useDispatch();
  const { open, setOpen, setUp, setSuccessOpen, setFailureOpen } = props;
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState<any>({});
  const [searched, setSearched] = useState(false);
  const operationDefaultDate = new Date();
  const firstPaymentDefaultDate = new Date();
  firstPaymentDefaultDate.setDate(firstPaymentDefaultDate.getDate() + 30);
  const [amortizationSchedule, setAmortizationSchedule] = useState<
    AmortizationRow[]
  >([]);

  const [creditDetails, setCreditDetails] = useState<{
    amount: number;
    months: number;
    operationCost: number;
    otherExpenses: number;
    rate: number;
    arrearRate: number;
    periodicity: 'MONTHLY' | 'QUARTERLY' | 'SEMI_ANNUAL' | 'ANNUAL';
    interestType: 'compound' | 'simple';
    type: 'WORKING_CAPITAL' | 'CONSUMER';
    operationDate: string;
    firstPaymentDate: string;
    warranties: any[];
  }>({
    amount: 0,
    months: 12,
    operationCost: 0,
    otherExpenses: 0,
    rate: 1,
    periodicity: 'MONTHLY',
    arrearRate: 0,
    interestType: 'compound',
    type: 'WORKING_CAPITAL',
    operationDate: operationDefaultDate.toISOString().split('T')[0],
    firstPaymentDate: firstPaymentDefaultDate.toISOString().split('T')[0],
    warranties: [],
  });
  const item =
    business && searched ? (
      <Typography variant="h6">{business.businessName}</Typography>
    ) : null;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createCredit(dispatch, {
        amount: Number(creditDetails.amount),
        months: Number(creditDetails.months),
        type: creditDetails.type,
        operationCost: Number(creditDetails.operationCost),
        otherExpenses: Number(creditDetails.otherExpenses),
        operationDate: new Date(creditDetails.operationDate),
        rate: Number(creditDetails.rate),
        arrearRate: Number(creditDetails.arrearRate),
        businessIdentifier: business.identifier,
        cuotas: amortizationSchedule,
        paymentDate:
          amortizationSchedule[amortizationSchedule.length - 1].paymentDate,
        warranties: creditDetails.warranties,
      });
      setSuccessOpen('Crédito creado correctamente');
      await setUp();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      setFailureOpen('Error al crear el crédito');
      console.log(error);
    }
  };

  const creditDetailsValid = () => {
    return (
      creditDetails.amount > 0 &&
      creditDetails.months > 0 &&
      creditDetails.rate > 0 &&
      creditDetails.arrearRate > 0 &&
      creditDetails.operationDate !== '' &&
      creditDetails.firstPaymentDate !== ''
    );
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
      <CustomDialogTitle setOpen={setOpen} item={item}>
        Solicitar Crédito
      </CustomDialogTitle>
      {loading && (
        <DialogContent style={{ minWidth: '500px' }}>
          <CenteredCircularProgress type="dialog" />
        </DialogContent>
      )}
      {!loading && (
        <DialogContent style={{ minWidth: '500px' }}>
          {!searched && (
            <div>
              <CompanyBusinessSearcher
                setBusiness={setBusiness}
                setSearched={setSearched}
              />
            </div>
          )}
          {searched && (
            <div>
              <CreditSimulator
                creditDetails={creditDetails}
                setCreditDetails={setCreditDetails}
                amortizationSchedule={amortizationSchedule}
                setAmortizationSchedule={setAmortizationSchedule}
                editing={true}
                newCreditRequest={true}
              />
            </div>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => setOpen(false)}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading || !creditDetailsValid()}
        >
          Solicitar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCreditDialog;
