import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CreditRequestStatus from '../../../../../../../common/CreditRequestStatus';

const ShoppingCartAccepted = (props: any) => {
  const { setOpenShoppingCart, openRequest } = props;

  return (
    <div>
      <div>
        <CreditRequestStatus
          openRequest={openRequest}
          audienceType="received"
        />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartAccepted;
