import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Typography,
} from '@mui/material';

import { getComitees } from '../../../../../../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { editCreditRequestComitee } from '../../../../../../../../../../../../../lib/api/creditRequest';

const ComiteeUpdateDialog = (props: any) => {
  const { openRequest, open, setOpen, setFailure, setSuccess, comitee, setUp } =
    props;
  const [loading, setLoading] = useState(false);
  const [comitees, setComitees] = useState([]);
  const [newComitee, setNewComitee] = useState<any>(null);
  const dispatch = useDispatch();

  const validateAttributes = () => {
    if (!newComitee) return alert('Seleccione un comité');
    return true;
  };

  const handleSave = async () => {
    if (!validateAttributes()) {
      return;
    }
    setLoading(true);
    try {
      await editCreditRequestComitee(dispatch, openRequest.id, {
        comiteeId: newComitee,
      });
      setSuccess('Se ha editado el comité de la solicitud');
    } catch (error) {
      setFailure('Error al editar el comité');
    }
    await setUp();
    setOpen(false);
    setLoading(false);
  };

  const setUpComitees = async () => {
    setLoading(true);
    try {
      const response = await getComitees(dispatch);
      setComitees(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpComitees();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Editar Comité</DialogTitle>
      <DialogContent>
        <div>
          <div style={{ paddingBottom: '10px' }}>
            <Typography variant="body1">
              Acá podrás editar el comité con que quieres que sea revisado está
              solicitud.
            </Typography>
          </div>
          <div style={{ paddingBottom: '10px' }}>
            <Typography variant="body1" fontWeight={'bold'}>
              Comité actual
            </Typography>
            <Typography variant="body1">
              {comitee ? comitee.name : 'Sin comité seleccionado'}
            </Typography>
          </div>
          <Typography variant="body1" fontWeight={'bold'}>
            Nuevo comité
          </Typography>
          <FormControl margin="dense" fullWidth>
            <InputLabel id="filter-label">Selecciona un comité</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={newComitee}
              onChange={(e) => setNewComitee(e.target.value)}
              label="Selecciona un comité"
            >
              {comitees.map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Salir
        </CustomButton>
        <CustomButton color="primary" onClick={handleSave}>
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ComiteeUpdateDialog;
