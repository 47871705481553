import {
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  calculateRequestDetails,
  formatMoney,
} from '../../../../../../lib/functions/utils';
import { Info } from '@mui/icons-material';
import { getCompanyLogo } from '../../../../../../lib/api/company';
import { generateSimulationPDF } from '../exportSimulation';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';
import { expressGetCompanyLogo } from '../../../../../../lib/api/express';

const CustomTooltip = (tableData: any) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Costo</TableCell>
        <TableCell>Valor</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {tableData.map((row: any) => (
        <TableRow key={row.index}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.value}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const SimulationPreview = (props: any) => {
  const { selectedRequest, liveForm, isLive, documentsToApply } = props;
  const [loading, setLoading] = useState(false);

  const styledBox = {
    display: 'flex',
    padding: !isLive ? '6px 0px' : '12px 0px',
    justifyContent: 'space-between',
    alignContent: 'center',
  };
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();

  const [finalOperationForm, setFinalOperationForm] = useState<any>({
    operationAmount: null,
    operationAdvanced: null,
    operationCostRate: null,
    operationCostIva: null,
    operationCostComissionFixed: null,
    operationCostComissionVariable: null,
    operationToDepost: null,
    expensesCost: null,
    expensesCostIva: null,
    advance: null,
    rate: null,
  });

  const updateSimulationValuesPreview = () => {
    const {
      operationAmount,
      operationAdvanced,
      operationCostRate,
      operationToDepost,
      operationIva,
      expensesIva,
      operationCostComissionFixed,
      operationCostComissionVariable,
      advance,
      rate,
      expensesCost,
      operationAmountToApply,
    } = calculateRequestDetails(selectedRequest);

    setFinalOperationForm({
      operationAmount,
      operationAdvanced,
      operationCostRate,
      operationToDepost,
      operationIva,
      expensesIva,
      operationCostComissionFixed,
      operationCostComissionVariable,
      advance,
      rate,
      expensesCost,
      operationAmountToApply,
    });
  };

  useEffect(() => {
    updateSimulationValuesPreview();
  }, []);

  const operationAmount = liveForm
    ? liveForm.operationAmount
    : finalOperationForm.operationAmount;
  const operationAdvanced = liveForm
    ? liveForm.operationAdvanced
    : finalOperationForm.operationAdvanced;
  const operationCostRate = liveForm
    ? liveForm.operationCostRate
    : finalOperationForm.operationCostRate;
  const operationCostComissionFixed = liveForm
    ? liveForm.operationCostComissionFixed
    : finalOperationForm.operationCostComissionFixed;
  const operationCostComissionVariable = liveForm
    ? liveForm.operationCostComissionVariable
    : finalOperationForm.operationCostComissionVariable;
  const operationToDepost = liveForm
    ? liveForm.operationToDepost
    : finalOperationForm.operationToDepost;
  const operationIva = liveForm
    ? liveForm.operationIva
    : finalOperationForm.operationIva;
  const expensesIva = liveForm
    ? liveForm.expensesIva
    : finalOperationForm.expensesIva;
  const operationAmountToApply = liveForm
    ? liveForm.operationAmountToApply
    : finalOperationForm.operationAmountToApply;
  const expensesCost = liveForm
    ? liveForm.expensesCost
    : finalOperationForm.expensesCost;
  const arrearRate = liveForm
    ? liveForm.arrearRate
    : finalOperationForm.arrearRate;
  const advance = liveForm ? liveForm.advance : finalOperationForm.advance;
  const rate = liveForm ? liveForm.rate : finalOperationForm.rate;

  const buildSelectedRequest = () => {
    console.log('selectedRequest', selectedRequest);
    const documentsApplied = selectedRequest.quoteActive
      ? selectedRequest.quoteActive.documentsToApply
      : documentsToApply;
    return {
      id: selectedRequest.id,
      executiveName: user?.name,
      businessIdentifier: selectedRequest.businessIdentifier,
      subProductName: selectedRequest.subProductName,
      advance,
      amount: operationAmount,
      businessName: selectedRequest.businessName,
      createdAt: new Date(),
      operationDate:
        selectedRequest.operationDate || selectedRequest.requestDate,
      financingAt: selectedRequest.financingAt,
      rate,
      arrearRate: arrearRate || selectedRequest.arrearRate,
      amountToApply: operationAmountToApply,
      expenses: expensesCost,
      amountToDepositTotal: operationToDepost,
      documentsToApply: documentsApplied,
      documents: selectedRequest.documents,
      operationCostFixed: operationCostComissionFixed,
      operationCostVariable: operationCostComissionVariable,
      operationCostIva: operationIva,
      expensesCostIva: expensesIva,
    };
  };

  const exportSimulation = async () => {
    setLoading(true);
    const request = buildSelectedRequest();
    try {
      // const documentsApplied = await getDocumentsApplied();
      const imageBase64 = user?.express
        ? await expressGetCompanyLogo(dispatch, user.clientId as string)
        : await getCompanyLogo(dispatch);
      await generateSimulationPDF(request, user?.name || '', imageBase64);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        style={{
          padding: '0px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight="bold" variant="h6">
          Detalles
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {loading && <CircularProgress size={16} style={{ marginRight: 4 }} />}
          <Typography
            onClick={() => exportSimulation()}
            variant="caption"
            color="primary"
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
          >
            Exportar
          </Typography>
        </div>
      </div>
      <div style={styledBox}>
        <Typography variant="body1">Monto operación</Typography>
        <Typography variant="body1">{formatMoney(operationAmount)}</Typography>
      </div>
      <div style={styledBox}>
        <Typography variant="body1">
          Monto a financiar {`(${advance}%)`}
        </Typography>
        <Typography variant="body1">
          {formatMoney(operationAdvanced)}
        </Typography>
      </div>
      <div style={styledBox}>
        <Typography variant="body1">
          Diferencia precio {`(${rate}%)`}
        </Typography>
        <Typography variant="body1">
          {formatMoney(operationCostRate)}
        </Typography>
      </div>
      {operationAmountToApply !== 0 && (
        <div style={styledBox}>
          <Typography variant="body1">Documentos aplicados</Typography>
          <Typography variant="body1">
            {formatMoney(operationAmountToApply)}
          </Typography>
        </div>
      )}
      {expensesCost !== 0 && (
        <div style={styledBox}>
          <div style={{ display: 'flex' }}>
            <Typography variant="body1">Otros gastos</Typography>
            <LightTooltip
              title={CustomTooltip([
                { name: 'Costo', value: formatMoney(expensesCost) },
                { name: 'IVA', value: formatMoney(expensesIva) },
              ])}
            >
              <Info style={{ marginLeft: 6, fontSize: 16 }} />
            </LightTooltip>
          </div>
          <Typography variant="body1">
            {formatMoney(expensesCost + expensesIva)}
          </Typography>
        </div>
      )}
      <div style={styledBox}>
        <div style={{ display: 'flex' }}>
          <Typography variant="body1">Comisión operación + IVA</Typography>
          <LightTooltip
            title={CustomTooltip([
              {
                name: 'Costo fijo',
                value: formatMoney(operationCostComissionFixed),
              },
              {
                name: 'Costo variable',
                value: formatMoney(operationCostComissionVariable),
              },
              { name: 'IVA', value: formatMoney(operationIva) },
              {
                name: 'Total',
                value: formatMoney(
                  operationCostComissionFixed +
                    operationCostComissionVariable +
                    operationIva,
                ),
              },
            ])}
          >
            <Info style={{ marginLeft: 6, fontSize: 16 }} />
          </LightTooltip>
        </div>
        <Typography variant="body1">
          {formatMoney(
            operationCostComissionFixed +
              operationCostComissionVariable +
              operationIva,
          )}
        </Typography>
      </div>
      <div style={{ marginTop: 10 }}>
        <Divider />
        <div style={styledBox}>
          <Typography fontWeight="bold" variant="body1">
            Monto a girar
          </Typography>
          <Typography fontWeight="bold" variant="body1">
            {formatMoney(operationToDepost)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SimulationPreview;
