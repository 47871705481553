import React, { useState, useCallback } from 'react';
import { PopperPlacementType, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getPayerSales } from '../../../../../../../lib/api/dashboard';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import MiniPieChart from './components/miniPieChart';

const PayerSales = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [payerIdentifier, setPayerIdentifier] = useState<string | null>(null);
  const [factorings, setFactorings] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [payerName, setPayerName] = useState<string | null>(null);
  const [search, setSearch] = useState<boolean>(false);
  // Popper
  const [openFactoringPopper, setOpenFactoringPopper] = React.useState(false);
  const [factoringAnchorEl, setFactoringAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [factoringPlacement, setFactoringPlacement] =
    React.useState<PopperPlacementType>('right');
  const [selectedFactoringDate, setSelectedFactoringDate] = useState<any>(null);
  const [selectedFactoring, setSelectedFactoring] = useState<any>(null);
  const [otherFactoringsSelected, setOtherFactoringsSelected] = useState<any>(
    [],
  );

  const handleFactoringSelectDate = useCallback(
    (key: string, id: string, e: any, factorings: any) => {
      console.log(factorings);
      const factoring: any = factorings.find((x: any) => x.id === id);
      console.log(factoring);
      if (!factoring) return;
      const dateSelected = factoring.clientsData[key];
      console.log(dateSelected);
      if (!dateSelected)
        return alert('Este factoring no tuvo cesiones en este mes');

      setOtherFactoringsSelected(dateSelected);
      setSelectedFactoring(factoring);
      setSelectedFactoringDate(key === 'allYear' ? 'Año completo' : key);
      handleClickFactoringPopper('right')(e);
    },
    [factorings], // Dependencia actualizada
  );

  const handleClickFactoringPopper =
    (newPlacement: PopperPlacementType) =>
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setFactoringAnchorEl(event.currentTarget);
      setOpenFactoringPopper(
        (prev) => factoringPlacement !== newPlacement || !prev,
      );
      setFactoringPlacement(newPlacement);
    };

  const createClickeableHeaders = (headers: any, factorings: any) => {
    return headers.map((header: any) => ({
      ...header,
      ...(header.key !== 'cessionaryName' && {
        onClick: (key: string, id: string, e: any) =>
          handleFactoringSelectDate(key, id, e, factorings),
      }),
    }));
  };

  const fetchPayersSales = async () => {
    if (!payerIdentifier) return alert('No se ha seleccionado un deudor');
    setLoading(true);
    try {
      const cessionsResponse = await getPayerSales(dispatch, payerIdentifier);
      setFactorings(cessionsResponse.data);
      const clickeableHeaders = createClickeableHeaders(
        cessionsResponse.headers,
        cessionsResponse.data,
      );
      setHeaders(clickeableHeaders);
      setPayerName(cessionsResponse.payerName);
      setSearch(true);
      console.log(cessionsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá podrás buscar si es que un deudor de tus clientes ha sido tomado en otros factorings."
        variant="body1"
        item={
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <TextField
              label="Rut Deudor"
              value={payerIdentifier}
              onChange={(e) => setPayerIdentifier(e.target.value)}
            />
            <div>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={fetchPayersSales}
              >
                Buscar
              </CustomButton>
            </div>
          </div>
        }
      />

      {search && (
        <div>
          <div>
            <Typography variant="h6">
              <b> {payerName}</b>
            </Typography>
          </div>
          {factorings.length > 0 && (
            <EnhancedTable
              data={factorings || []}
              dataHeaders={headers || []}
              finalSumRow
              exportToFile="xlsx"
              rowsPerPageDefault={20}
            />
          )}
          <MiniPieChart
            data={otherFactoringsSelected}
            openPopper={openFactoringPopper}
            setOpenPopper={setOpenFactoringPopper}
            anchorEl={factoringAnchorEl}
            setAnchorEl={setFactoringAnchorEl}
            placement={factoringPlacement}
            handleClickPopper={handleClickFactoringPopper}
            date={selectedFactoringDate}
            name={selectedFactoring?.cessionaryName}
          />
        </div>
      )}
    </div>
  );
};

export default PayerSales;
