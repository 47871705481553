import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { formatMoney } from '../../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%',
    },
    tableCell: {
      padding: '8px !important',
      overflow: 'hidden',
      minWidth: '50px',
      maxWidth: '180px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableCellTasa: {
      padding: '8px !important',
      overflow: 'hidden',
      maxWidth: '50px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
      textAlign: 'right',
    },
    tableCellNames: {
      padding: '8px !important',
      overflow: 'hidden',
      maxWidth: '80px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    textField: {
      fontSize: '14px !important',
      padding: '8px !important',
    },
    textFieldAutocomplete: {
      fontSize: '14px !important',
      padding: '0px !important',
      minWidth: '120px !important',
    },
    iconButton: {
      padding: '5px',
    },
  }),
);

const MajorBookEntriesTable = (props: any) => {
  const { entries } = props;
  const classes = useStyles();

  const totalDebit = entries.reduce(
    (sum: number, line: any) => sum + line.debit,
    0,
  );
  const totalCredit = entries.reduce(
    (sum: number, line: any) => sum + line.credit,
    0,
  );
  const totalDebtor = entries.reduce(
    (sum: number, line: any) => sum + line.debtor,
    0,
  );
  const totalCreditor = entries.reduce(
    (sum: number, line: any) => sum + line.creditor,
    0,
  );
  const totalActive = entries.reduce(
    (sum: number, line: any) => sum + line.active,
    0,
  );
  const totalPassive = entries.reduce(
    (sum: number, line: any) => sum + line.passive,
    0,
  );
  const totalLoss = entries.reduce(
    (sum: number, line: any) => sum + line.loss,
    0,
  );
  const totalIncome = entries.reduce(
    (sum: number, line: any) => sum + line.income,
    0,
  );

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Cuenta</TableCell>
            <TableCell>Nombre </TableCell>
            <TableCell>Débito</TableCell>
            <TableCell>Crédito</TableCell>
            <TableCell>Deudor</TableCell>
            <TableCell>Acreedor</TableCell>
            <TableCell>Activo</TableCell>
            <TableCell>Pasivo</TableCell>
            <TableCell>Pérdida</TableCell>
            <TableCell>Ganancia</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {entries.map((day: any, dayIndex: number) => (
            <TableRow key={dayIndex}>
              <TableCell className={classes.tableCell}>{day.account}</TableCell>
              <TableCell className={classes.tableCell}>
                <LightTooltip
                  placement="top"
                  title={
                    <Typography padding="8px">{day.accountName}</Typography>
                  }
                >
                  {day.accountName.length > 30
                    ? `${day.accountName.substring(0, 30)}...`
                    : day.accountName}
                </LightTooltip>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.debit)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.credit)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.debtor)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.creditor)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.active)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.passive)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.loss)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatMoney(day.income)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              Sumas totales:
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalDebit)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalCredit)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalDebtor)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalCreditor)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalActive)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalPassive)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalLoss)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalIncome)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              Resultado:
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(
                totalActive < totalPassive ? totalPassive - totalActive : 0,
              )}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(
                totalPassive < totalActive ? totalActive - totalPassive : 0,
              )}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(
                totalLoss < totalIncome ? totalIncome - totalLoss : 0,
              )}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(
                totalIncome < totalLoss ? totalLoss - totalIncome : 0,
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              Totales iguales:
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalDebit)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalCredit)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalDebtor)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalCreditor)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(
                totalActive > totalPassive ? totalActive : totalPassive,
              )}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(
                totalPassive > totalActive ? totalPassive : totalActive,
              )}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalLoss > totalIncome ? totalLoss : totalIncome)}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {formatMoney(totalIncome > totalLoss ? totalIncome : totalLoss)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MajorBookEntriesTable;
