import { Cancel } from '@mui/icons-material';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { Grid, Select } from '@mui/material';
import { IconButton } from '@mui/material';
import React from 'react';
import { InputLabel } from '@mui/material';

import { AddCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';

const GTRangeAssign = (props: any) => {
  const {
    newNode,
    addRange,
    handleChangeRange,
    deleteRange,
    availableFinalNodeForSelection,
  } = props;
  return (
    <div>
      <div
        style={{
          padding: '10px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" gutterBottom>
          <b>Rangos</b>
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => addRange()}>
            <AddCircle color="primary" />
          </IconButton>
        </div>
      </div>
      <Grid container spacing={2} alignItems="center" padding={'6px 0px'}>
        <Grid item xs={1}>
          <Typography>#</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>Decisión a asignar</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Valor mínimo</Typography>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      {newNode.ranges.map((range: any, index: any) => (
        <Grid
          container
          spacing={2}
          key={index}
          alignItems="center"
          padding={'6px 0px'}
        >
          <Grid item xs={1}>
            <Typography>{index + 1}</Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="filter-label">Selecciona una decisión</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                name="nodeId"
                value={range.nodeId}
                onChange={(e: any) => handleChangeRange(e, index)}
                label="Selecciona una decisión"
              >
                {availableFinalNodeForSelection().map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {0 === index ? (
              <Typography padding={'8px'} variant="body1" gutterBottom>
                - ∞
              </Typography>
            ) : (
              <TextField
                label="Valor máximo"
                variant="outlined"
                type="number"
                fullWidth
                value={range.value}
                onChange={(e) => handleChangeRange(e, index)}
                name="value"
              />
            )}
          </Grid>

          <Grid item xs={1}>
            <IconButton
              disabled={0 === index}
              onClick={() => deleteRange(index)}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default GTRangeAssign;
