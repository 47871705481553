import React from 'react';
import { DialogActions, TextField, Typography } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';
import {
  freezeCreditLine,
  unFreezeCreditLine,
} from '../../../../../../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingCartFactoringReviewed from '../2_ShoppingCartReviewed';
import { validatePermissions } from '../../../../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';

const ShoppingCartFactoringAlive = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const {
    setOpenShoppingCart,
    openShoppingCart,
    openRequest,
    setLoading,
    setSuccessOpen,
    setUp,
  } = props;
  const [inFreezing, setInFreezing] = React.useState(false);
  const [inUnFreezing, setInUnFreezing] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const dispatch = useDispatch();

  const validateMessage = () => {
    return message.length > 0;
  };

  const freeze = async () => {
    if (!validateMessage()) {
      return alert('Por favor, ingresa un mensaje');
    }
    setLoading(true);
    try {
      await freezeCreditLine(dispatch, {
        id: openRequest.id,
        message,
      });
      setSuccessOpen('Hemos congelado la línea de crédito');
      await setUp();
      setOpenShoppingCart(false);
    } catch (error) {
      console.log(error);
    }
  };

  const unFreeze = async () => {
    if (!validateMessage()) {
      return alert('Por favor, ingresa un mensaje');
    }
    setLoading(true);
    try {
      await unFreezeCreditLine(dispatch, {
        id: openRequest.id,
        message,
      });
      setSuccessOpen('Hemos descongelado la línea de crédito');
      await setUp();
      setOpenShoppingCart(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (editing) {
    console.log('ShoppingCartFactoringAlive');
    return (
      <ShoppingCartFactoringReviewed
        openShoppingCart={openShoppingCart}
        setOpenShoppingCart={setOpenShoppingCart}
        openRequest={openRequest}
        setSuccessOpen={setSuccessOpen}
        inAssignationDefault
        setUp={setUp}
        setLoading={setLoading}
      />
    );
  }

  return (
    <div>
      <div>
        <div>
          <CreditLineStatus openRequest={openRequest} audienceType="sent" />
          {inFreezing || inUnFreezing ? (
            <div>
              <Typography variant="h6" fontWeight={600}>
                {inFreezing ? 'Congelar' : 'Descongelar'} Línea de crédito
              </Typography>
              <Typography variant="body1">
                Por favor, ingresa la razón para{' '}
                <b style={{ color: inFreezing ? 'red' : 'green' }}>
                  {inFreezing ? 'congelar' : 'descongelar'}
                </b>{' '}
                esta línea de crédito
              </Typography>
              <TextField
                label="Razón"
                variant="outlined"
                fullWidth
                multiline
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
                style={{ marginTop: 10 }}
              />
              <DialogActions>
                <CustomButton
                  onClick={() => {
                    setInFreezing(false);
                    setInUnFreezing(false);
                  }}
                  color="secondary"
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  onClick={inFreezing ? freeze : unFreeze}
                  color="primary"
                >
                  Confirmar
                </CustomButton>
              </DialogActions>
            </div>
          ) : (
            <div>
              <DialogActions>
                <CustomButton
                  onClick={() => setOpenShoppingCart(false)}
                  color="secondary"
                >
                  Salir
                </CustomButton>
                <CustomButton
                  onClick={() => setEditing(true)}
                  color="primary"
                  disabled={
                    !validatePermissions(user, 'financing_creditLine_edit')
                  }
                >
                  Editar
                </CustomButton>
                {openRequest.status === 'Activa' ? (
                  <CustomButton
                    onClick={() => setInFreezing(true)}
                    color="error"
                    disabled={
                      !validatePermissions(user, 'financing_creditLine_edit')
                    }
                  >
                    Congelar
                  </CustomButton>
                ) : (
                  <CustomButton
                    onClick={() => setInUnFreezing(true)}
                    color="success"
                    disabled={
                      !validatePermissions(user, 'financing_creditLine_edit')
                    }
                  >
                    Descongelar
                  </CustomButton>
                )}
              </DialogActions>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartFactoringAlive;
