import React, { useEffect, useState } from 'react';
import { DialogActions, Grid, TextField, Typography } from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import PayersAssignation from './components/Payers';
import EnhancedTab from '../../../../../../../../../../lib/designSystem/TabSimulation';
import SubPrductAssingnation from './components/Subproducts';
import {
  assginCreditLine,
  getBusinessPayers,
  getCreditLineAssignations,
  getProductSubProducts,
} from '../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';

const AssignationModule = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    updateStatus,
    setInAssignation,
    setSuccessOpen,
    setUp,
  } = props;
  console.log('AssignationModule rendered');
  const [payers, setPayers] = useState<any[]>([]);
  const [subProducts, setSubProducts] = useState<any[]>([]);
  const [stakeholderConcentrations, setStakeholderConcentrations] = useState(
    [],
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [subProductConcentrations, setSubProductConcentrations] = useState([]);
  const [amount, setAmount] = useState(openRequest.amount);
  const [validUntil, setValidUntil] = useState(openRequest.validUntil);
  const validateConcentrationsAmount = () => {
    if (stakeholderConcentrations.length === 0) {
      alert(
        'Debes seleccionar al menos un pagador. Si no quieres agregar una concentración especififica, selecciona "Otros"',
      );
      return false;
    }
    const total = stakeholderConcentrations.every(
      (x: any) => x.amount <= amount,
    );

    if (!total) {
      alert(
        'El monto de las concentraciones de los pagadores no puede ser mayor al total de la línea.',
      );
      return false;
    }
    return true;
  };

  const validateSubProductsConcentrationsAmount = () => {
    if (subProductConcentrations.length === 0) {
      alert('Debes seleccionar al menos un subproducto');
      return false;
    }
    const total = subProductConcentrations.every(
      (x: any) => x.amount <= amount,
    );

    if (!total) {
      alert(
        'El monto de las concentraciones de los subproductos no puede ser mayor al total de la línea.',
      );
      return false;
    }
    return true;
  };

  const validateAmount = () => {
    if (amount <= 0) {
      alert('El monto del total de la línea debe ser mayor a 0');
      return false;
    }
    return true;
  };

  const cleanStakeholderConcentrations = () => {
    return stakeholderConcentrations.map((x: any) => {
      return {
        businessIdentifier: x.stakeholderName === 'Otros' ? null : x.identifier,
        businessName: x.stakeholderName,
        amount: x.amount,
      };
    });
  };

  const cleanSubProductsConcentrations = () => {
    const newConcentrations = subProductConcentrations.map((x: any) => {
      const newConcentration = {
        subProductId: x.id,
        advance: Number(x.advance),
        arrearRate: Number(x.arrearRate),
        maxFinancingDays: Number(x.maxFinancingDays),
        spread: Number(x.spread),
        operationCostVariable: Number(x.operationCostVariable),
        operationCostFixed: Number(x.operationCostFixed),
        amount: Number(x.amount),
      };
      delete x.id;
      return newConcentration;
    });
    return newConcentrations;
  };
  const handleSubmit = async () => {
    if (!validateAmount()) return;
    if (!validateConcentrationsAmount()) return;
    if (!validateSubProductsConcentrationsAmount()) return;
    if (!validUntil)
      return alert('Por favor, selecciona una fecha de vencimiento');

    setLoading(true);
    try {
      const cleannedStakeholdersData = cleanStakeholderConcentrations();
      const cleannedSubProductsData = cleanSubProductsConcentrations();

      console.log('cleannedStakeholdersData', cleannedStakeholdersData);
      console.log('cleannedSubProductsData', cleannedSubProductsData);
      await assginCreditLine(dispatch, {
        id: openRequest.id,
        amount,
        stakeholderConcentrations: cleannedStakeholdersData,
        subProductConcentrations: cleannedSubProductsData,
        validUntil,
        updateStatus,
        typeId: openRequest.productTypeId,
      });
      setInAssignation(false);
      setSuccessOpen('Se ha asignado la línea con éxito.');
      await setUp();
      setOpenShoppingCart(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getConcentrations = async () => {
    setLoading(true);
    try {
      const response = await getCreditLineAssignations(dispatch, {
        id: openRequest.id,
      });
      const { stakeholderAssignations, subProductAssignations } = response;

      const parsedConcentrations = stakeholderAssignations.map(
        (concentration: any) => ({
          identifier: concentration.businessIdentifier,
          stakeholderName: concentration.businessName,
          amount: concentration.amount,
          new: false,
        }),
      );
      setStakeholderConcentrations(parsedConcentrations);
      setSubProductConcentrations(subProductAssignations);

      console.log(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const needToSetup = async () => {
    console.log('updateStatus', updateStatus);
    await setUpBusinessPayers();
    await setUpBusinessSubProducts();
    if (updateStatus) {
      return false;
    } else {
      await getConcentrations();
      setValidUntil(openRequest.validUntil);
    }
  };

  const setUpBusinessSubProducts = async () => {
    setLoading(true);
    try {
      const response = await getProductSubProducts(dispatch, openRequest.id);
      setSubProducts(response);
      console.log(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const setUpBusinessPayers = async () => {
    setLoading(true);
    try {
      const response = await getBusinessPayers(
        dispatch,
        openRequest.businessIdentifier,
      );
      setPayers(response);
      console.log(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    needToSetup();
  }, []);

  const daysTillExpiration = validUntil
    ? (new Date(validUntil).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24)
    : 0;
  const [days, setDays] = useState(Math.floor(daysTillExpiration));

  const handleChangeDays = (value: any) => {
    if (value < 0) return;

    setDays(parseInt(value));
    const date = new Date();
    date.setDate(date.getDate() + parseInt(value));
    setValidUntil(date);
  };

  const handleChangeAmount = (value: any) => {
    const cleanValue = value.split('.').join('').split('$').join('');
    if (isNaN(cleanValue)) return;
    if (cleanValue < 0) return;
    setAmount(Number(cleanValue));
  };

  const startValue = 0;

  const tabs = [
    {
      index: 0,
      label: 'Deudores',
      component: (
        <PayersAssignation
          concentrations={stakeholderConcentrations}
          setConcentrations={setStakeholderConcentrations}
          amount={amount}
          payers={payers}
        />
      ),
    },
    {
      index: 0,
      label: 'Subproductos',
      component: (
        <SubPrductAssingnation
          concentrations={subProductConcentrations}
          setConcentrations={setSubProductConcentrations}
          amount={amount}
          subProducts={subProducts}
        />
      ),
    },
  ];

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }
  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <Typography variant="body1">
          Acá debes ingresar el monto de la línea y si tiene concentraciones que
          la limitan
        </Typography>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Grid container spacing={2} alignItems={'top'}>
          <Grid item xs={6}>
            <Typography fontWeight={600} variant="body1">
              Monto total
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Monto"
              type="text"
              value={formatMoney(amount)}
              onChange={(e) => handleChangeAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Días para el vencimiento
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Días"
              type="number"
              value={days}
              onChange={(e) => handleChangeDays(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Fecha
            </Typography>
            <Typography marginTop={3} fontWeight={600} variant="body1">
              {validUntil ? formatDate(validUntil) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <EnhancedTab startValue={startValue} tabs={tabs} title="Asignaciones" />
      </div>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} color="primary">
          Enviar
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default AssignationModule;
