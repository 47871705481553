import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import CampaignIcon from '@mui/icons-material/Campaign';
import moment from 'moment';
import {
  getPaymentNotificationByInvoice,
  reviewPaymentNotification,
} from '../../../../../../../../../../lib/api';
import { formatMoney } from '../../../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';

interface PaymentNotificationsProps {
  invoiceId: any;
  open: boolean;
  setOpen: any;
  fetchInvoices: any;
}

const PaymentNotifications: React.FC<PaymentNotificationsProps> = (
  props: PaymentNotificationsProps,
) => {
  const { invoiceId, open, setOpen, fetchInvoices } = props;
  const dispatch = useDispatch();
  const [paymentNotifications, setPaymentNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const findNotifications = async () => {
    setLoading(true);
    if (!invoiceId) {
      setPaymentNotifications([]);
      return;
    }
    const result = await getPaymentNotificationByInvoice(dispatch, invoiceId);
    setPaymentNotifications(result);
    setLoading(false);
  };

  const markAsRead = async (id: number) => {
    // await markNotificationAsRead(dispatch, id);
    await reviewPaymentNotification(dispatch, id);
    await findNotifications();
    await fetchInvoices();
  };

  useEffect(() => {
    findNotifications();
  }, [invoiceId]);

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen({ invoiceId: null, open: false });
        }}
      >
        <Box sx={{ width: 350, backgroundColor: '#F8FAFC', height: '100%' }}>
          <div style={{ paddingLeft: '23px' }}>
            <HeaderWithActions
              type="pageHeader"
              title="Notificaciones de pago"
              variant="h3"
            />
          </div>
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color="primary" />
            </div>
          )}
          {!loading && (
            <List>
              {paymentNotifications.length === 0 && (
                <ListItem>
                  <ListItemText primary="No hay notificaciones de pago registradas" />
                </ListItem>
              )}
              {paymentNotifications.map((item) => (
                <div key={item.id}>
                  <ListItem>
                    <ListItemIcon>
                      <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formatMoney(item.amount)} - ${moment(item.createdAt).format('DD/MM/YYYY HH:mm')}`}
                      secondary={item.message}
                    />
                  </ListItem>
                  {item.status === 'PENDING' && (
                    <div
                      style={{
                        paddingRight: '10px',
                        paddingBottom: '10px',
                        textAlign: 'right',
                      }}
                    >
                      <CustomButton
                        onClick={() => markAsRead(item.id)}
                        color="secondary"
                      >
                        {' '}
                        Marcar como leído{' '}
                      </CustomButton>
                    </div>
                  )}
                  <Divider variant="inset" component="li" />
                </div>
              ))}
            </List>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default PaymentNotifications;
