import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAvailableDocumentsForCollection } from '../../../../../../../../../../lib/api/collections';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dialog } from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';

const DocumentSelector = (props: any) => {
  const { open, setOpen, handleConfirmSelection } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getAvailableDocumentsForCollection(dispatch);
      setLoading(false);
      setDocuments(response.documentsProcessed);
    } catch (error) {
      setLoading(false);
    }
  };

  const confirmSelection = () => {
    if (!selectedDocuments.length) {
      return alert('Debes seleccionar al menos un documento');
    }
    const id = selectedDocuments[0];
    const document = documents.find((x: any) => x.id === id);
    if (!document) {
      return alert('Documento no encontrado');
    }
    handleConfirmSelection(document);
    setOpen(false);
  };

  const cantSelect = (document: any) => {
    if (selectedDocuments.length === 0) {
      return false;
    }
    if (selectedDocuments.find((id: string) => id === document.id)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Selecciona un documento</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Selecciona un documento para crear una nueva gestión
        </Typography>
        <EnhancedTable
          dataHeaders={[
            {
              label: 'Folio',
              key: 'folio',
            },
            {
              label: 'Cliente',
              key: 'businessName',
            },
            {
              label: 'Deudor',
              key: 'stakeholderName',
            },
            {
              label: 'Monto',
              key: 'amount',
              type: 'money',
            },
          ]}
          data={documents}
          rowsPerPageDefault={10}
          selected={selectedDocuments}
          setSelected={setSelectedDocuments}
          cantSelectRow={cantSelect}
          showActions
        />
        <DialogActions>
          <CustomButton color="secondary" onClick={() => setOpen(false)}>
            Cancelar
          </CustomButton>
          <CustomButton onClick={confirmSelection}>Seleccionar</CustomButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentSelector;
