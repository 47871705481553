import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../../lib/designSystem/Alert';
import { RootState } from '../../../../../../../services/redux/store';
import { validatePermissions } from '../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import ReceivedDetails from './components/details';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../lib/designSystem/HeaderWithActions';
import CreateCreditLineDialog from './components/create';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  fetchAllGiver,
  fetchDetails,
} from '../../../../../../../lib/api/creditRequest';

export interface CreditLine {
  status: string;
  companyReceiverId: number;
  productId: number;
  productName: string;
  description?: string;
}

const dataHeadersDefault = [
  { key: 'id', label: 'ID' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'type', label: 'Producto' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'minutesSinceUpdate', label: 'Estancado', type: 'formatMinutes' },
  { key: 'createdAt', label: 'Creado en', type: 'date-large' },
  { key: 'status', label: 'Estado', type: 'status' },
];

const CreditsRequested: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [workingCapitalRequests, setWorkingCapitalRequests] = useState<any>([]);
  const [filteredWorkingCapitalRequests, setFilteredWorkingCapitalRequests] =
    useState<any>([]);
  const [filter, setFilter] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [shoppingCartType, setShoppingCartType] = useState('');
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [openRequest, setOpenRequest] = useState<CreditLine | null>(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [failedMessage, setFailedText] = useState('');
  const [dataHeaders, setDataHeaders] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const setSuccessOpen = (text: string) => {
    setSuccess(true);
    setSuccessText(text);
  };

  const setFailureOpen = (text: string) => {
    setFailed(true);
    setFailedText(text);
  };
  const pendingRequestsRedux = useSelector(
    (state: RootState) => state.notification.pendingRequests,
  );

  const handleDetails = async (id: number) => {
    setLoading(true);
    try {
      setOpenDialog(false);
      const request = await fetchDetails(dispatch, id);
      console.log(request);
      setOpenRequest(request);
      setLoading(false);
      setShoppingCartType(request.status);
      setOpenShoppingCart(true);
    } catch (error) {
      setLoading(false);
      return alert('error');
    }
  };

  const canShowOpenDetails = (id: number) => {
    const request = workingCapitalRequests.find((x: any) => x.id === id);
    if (!request) return alert('Ha ocurrido un error');

    return true;
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Detalles',
      canShow: canShowOpenDetails,
    },
  ];

  const setUp = async () => {
    setLoading(true);
    console.log(pendingRequestsRedux);
    const workingCapitalRequestsResponse = await fetchAllGiver(dispatch);
    setWorkingCapitalRequests(workingCapitalRequestsResponse);
    setFilteredWorkingCapitalRequests(workingCapitalRequestsResponse);
    // if (validatePermissions(user, 'financing_factoring_read_all')) {
    //   dataHeadersDefault.push({ key: 'recipientName', label: 'Ejecutivo' });
    // }
    setDataHeaders(dataHeadersDefault);

    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => setOpenDialog(true),
      name: 'Solicitar',
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_creditLine_create'),
    },
  ];

  const handleFilter = (value: string) => {
    if (value === '') {
      setFilteredWorkingCapitalRequests(workingCapitalRequests);
      setFilter(null);
      return;
    } else {
      setFilter(value);
      const filtered = workingCapitalRequests.filter((payout: any) => {
        return payout.status === value;
      });
      setFilteredWorkingCapitalRequests(filtered);
    }
  };

  const Filter = (
    <FormControl fullWidth style={{ minWidth: '200px' }}>
      <InputLabel id="filter-label">Filtrar por estado</InputLabel>
      <Select
        labelId="filter-label"
        id="Filtrar por estado"
        fullWidth
        value={filter}
        onChange={(e) => handleFilter(e.target.value as string)}
        label={'Filtrar por estado'}
      >
        <MenuItem value={''}>Todos</MenuItem>
        {[
          ...new Set(
            workingCapitalRequests.map((request: any) => request.status),
          ),
        ].map((status: any) => (
          <MenuItem value={status} key={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title="Acá verás todas los créditos que se han solicitado"
        variant="body1"
        buttons={buttonsData}
        item={Filter}
      />
      <EnhancedTable
        dataHeaders={dataHeaders}
        data={filteredWorkingCapitalRequests}
        rowsPerPageDefault={25}
        showActions
        actions={actions}
        exportToFile="xlsx"
      />
      <ReceivedDetails
        shoppingCartType={shoppingCartType}
        openShoppingCart={openShoppingCart}
        setOpenShoppingCart={setOpenShoppingCart}
        openRequest={openRequest}
        setSelectedRequest={setOpenRequest}
        setUp={setUp}
        setSuccessOpen={setSuccessOpen}
        setFailureOpen={setFailureOpen}
      />
      {openDialog && (
        <CreateCreditLineDialog
          open={openDialog}
          setOpen={setOpenDialog}
          handleDetails={handleDetails}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      )}
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successText}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Oops"
        text={failedMessage}
        severity="error"
      />
    </div>
  );
};

export default CreditsRequested;
