import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import AddInvoicesForm from './invoicesForm';
import AddInvoicesCSV from './csvParse';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import { createDocumentsFromCompany } from '../../../../../../../../lib/api';

const AddInvoicesManually = (props: any) => {
  const {
    open,
    setOpen,
    business,
    subProductSelected,
    handleSearchSuggestedDocuments,
    setSuccessOpen,
  } = props;
  const dispatch = useDispatch();
  const [inputType, setInputType] = useState<string | null>(null);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const uploadInvoices = async () => {
    setLoading(true);
    try {
      const formatInvoicesWithDate = invoices.map((invoice) => {
        return {
          ...invoice,
          issuedDate: new Date(invoice.issuedDate).toISOString(),
          amount: parseFloat(invoice.amount),
        };
      });
      console.log(subProductSelected);
      const response = await createDocumentsFromCompany(dispatch, {
        documents: formatInvoicesWithDate,
        businessIdentifier: business.identifier,
        subProductId: subProductSelected,
      });
      console.log(response);
      setSuccessOpen('Facturas agregadas correctamente.');
      setOpen(false);
      await handleSearchSuggestedDocuments();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog maxWidth="lg" open={open}>
        <DialogTitle>
          <HeaderWithActions title="Agregar facturas" type="dialogHeader" />
        </DialogTitle>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            {!inputType ? (
              <div>
                <Typography variant="body1">
                  Acá podrás agregar facturas de manera manual. Puedes hacerlo
                  ingresando las facturas o dejando el RCV extraído del SII.
                </Typography>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '80%',
                      padding: 30,
                      margin: 'auto',
                    }}
                  >
                    <CustomButton
                      color="primary"
                      onClick={() => {
                        setInputType('manual');
                      }}
                    >
                      Manual
                    </CustomButton>
                    <CustomButton
                      color="primary"
                      onClick={() => {
                        setInputType('sii');
                      }}
                    >
                      RCV
                    </CustomButton>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {inputType === 'manual' ? (
                  <AddInvoicesForm
                    setInvoices={setInvoices}
                    invoices={invoices}
                    business={business}
                  />
                ) : (
                  <AddInvoicesCSV
                    setInvoices={setInvoices}
                    invoices={invoices}
                    business={business}
                  />
                )}
              </div>
            )}
            <div>
              {invoices.length > 0 && (
                <div>
                  <Typography variant="body1">Facturas verificadas:</Typography>
                  <div>
                    <EnhancedTable
                      dataHeaders={[
                        { key: 'folio', label: 'Folio' },
                        { key: 'stakeholderIdentifier', label: 'Rut receptor' },
                        { key: 'stakeholderName', label: 'Nombre receptor' },
                        {
                          key: 'issuedDate',
                          label: 'Fecha emisión',
                        },
                        { key: 'amount', label: 'Monto', type: 'money' },
                      ]}
                      data={invoices}
                      finalSumRow
                    />
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
        )}
        <DialogActions>
          {invoices.length && !loading ? (
            <CustomButton color="primary" onClick={() => uploadInvoices()}>
              Guardar
            </CustomButton>
          ) : null}
          <CustomButton
            onClick={() =>
              inputType && invoices.length ? setInputType(null) : setOpen(false)
            }
            color="secondary"
          >
            Volver
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddInvoicesManually;
