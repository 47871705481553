import React from 'react';
import { useDispatch } from 'react-redux';
import { resendUserEmail } from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';

const ResendUserEmailDialog = (props: any) => {
  const {
    handleCloseDialog,
    setFailedMessage,
    setFailed,
    setLoading,
    loading,
    selectedUser,
    openAddDialog,
    setSuccessText,
    setSuccess,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await resendUserEmail(dispatch, { userId: selectedUser.id });
    } catch (error) {
      setLoading(false);
      setFailed(true);
      setFailedMessage('No se pudo reenviar el email de activación');
      return;
    }
    setLoading(false);
    setSuccess(true);
    setSuccessText(
      'Se ha reenviado el email de actviación al usuario. Recuerda que el usuario debe confirmar su email para poder ingresar a Pruff.',
    );
    handleCloseDialog();
  };

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Dialog
        fullWidth
        open={openAddDialog}
        onClose={() => handleCloseDialog()}
      >
        <DialogTitle>
          <HeaderWithActions
            title="Reenviar link de activación"
            type="dialogHeader"
          />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que deseas reenviar el email de activación al usuario?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton color="primary" onClick={handleSubmit}>
            Enviar
          </CustomButton>
          <CustomButton onClick={() => handleCloseDialog()} color="secondary">
            Salir
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResendUserEmailDialog;
