import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Chip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import { getCollectionManagementById } from '../../../../../../../../../../lib/api/collections';
import { formatDateToLocal } from '../../../../../../../../../../lib/designSystem/Table/cellFormat';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import ContactsDocument from '../../../../../../../../../../lib/common/ContactsDocument';

interface propsType {
  openModal: boolean;
  setOpenModal: any;
  collectionManagementId: number;
  handleComplete: any;
}

interface CollectionManagement {
  id: number;
  collectionManagementOptions: any[];
  folio: string;
  businessName: string;
  businessIdentifier: string;
  stakeholderName: string;
  stakeholderIdentifier: string;
  amount: number;
  dueAmount: number;
  dueDays: number;
  paymentDate: Date;
  completedAt: Date;
  description: string;
  companyId: number;
  createdAt: string;
  type: string;
  totalRemainingNet: number;
  state: string;
  name: string;
  option: string;
  optionName: string;
  updatedAt: string;
  dueDate: Date;
  stakeholderContacts: any[];
  businessContacts: any[];
}

const DetailedCollectionManagement: React.FC<propsType> = (
  props: propsType,
) => {
  const { collectionManagementId, openModal, setOpenModal, handleComplete } =
    props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [collectionManagement, setCollectionManagement] =
    useState<CollectionManagement | null>(null);

  const fetchDetailedManagement = async () => {
    setLoading(true);
    try {
      const result = await getCollectionManagementById(
        dispatch,
        collectionManagementId,
      );
      setCollectionManagement(result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDetailedManagement();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  if (!collectionManagement) return <div></div>;

  return (
    <Dialog maxWidth="md" open={openModal} onClose={() => setOpenModal(false)}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Gestión de cobranza #{collectionManagement.id}</span>
        <Chip
          label={
            collectionManagement.completedAt
              ? 'Completada'
              : 'Pendiente de completar'
          }
          color={collectionManagement.completedAt ? 'success' : 'warning'}
        />
      </DialogTitle>
      <DialogContent style={{ width: '500px' }}>
        <div>
          <Typography fontWeight="bold">Datos del documento</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Folio</Typography>
            <Typography>{collectionManagement.folio}</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Monto documento</Typography>
            <Typography>{collectionManagement.amount}</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Cliente</Typography>
            <Typography>
              {collectionManagement.businessName} (
              {collectionManagement.businessIdentifier})
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Deudor</Typography>
            <Typography>
              {collectionManagement.stakeholderName} (
              {collectionManagement.stakeholderIdentifier})
            </Typography>
          </div>
        </div>
        <div style={{ paddingTop: '24px' }}>
          <Typography fontWeight="bold">Datos de la gestión</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Estado</Typography>
            <Typography>{collectionManagement.state}</Typography>
          </div>
          {collectionManagement.option && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '12px',
              }}
            >
              <Typography>SubEstado</Typography>
              <Typography>{collectionManagement.optionName}</Typography>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Tipo de gestión</Typography>
            <Typography>{collectionManagement.type}</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '12px',
            }}
          >
            <Typography>Vencimiento</Typography>
            <Typography>
              {formatDateToLocal(collectionManagement.dueDate)}
            </Typography>
          </div>
          <div style={{ paddingTop: '12px' }}>
            <Typography fontWeight="bold">Observaciones</Typography>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {collectionManagement.description}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpenModal(false)}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton color="primary" onClick={() => setContactModal(true)}>
          Ver contactos
        </CustomButton>
        {!collectionManagement.completedAt && (
          <CustomButton
            color="success"
            onClick={() => handleComplete(collectionManagementId)}
          >
            Completar
          </CustomButton>
        )}
      </DialogActions>
      {contactModal && (
        <ContactsDocument
          openModal={contactModal}
          setOpenModal={setContactModal}
          businessName={collectionManagement.businessName}
          businessIdentifier={collectionManagement.businessIdentifier}
          stakeholderName={collectionManagement.stakeholderName}
          stakeholderIdentifier={collectionManagement.stakeholderIdentifier}
          businessContacts={collectionManagement.businessContacts}
          stakeholderContacts={collectionManagement.stakeholderContacts}
        />
      )}
    </Dialog>
  );
};

export default DetailedCollectionManagement;
