import React, { useState } from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import CreditSimulator from '../../../../../create/components/Simulator';
import CustomAlert from '../../../../../../../../../../../../../lib/designSystem/Alert';
import { updateCreditRequestQuote } from '../../../../../../../../../../../../../lib/api/creditRequest';

const ShoppingCartInReview = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setUp,
    setSuccessOpen,
    setFailureOpen,
  } = props;
  const [loading, setLoading] = useState(false);
  const [creditDetails, setCreditDetails] = useState<any>(openRequest);
  const [openWarning, setOpenWarning] = useState(false);
  const [editing, setEditing] = useState(false);
  const [amortizationSchedule, setAmortizationSchedule] = useState<any>(
    openRequest.documents,
  );

  const dispatch = useDispatch();

  const handleEdit = () => {
    setOpenWarning(true);
    setEditing(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateCreditRequestQuote(dispatch, {
        id: creditDetails.id,
        amount: Number(creditDetails.amount),
        months: Number(creditDetails.months),
        type: creditDetails.type,
        operationCost: Number(creditDetails.operationCost),
        otherExpenses: Number(creditDetails.otherExpenses),
        operationDate: new Date(creditDetails.operationDate),
        rate: Number(creditDetails.rate),
        arrearRate: Number(creditDetails.arrearRate),
        cuotas: amortizationSchedule,
        paymentDate:
          amortizationSchedule[amortizationSchedule.length - 1].paymentDate,
        warranties: creditDetails.warranties,
      });
      setSuccessOpen('Crédito editado correctamente');
      await setUp();
      setLoading(false);
      setOpenShoppingCart(false);
    } catch (error) {
      setLoading(false);
      setFailureOpen('Error al editar el crédito');
      console.log(error);
    }
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <CreditSimulator
        creditDetails={creditDetails}
        setCreditDetails={setCreditDetails}
        amortizationSchedule={amortizationSchedule}
        setAmortizationSchedule={setAmortizationSchedule}
        editing={editing}
      />
      {!editing && (
        <DialogActions>
          <CustomButton color="warning" onClick={() => handleEdit()}>
            Editar
          </CustomButton>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
        </DialogActions>
      )}
      {editing && (
        <DialogActions>
          <CustomButton color="secondary" onClick={() => setEditing(false)}>
            Cancelar
          </CustomButton>
          <CustomButton color="success" onClick={() => handleSubmit()}>
            Guardar
          </CustomButton>
        </DialogActions>
      )}
      <CustomAlert
        open={openWarning}
        setOpen={setOpenWarning}
        severity="warning"
        title="Atención"
        text="Si guardas los cambios, se borrarán todas las aprobaciones ya realizadas."
        handleClose={() => setOpenWarning(false)}
      />
    </div>
  );
};

export default ShoppingCartInReview;
