import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ClientConfigState } from '../../../services/redux/clientConfigSlice';

interface CenteredCircularProgressProps {
  type?: string;
  relative?: boolean;
  text?: string;
}

const positions: Record<string, string> = {
  half: '75%',
  login: '71%',
  dialog: '50%',
  noLayout: '50%',
  layout: '57%',
};

const fixedSx = (type: string | undefined) => ({
  position: 'fixed', // Fixed position
  ...(type === 'fileLoader' ? { bottom: 10 } : { top: '50%' }),
  ...(type !== 'fileLoader'
    ? type
      ? { left: positions[type] }
      : { left: '57%' }
    : { right: 10 }), // Center horizontally (left or right depending on the type
  transform: 'translate(-50%, -50%)', // Adjust for the progress circle's own size
  zIndex: 1500, // A high zIndex to make it stay above other UI elements
});

const relativeSx = {
  textAlign: 'center',
  padding: '20px 0px',
};

const CenteredCircularProgress: React.FC<CenteredCircularProgressProps> = ({
  type,
  relative,
  text,
}) => {
  const expressConfig = useSelector(
    (state: { clientConfiguration: ClientConfigState }) =>
      state.clientConfiguration.configs,
  );

  // Get custom primary color from expressConfig if available
  const customPrimaryColor = expressConfig?.pruffConnect_styles_mainColor;
  return (
    <Box
      sx={{
        ...(relative ? relativeSx : fixedSx(type)),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        style={{ color: customPrimaryColor ? customPrimaryColor : 'blue' }}
      />
      {text && (
        <Typography
          variant="body1"
          style={{ textAlign: 'center', marginTop: 8, width: '490px' }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default CenteredCircularProgress;
