import React, { useEffect, useState } from 'react';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import { useNavigate, useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import { validatePermissions } from '../../../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../services/redux/authSlice';
import NotificationsCalendar from './components/NotificationsCalendar';
import CollectionsHome from './components/Home';
import CollectionsManagements from './components/Managements';

const Collection = () => {
  const { subPage } = useParams<{ page: string; subPage: string }>();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const tabs: {
    label: string;
    component: any;
    path: string;
    index: number;
    disabled?: boolean;
  }[] = [];
  if (validatePermissions(user, 'collections_view')) {
    tabs.push({
      label: 'Inicio',
      component: <CollectionsHome />,
      path: 'home',
      index: 0,
    });
    tabs.push({
      label: 'Gestiones',
      component: <CollectionsManagements />,
      path: 'management',
      index: 1,
    });
    tabs.push({
      label: 'Calendario',
      component: <NotificationsCalendar />,
      path: 'notificationsCalendar',
      index: 3,
    });
  }

  const [loading, setLoading] = useState(true);
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();

  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/finance/collections/${companyTabs[index].path}`);
  };

  const setUpTabs = async () => {
    const newProfileTabs: any = [...tabs];
    let startValueDefault = 0;
    startValueDefault = newProfileTabs.findIndex(
      (x: any) => x.path === subPage,
    );
    setCompanyTabs(newProfileTabs);
    console.log(newProfileTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUpTabs();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <EnhancedTab
        startValue={startValue}
        tabs={tabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default Collection;
