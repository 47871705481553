import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatDate, formatMoney } from '../../../../../../lib/functions/utils';

//
export const getImageDimensionsFromBase64 = (
  base64: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = base64;
  });
};

export const generateSimulationPDF = async (
  selectedRequest: any,
  userName: string,
  imageBase64: string,
) => {
  const doc = new jsPDF('landscape');

  // Add the title section
  const addTitle = async () => {
    // Add the image
    const imgX = 10; // X position for the image
    const imgY = 5; // Y position for the image
    const imgHeight = 15; // Height of the image

    const { width: naturalWidth, height: naturalHeight } =
      await getImageDimensionsFromBase64(imageBase64);
    const aspectRatio = naturalWidth / naturalHeight;
    const imgWidth = imgHeight * aspectRatio;

    // Load and add the image
    doc.addImage(imageBase64 as any, 'PNG', imgX, imgY, imgWidth, imgHeight);

    // Add the title text
    doc.setFontSize(16);
    doc.text('Detalle Simulación', 148.5, 15, { align: 'center' });

    // Add the date and user
    doc.setFontSize(10);
    doc.text(`Fecha: ${new Date().toLocaleString()}`, 220, 10);
    doc.text(`Usuario: ${userName}`, 220, 15);
  };

  const costRate = selectedRequest.documents.reduce(
    (acc: number, doc: any) => acc + doc.costRate,
    0,
  );
  // Add the summary section
  const addSummary = () => {
    doc.setFontSize(10);

    // Column positions
    const col1X = 10;
    const col2X = 120;
    const col3X = 220;
    const defaultX = 30; // Standard separation between key and value
    const initialY = 30;

    // Column 1
    doc.setFont('helvetica', 'bold');
    doc.text('Ejecutivo', col1X, initialY);
    doc.setFont('helvetica', 'normal');
    doc.text(`: ${selectedRequest.executiveName}`, col1X + defaultX, initialY);

    doc.setFont('helvetica', 'bold');
    doc.text('Rut Cliente', col1X, initialY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${selectedRequest.businessIdentifier}`,
      col1X + defaultX,
      initialY + 5,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Nro. Operación', col1X, initialY + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${String(selectedRequest.id)}`,
      col1X + defaultX,
      initialY + 10,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Tipo Documento', col1X, initialY + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${selectedRequest.subProductName}`,
      col1X + defaultX,
      initialY + 15,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Moneda', col1X, initialY + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(': CLP', col1X + defaultX, initialY + 20);

    doc.setFont('helvetica', 'bold');
    doc.text('Crea Operación', col1X, initialY + 25);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${selectedRequest.executiveName}`,
      col1X + defaultX,
      initialY + 25,
    );

    // Column 2
    doc.setFont('helvetica', 'bold');
    doc.text('Razón Social', col2X, initialY);
    doc.setFont('helvetica', 'normal');
    const shortName =
      selectedRequest.businessName.length > 25
        ? selectedRequest.businessName.substring(0, 25) + '...'
        : selectedRequest.businessName;
    doc.text(`: ${shortName}`, col2X + defaultX, initialY);

    doc.setFont('helvetica', 'bold');
    doc.text('Fecha Negocio', col2X, initialY + 5);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${formatDate(selectedRequest.operationDate)}`,
      col2X + defaultX,
      initialY + 5,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Fecha Cierre', col2X, initialY + 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${selectedRequest.financingAt ? formatDate(selectedRequest.financingAt) : 'No ejecutado'}`,
      col2X + defaultX,
      initialY + 10,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Anticipo', col2X, initialY + 15);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${String(selectedRequest.advance)}%`,
      col2X + defaultX,
      initialY + 15,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Tasa Negocio', col2X, initialY + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `: ${String(selectedRequest.rate)}%`,
      col2X + defaultX,
      initialY + 20,
    );

    if (selectedRequest.arrearRate) {
      doc.setFont('helvetica', 'bold');
      doc.text('Tasa Mora', col2X, initialY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(
        `: ${String(selectedRequest.arrearRate)}%`,
        col2X + defaultX,
        initialY + 25,
      );
    }

    // Column 3
    const alignRight = (text: string, x: number, y: number) => {
      const textWidth = doc.getTextWidth(text);
      doc.text(':', x + 35 - 25, y);
      doc.text(text, x + 35 - textWidth, y);
    };

    doc.setFont('helvetica', 'bold');
    doc.text('Monto Total', col3X, initialY);
    doc.setFont('helvetica', 'normal');
    alignRight(
      `${formatMoney(selectedRequest.amount)}`,
      col3X + defaultX,
      initialY,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Monto Anticipado', col3X, initialY + 5);
    doc.setFont('helvetica', 'normal');
    alignRight(
      `${formatMoney(selectedRequest.amount * (selectedRequest.advance / 100))}`,
      col3X + defaultX,
      initialY + 5,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Monto Retenido', col3X, initialY + 10);
    doc.setFont('helvetica', 'normal');
    alignRight(
      `: ${formatMoney(((100 - selectedRequest.advance) * selectedRequest.amount) / 100)}`,
      col3X + defaultX,
      initialY + 10,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Dif precio', col3X, initialY + 15);
    doc.setFont('helvetica', 'normal');
    alignRight(`${formatMoney(costRate)}`, col3X + defaultX, initialY + 15);

    doc.setFont('helvetica', 'bold');
    doc.text('Comisión + IVA', col3X, initialY + 20);
    doc.setFont('helvetica', 'normal');
    alignRight(
      `${formatMoney((selectedRequest.operationCostFixed + selectedRequest.operationCostVariable) * 1.19)}`,
      col3X + defaultX,
      initialY + 20,
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Total Aplicado', col3X, initialY + 25);
    doc.setFont('helvetica', 'normal');
    alignRight(
      `${formatMoney(selectedRequest.amountToApply)}`,
      col3X + defaultX,
      initialY + 25,
    );
    if (selectedRequest.expenses > 0) {
      doc.setFont('helvetica', 'bold');
      doc.text('Gastos + IVA', col3X, initialY + 30);
      doc.setFont('helvetica', 'normal');
      alignRight(
        `${formatMoney(selectedRequest.expenses * 1.19)}`,
        col3X + defaultX,
        initialY + 30,
      );

      doc.setFont('helvetica', 'bold');
      doc.text('Saldo Giro', col3X, initialY + 35);
      doc.setFont('helvetica', 'normal');
      alignRight(
        `${formatMoney(selectedRequest.amountToDepositTotal)}`,
        col3X + defaultX,
        initialY + 35,
      );
    } else {
      doc.setFont('helvetica', 'bold');
      doc.text('Saldo Giro', col3X, initialY + 30);
      doc.setFont('helvetica', 'normal');
      alignRight(
        `${formatMoney(selectedRequest.amountToDepositTotal)}`,
        col3X + defaultX,
        initialY + 30,
      );
    }
  };

  const addDocumentsTable = () => {
    doc.setFontSize(10);
    doc.text('Documentos', 10, 75);
    autoTable(doc, {
      startY: 80,
      head: [
        [
          'Nro. Doc',
          'Rut Deudor',
          'Razón social',
          'Tipo',
          'Monto',
          'Monto Anticipado',
          'Diferencia precio',
          'Fecha Liberación',
          'Días',
          'Reclamo',
          'Nota Crédito',
        ],
      ],
      body: selectedRequest.documents
        .filter((doc: any) => doc.active !== false)
        .map((doc: any) => [
          String(doc.folio),
          doc.stakeholderIdentifier,
          doc.stakeholderName,
          selectedRequest.subProductName,
          formatMoney(doc.amountDocument),
          formatMoney(doc.amountFinanced),
          formatMoney(doc.costRate),
          formatDate(doc.paymentDate),
          String(doc.daysToCount),
          doc.reclamo,
          doc.notaCredito,
        ]),
      theme: 'grid',
      headStyles: {
        fillColor: '#efefef', // or '#2980b9' in hex format
        textColor: '#1b1b1b',
        fontStyle: 'bold',
        halign: 'left', // horizontal alignment
        valign: 'middle', // vertical alignment
        lineColor: [44, 62, 80],
        fontSize: 9,
      },
      bodyStyles: {
        textColor: [50, 50, 50],
        fontSize: 8,
      },
      styles: {
        font: 'helvetica', // choose the font
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
    });
    doc.setFontSize(10);
  };

  // Add the applications table section
  const addApplicationsTable = () => {
    doc.setFontSize(10);
    const finalY = (doc as any).lastAutoTable.finalY || 110;
    doc.text('Aplicaciones', 10, finalY + 10);
    autoTable(doc, {
      startY: finalY + 15,
      head: [
        [
          'Descripción',
          'N° Operación',
          'N° Documento',
          'Monto Factura',
          'Monto Aplicado',
        ],
      ],
      body: selectedRequest.documentsToApply.map((application: any) => [
        application.type,
        application.factoringRequestId,
        application.folio,
        formatMoney(application.amount),
        formatMoney(application.amountToConciliate),
      ]),
      theme: 'grid',
      headStyles: {
        fillColor: '#efefef', // or '#2980b9' in hex format
        textColor: '#1b1b1b',
        fontStyle: 'bold',
        halign: 'left', // horizontal alignment
        valign: 'middle', // vertical alignment
        lineColor: [44, 62, 80],
        fontSize: 9,
      },
      bodyStyles: {
        textColor: [50, 50, 50],
        fontSize: 8,
      },
      styles: {
        font: 'helvetica', // choose the font
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
    });
  };

  await addTitle();
  addSummary();
  addDocumentsTable();
  // if (selectedRequest.discounts && selectedRequest.discounts.length > 0)
  //   addDiscountsTable();
  console.log(selectedRequest);
  if (
    selectedRequest.documentsToApply &&
    selectedRequest.documentsToApply.length > 0
  ) {
    addApplicationsTable();
  }

  // Save the PDF
  doc.save(`Simulación-${selectedRequest.id}.pdf`);
};
