import { requestHandler } from '../../api';

export const getDailyBook = async (dispatch: any, queryParams: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/accounting/book/daily?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getMajorBook = async (dispatch: any, queryParams: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/accounting/book/major?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFinancialStatementBook8Column = async (
  dispatch: any,
  queryParams: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/accounting/book/8column?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAccountingEntries = async (dispatch: any, queryParams: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/accounting/entries?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createAccountingEntry = async (dispatch: any, entry: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/accounting/entries`,
    dispatch,
    data: entry,
  });
  if (response) return response.data;
};

export const editAccountingEntry = async (dispatch: any, entry: any) => {
  const response = await requestHandler({
    method: 'put',
    url: `internal/accounting/entries/${entry.id}`,
    dispatch,
    data: entry,
  });
  if (response) return response.data;
};

export const getAccountingEntryTypes = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/accounting/entries/types`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAccountingNodes = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/accounting/entries/nodes`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteAccountingEntry = async (dispatch: any, entryId: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/accounting/entries/${entryId}`,
    dispatch,
  });
  if (response) return response.data;
};
