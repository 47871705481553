import { exportToExcel } from '../../../../../../../../lib/designSystem/DataExporter/utils/exportToExcel';
import { ExportProps, Headers, Prospect } from '../../../Prospects.types';

export const exportFullRolling = async ({
  prospectsMainTable,
  monthlyRollingData,
}: ExportProps) => {
  const getAllPayersFactorings = (prospect: Prospect) => {
    const payers: Record<string, any> = {};
    const factorings: Record<string, any> = {};
    const prospectMonthlyData = monthlyRollingData[prospect.businessIdentifier];

    for (const payer12M of prospectMonthlyData.byStakeholder['12M']) {
      payers[payer12M.identifier] = {
        businessName: payer12M.name,
        type: 'Deudor',
        businessIdentifier: payer12M.identifier,
        '12M': payer12M.amount,
        '6M': 0,
        '3M': 0,
        '1M': 0,
      };
    }
    for (const factoring12M of prospectMonthlyData.byFactoring['12M']) {
      factorings[factoring12M.identifier] = {
        businessName: factoring12M.name,
        type: 'Factoring',
        businessIdentifier: factoring12M.identifier,
        '12M': factoring12M.amount,
        '6M': 0,
        '3M': 0,
        '1M': 0,
      };
    }
    for (const payer6M of prospectMonthlyData.byStakeholder['6M']) {
      payers[payer6M.identifier]['6M'] = payer6M.amount;
    }
    for (const factoring6M of prospectMonthlyData.byFactoring['6M']) {
      factorings[factoring6M.identifier]['6M'] = factoring6M.amount;
    }
    for (const payer3M of prospectMonthlyData.byStakeholder['3M']) {
      payers[payer3M.identifier]['3M'] = payer3M.amount;
    }
    for (const factoring3M of prospectMonthlyData.byFactoring['3M']) {
      factorings[factoring3M.identifier]['3M'] = factoring3M.amount;
    }
    for (const payer1M of prospectMonthlyData.byStakeholder['1M']) {
      payers[payer1M.identifier]['1M'] = payer1M.amount;
    }
    for (const factoring1M of prospectMonthlyData.byFactoring['1M']) {
      factorings[factoring1M.identifier]['1M'] = factoring1M.amount;
    }

    const payersSorted = Object.values(payers).sort(
      (a, b) => b['12M'] - a['12M'],
    );
    const factoringsSorted = Object.values(factorings).sort(
      (a, b) => b['12M'] - a['12M'],
    );

    return { payersSorted, factoringsSorted };
  };

  const headers: Headers = [
    { key: 'businessName', label: 'Razón Social' },
    { key: 'type', label: 'Tipo' },
    { key: 'businessIdentifier', label: 'Rut' },
    { key: '12M', label: '12M' },
    { key: '6M', label: '6M' },
    { key: '3M', label: '3M' },
    { key: '1M', label: '1M' },
  ];
  const data: any[] = [];

  for (const prospect of prospectsMainTable) {
    data.push({
      businessName: prospect.businessName,
      type: 'Prospecto',
      businessIdentifier: prospect.businessIdentifier,
      '12M': prospect['12M'],
      '6M': prospect['6M'],
      '3M': prospect['3M'],
      '1M': prospect['1M'],
    });

    let result: any = {};
    // Get three top payers and factorings by total amount per payer
    result = getAllPayersFactorings(prospect);
    for (const payer of result.payersSorted) {
      data.push(payer);
    }
    for (const factoring of result.factoringsSorted) {
      data.push(factoring);
    }
    data.push({});
  }

  await exportToExcel(data, headers, `Prospectos - Completo.xlsx`);
};
