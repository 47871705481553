import React, { useState } from 'react';

import { IconButton, Typography } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';
import { AddCircle, CancelOutlined } from '@mui/icons-material';
import NewMatrixAssignation from './components/newAssignation';
import { LightTooltip } from '../../../../../../../../../../../lib/designSystem/WhiteTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    addContainer: {
      height: '100vh', // Set the sidebar to full height
      backgroundColor: 'ffffff', // Change the background color
      alignItems: 'center',
      width: '100%',
    },
    container: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const LineNodesConfiguration = (props: any) => {
  const {
    setDecisionNodesAssignation,
    decisionNodesAssignation,
    decisionNodes,
    comiteesAvailables,
  } = props;
  const classes = useStyles();
  const [openAddNode, setOpenAddNode] = useState(false);
  const [newNode, setNewNode] = useState({
    id: 0,
    sonIncorrectNodeId: null,
    sonCorrectNodeId: null,
    originNodeId: '',
    value: '',
    type: '',
    description: '',
    name: '',
    fatherNodeDecision: null,
    ranges: [],
  });

  const canDeleteNode = (node: any) => {
    const haveChildren = decisionNodesAssignation.find(
      (x: any) => x.fatherNodeId === node.id,
    );
    if (haveChildren) {
      return false;
    }
    return true;
  };

  const deleteNode = (node: any) => {
    const nodes = decisionNodesAssignation.filter((x: any) => x.id !== node.id);
    setDecisionNodesAssignation(nodes);
  };

  return (
    <div className={classes.container}>
      <div className={classes.addContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">Evaluaciones agregadas</Typography>
          <IconButton onClick={() => setOpenAddNode(true)}>
            <AddCircle color="primary" />
          </IconButton>
        </div>
        <div style={{ padding: '20px 0px' }}>
          {decisionNodesAssignation.map((x: any, index: any) => {
            const name =
              x.name.length > 45 ? x.name.slice(0, 45) + '...' : x.name;
            return (
              <div
                key={index}
                style={{
                  backgroundColor: '#ffffff',
                  padding: '5px 10px',
                  borderRadius: '20px',
                  margin: '10px 0px',
                  border: '1px solid #0F23DF',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <LightTooltip
                    title={
                      <Typography variant="body1" style={{ padding: '10px' }}>
                        {`${x.id} | ${x.name}`}
                      </Typography>
                    }
                    placement="top"
                  >
                    <Typography variant="body1">
                      {x.id} | {name}
                    </Typography>
                  </LightTooltip>

                  {canDeleteNode(x) && (
                    <IconButton onClick={() => deleteNode(x)}>
                      <CancelOutlined />
                    </IconButton>
                  )}
                </div>
                {/* <Typography variant="body1" gutterBottom>
                {x.description}
              </Typography> */}
              </div>
            );
          })}
        </div>

        {decisionNodesAssignation.length === 0 && (
          <div style={{ padding: '20px 0px' }}>
            <Typography variant="body1" gutterBottom>
              No hay nodos agregados
            </Typography>
          </div>
        )}
        {openAddNode && (
          <NewMatrixAssignation
            newNode={newNode}
            setNewNode={setNewNode}
            openAddNode={openAddNode}
            setOpenAddNode={setOpenAddNode}
            decisionNodes={decisionNodes}
            decisionNodesAssignation={decisionNodesAssignation}
            setDecisionNodesAssignation={setDecisionNodesAssignation}
            comiteesAvailables={comiteesAvailables}
          />
        )}
      </div>
    </div>
  );
};

export default LineNodesConfiguration;
