import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import EnhancedTable from '../../designSystem/Table';
import CustomButton from '../../designSystem/Button';
import { getContacts } from '../../api/contacts';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../designSystem/CircularProgress';

interface ContactsDocumentProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  businessName: string;
  businessIdentifier: string;
  stakeholderName: string;
  stakeholderIdentifier: string;
  businessContacts: any[];
  stakeholderContacts: any[];
}

const ContactsDocument: React.FC<ContactsDocumentProps> = ({
  openModal,
  setOpenModal,
  businessIdentifier,
  stakeholderIdentifier,
}) => {
  const [business, setBusiness] = useState<{
    name: string;
    contacts: any[];
  }>({ name: '', contacts: [] });
  const [stakeholder, setStakeholder] = useState<{
    name: string;
    contacts: any[];
  }>({ name: '', contacts: [] });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const query = `identifiers=${businessIdentifier},${stakeholderIdentifier}`;
      const response = await getContacts(dispatch, query);
      const businessResponse = response[businessIdentifier];
      const stakeholderResponse = response[stakeholderIdentifier];
      setBusiness(businessResponse);
      setStakeholder(stakeholderResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog open={openModal} maxWidth="md" onClose={() => setOpenModal(false)}>
      <DialogTitle>Contactos</DialogTitle>
      <DialogContent>
        <div>
          <Typography fontWeight="bold">
            Cliente {business.name} ({businessIdentifier})
          </Typography>
          <EnhancedTable
            data={business.contacts}
            dataHeaders={[
              { key: 'name', label: 'Nombre' },
              { key: 'email', label: 'Email' },
              { key: 'phone', label: 'Teléfono' },
              { key: 'role', label: 'Rol' },
              {
                key: 'invoicing',
                label: 'Facturación',
                type: 'vission',
              },
              { key: 'selected', label: 'Principal', type: 'vission' },
            ]}
          />
        </div>
        <div style={{ paddingTop: '24px' }}>
          <Typography fontWeight="bold">
            Deudor {stakeholder.name} ({stakeholderIdentifier})
          </Typography>
          <EnhancedTable
            data={stakeholder.contacts}
            dataHeaders={[
              { key: 'name', label: 'Nombre' },
              { key: 'email', label: 'Email' },
              { key: 'phone', label: 'Teléfono' },
              { key: 'role', label: 'Rol' },
              {
                key: 'invoicing',
                label: 'Facturación',
                type: 'vission',
              },
              { key: 'selected', label: 'Principal', type: 'vission' },
            ]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpenModal(false)}>
          Cerrar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContactsDocument;
