import React from 'react';
import CollectionsManagementsCreatedByUser from './components/CreatedByUser';
import CollectionsManagementsSuggested from './components/Suggested';

const CollectionsManagements: React.FC = () => {
  return (
    <div>
      <CollectionsManagementsCreatedByUser />

      <CollectionsManagementsSuggested />
      <CollectionsManagementsCreatedByUser completed />
    </div>
  );
};

export default CollectionsManagements;
