import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  Typography,
  Grid,
  Box,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import EnhancedTable from '../../../../../../../../../../../lib/designSystem/Table';
import { formatMoney } from '../../../../../../../../../../../lib/functions/utils';
import { getDueWorkingDay } from '../../../../../../../../../../../lib/functions/dates';
import WarrantyPopper from '../WarrantyDialog';

const CreditSimulator = (props: any) => {
  const {
    creditDetails,
    setCreditDetails,
    amortizationSchedule,
    setAmortizationSchedule,
    editing,
  } = props;
  const [workingDayActive, setWorkingDayActive] = useState<
    'active' | 'inactive'
  >('inactive');

  const calculateAmortization = () => {
    const rate = Number(creditDetails.rate) / 100;
    const totalMonths = Number(creditDetails.months);
    const operationCostPerMonth =
      Number(creditDetails.operationCost) / totalMonths;
    const otherExpensesPerMonth =
      Number(creditDetails.otherExpenses) / totalMonths;

    let balance = creditDetails.amount;
    const schedule: any[] = [];
    let payment = 0;

    // Calcular la diferencia entre operationDate y firstPaymentDate
    const operationDate = new Date(creditDetails.operationDate);
    const firstPaymentDate = new Date(creditDetails.firstPaymentDate);

    if (firstPaymentDate <= operationDate) {
      console.error(
        'La fecha de primer pago debe ser al menos un mes después de la fecha de operación.',
      );
      return;
    }

    const diffInDays = Math.ceil(
      (firstPaymentDate.getTime() - operationDate.getTime()) /
      (1000 * 60 * 60 * 24),
    );

    const extraInterest =
      diffInDays > 30
        ? balance * rate * (diffInDays / 30) // Proporcional a los días extra
        : 0;

    if (extraInterest > 0) {
      balance += extraInterest;
    }

    // Recalcular el pago mensual considerando el balance ajustado
    if (creditDetails.interestType === 'compound') {
      const operationCosts =
        creditDetails.operationCost + creditDetails.otherExpenses;
      const totalToPay = balance + operationCosts;
      payment =
        (totalToPay * rate * Math.pow(1 + rate, totalMonths)) /
        (Math.pow(1 + rate, totalMonths) - 1);
    } else {
      const totalInterest = balance * rate * totalMonths;
      payment = (balance + totalInterest) / totalMonths;
    }

    // Calcular cronograma de pagos desde la primera fecha de pago
    let currentDate = new Date(firstPaymentDate);
    if (workingDayActive === 'active') {
      currentDate = getDueWorkingDay(currentDate);
    }
    for (let i = 1; i <= totalMonths; i++) {
      const interest = balance * rate;
      const principal =
        payment - interest - operationCostPerMonth - otherExpensesPerMonth;
      balance -= principal;

      console.log('currentDate', currentDate);
      schedule.push({
        month: i,
        paymentDate: currentDate.toISOString(),
        balance: Number(Math.max(balance, 0).toFixed(2)),
        amount: Number(payment.toFixed(2)),
        interest: Number(interest.toFixed(2)),
        principal: Number(principal.toFixed(2)),
        operationCost: Number(operationCostPerMonth.toFixed(2)),
      });

      // Avanzar un mes para la próxima cuota

      currentDate.setMonth(currentDate.getMonth() + 1);
      if (workingDayActive === 'active') {
        currentDate = getDueWorkingDay(currentDate);
      }
    }

    setAmortizationSchedule(schedule);
  };

  const handleChangeMoney = (value: string, key: string) => {
    const newValue = value.split('$').join('').split('.').join('');
    setCreditDetails({ ...creditDetails, [key]: Number(newValue) });
  };

  const handleChange = (value: string, key: string) => {
    setCreditDetails({ ...creditDetails, [key]: value });
  };

  const handleChangeRates = (value: string, key: string) => {
    // Solo puede ser numeros y puntos
    if (isNaN(Number(value.replace('.', '')))) return;

    setCreditDetails({ ...creditDetails, [key]: value });
  };

  const minFirstPaymentDate = (operationDate: Date) => {
    const firstPaymentDate = new Date(operationDate);
    firstPaymentDate.setDate(firstPaymentDate.getDate() + 30);
    return firstPaymentDate.toISOString().split('T')[0];
  };

  useEffect(() => {
    calculateAmortization();
  }, [
    creditDetails.amount,
    creditDetails.months,
    creditDetails.operationCost,
    creditDetails.firstPaymentDate,
    creditDetails.rate,
    creditDetails.interestType,
    creditDetails.operationDate,
    workingDayActive,
  ]);

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {editing
          ? 'Ingresa los parámetros del crédito'
          : 'Estos son los datos del crédito'}
      </Typography>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>Tipo de Crédito</InputLabel>
              <Select
                value={creditDetails.type}
                disabled={!editing}
                onChange={(e) =>
                  handleChange(
                    e.target.value as 'WORKING_CAPITAL' | 'CONSUMER',
                    'type',
                  )
                }
                label="Tipo de Crédito"
              >
                <MenuItem value="WORKING_CAPITAL">Capital de Trabajo</MenuItem>
                <MenuItem value="CONSUMER">Consumo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>Método de Cálculo de Interés</InputLabel>
              <Select
                value={creditDetails.interestType}
                disabled={!editing}
                onChange={(e) =>
                  handleChange(
                    e.target.value as 'compound' | 'simple',
                    'interestType',
                  )
                }
                label="Método de Cálculo de Interés"
              >
                <MenuItem value="compound">Interés Compuesto</MenuItem>
                {/* <MenuItem value="simple">Interés Simple</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Fecha de Inicio"
              disabled={!editing}
              type="date"
              value={creditDetails.operationDate}
              onChange={(e) => handleChange(e.target.value, 'operationDate')}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Fecha primer pago"
              type="date"
              disabled={!editing}
              value={creditDetails.firstPaymentDate}
              onChange={(e) => handleChange(e.target.value, 'firstPaymentDate')}
              margin="dense"
              size="small"
              InputProps={{
                inputProps: {
                  min: minFirstPaymentDate(
                    new Date(creditDetails.operationDate),
                  ),
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Monto Total"
              value={formatMoney(creditDetails.amount)}
              disabled={!editing}
              onChange={(e) => handleChangeMoney(e.target.value, 'amount')}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Gastos Operacionales"
              value={formatMoney(creditDetails.operationCost)}
              disabled={!editing}
              onChange={(e) =>
                handleChangeMoney(e.target.value, 'operationCost')
              }
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Otros Gastos"
              value={formatMoney(creditDetails.otherExpenses)}
              disabled={!editing}
              onChange={(e) =>
                handleChangeMoney(e.target.value, 'otherExpenses')
              }
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Meses"
              value={creditDetails.months}
              onChange={(e) => handleChange(e.target.value, 'months')}
              disabled={!editing}
              margin="dense"
              size="small"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Tasa de Interés Mensual (%)"
              value={creditDetails.rate}
              onChange={(e) => handleChangeRates(e.target.value, 'rate')}
              disabled={!editing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Tasa de Interés de Mora (%)"
              value={creditDetails.arrearRate}
              onChange={(e) => handleChangeRates(e.target.value, 'arrearRate')}
              disabled={!editing}
              margin="dense"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>Día Hábil</InputLabel>
              <Select
                value={workingDayActive}
                onChange={(e) =>
                  setWorkingDayActive(e.target.value as 'active' | 'inactive')
                }
                disabled={!editing}
                label="Día Hábil"
              >
                <MenuItem value="active">Activar </MenuItem>
                <MenuItem value="inactive">Desactivado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <WarrantyPopper
              index={0}
              creditRequest={creditDetails}
              setCreditRequest={setCreditDetails}
              canEdit={editing}
            />
          </Grid>
        </Grid>
      </div>
      <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
        Resultados de la simulación |{' '}
        <span style={{ color: 'grey' }}>Descargar</span>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              data={amortizationSchedule}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={formatMoney} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="balance"
                stroke="#8884d8"
                name="Saldo"
              />
              <Line
                type="monotone"
                dataKey="payment"
                stroke="#82ca9d"
                name="Cuota"
              />
              <Line
                type="monotone"
                dataKey="interest"
                stroke="#ffc658"
                name="Interés"
              />
              <Line
                type="monotone"
                dataKey="principal"
                stroke="#ff7300"
                name="Capital"
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ flexGrow: 1, overflow: 'auto', maxHeight: 300 }}>
            <EnhancedTable
              dataHeaders={[
                { key: 'month', label: 'Mes' },
                { key: 'paymentDate', label: 'Pago', type: 'date' },
                { key: 'balance', label: 'Saldo', type: 'money' },
                { key: 'amount', label: 'Cuota', type: 'money' },
                { key: 'interest', label: 'Interés', type: 'money' },
                { key: 'principal', label: 'Capital', type: 'money' },
              ]}
              data={amortizationSchedule}
              finalSumRow
              completeTable
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreditSimulator;
