import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CreditRequestStatus from '../../../../../../../common/CreditRequestStatus';
import EnhancedTable from '../../../../../../../../../../../../../lib/designSystem/Table';

const ShoppingCartAccepted = (props: any) => {
  const { setOpenShoppingCart, openRequest } = props;

  const headers = [
    {
      label: 'Folio',
      key: 'folio',
    },
    {
      label: 'Fecha de pago',
      key: 'paymentDate',
      type: 'date',
    },
    {
      label: 'Monto',
      key: 'amount',
      type: 'money',
    },
    {
      label: 'Mora',
      key: 'arrearAmount',
      type: 'money',
    },
    {
      label: 'Pagado',
      key: 'paidAt',
      type: 'date-colored-ok',
    },
  ];

  return (
    <div>
      <div>
        <CreditRequestStatus
          openRequest={openRequest}
          audienceType="received"
        />
        <EnhancedTable data={openRequest.documents} dataHeaders={headers} />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartAccepted;
