import React from 'react';
import { Typography } from '@mui/material';
import { cellFormat } from '../../../../../../../../lib/designSystem/Table/cellFormat';

const statusSteps: any = {
  sent: {
    Aceptada:
      'Para que está solicitud sea activada, se debe realizar la nómina y luego su giro correspondiente.',
    Activa: 'La solicitud está activa',
  },
  received: {
    Aceptada:
      'Para que está solicitud sea activada, se debe realizar la nómina y luego su giro correspondiente.',
    Activa: 'La solicitud está activa',
  },
};

const CreditRequestStatus = (props: any) => {
  const { openRequest, audienceType } = props;

  return (
    <div>
      <Typography variant="h6" fontWeight="bold">
        Resumen
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '6px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Estado</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.status, 'status')}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0px',
        }}
      >
        <Typography variant="body1">
          <b>Vencimiento</b>
        </Typography>
        <Typography variant="body1">
          {cellFormat(openRequest.paymentDate, 'date')}
        </Typography>
      </div>
      {statusSteps[audienceType][openRequest.status] && (
        <div
          style={{
            padding: '10px 0px',
          }}
        >
          <div>
            <Typography variant="body1">
              <b>Pasos a seguir</b>
            </Typography>

            <Typography variant="body1">
              {statusSteps[audienceType][openRequest.status]}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditRequestStatus;
