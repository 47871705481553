// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserProfile } from '../../lib/api';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux/store';
import HeaderWithActions from '../../lib/designSystem/HeaderWithActions';
import { IconButton, Typography } from '@mui/material';
import EnhancedBox from '../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import CopyIcon from '@mui/icons-material/FileCopy'; // You can choose hanother appropriate icon
import Notifications from './Notifications';
import CustomAlert from '../../lib/designSystem/Alert';
import { copyToClipboard } from '../../lib/functions/utils';

const useStyles = makeStyles(() =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },
    userInfoSection: {
      padding: '32px',
      textAlign: 'center',
    },
    pairSection: {
      padding: '10px',
    },
  }),
);

const UserProfile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({
    name: '',
    email: '',
    encryptedId: '',
    enrollmentLink: '',
  });
  const auth = useSelector((state: RootState) => state.auth);

  const fetchUserProfile = async () => {
    const userResponse = await getUserProfile(dispatch, { id: auth.user?.id });
    setUserProfile(userResponse.data || []);
    setLoading(false);
  };
  useEffect(() => {
    fetchUserProfile();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions buttons={[]} title="Mi cuenta" />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <EnhancedBox>
            <div className={classes.avatarSection}>
              <Avatar className={classes.avatar} />
            </div>
            <div className={classes.userInfoSection}>
              <div className={classes.pairSection}>
                <Typography>
                  <b>Nombre</b>
                </Typography>
                <Typography>{userProfile.name}</Typography>
              </div>
              <div className={classes.pairSection}>
                <Typography>
                  <b>Email</b>
                </Typography>
                <Typography>{userProfile.email}</Typography>
              </div>
            </div>
          </EnhancedBox>
          <EnhancedBox margin="16px 0px">
            <Typography variant="body2">
              {' '}
              <b>Link de enrolamiento: </b>
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px 0px',
              }}
            >
              <IconButton
                onClick={() => copyToClipboard(userProfile.enrollmentLink)}
                size="small"
                aria-label="copy"
              >
                <CopyIcon fontSize="small" />
              </IconButton>
              <Typography>
                {userProfile?.enrollmentLink.slice(0, 45)}
                ...
              </Typography>
            </div>
          </EnhancedBox>
        </Grid>
        <Grid item xs={8}>
          <Notifications setSuccess={setSuccess} setFailure={setFailure} />
        </Grid>
      </Grid>
      <CustomAlert
        open={success.length}
        text={success}
        title="Éxito"
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        text={failure}
        title="Error"
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default UserProfile;
