import {
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { postCollectionManagement } from '../../../../../../../../../../lib/api';
import {
  getCollectionManagementOptionsByStateId,
  getCollectionManagementStates,
} from '../../../../../../../../../../lib/api/collectionManagementStates';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import moment from 'moment';

interface propsType {
  openModal: boolean;
  setOpenModal: any;
  document: any;
  setOpenSucessAlert: any;
  setOpenErrorAlert: any;
  completed?: boolean;
  setUp: any;
}

interface CollectionState {
  collectionManagementOptions: any[];
  companyId: number;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

const NewManagement: React.FC<propsType> = (props: propsType) => {
  const {
    completed,
    document,
    setOpenSucessAlert,
    setOpenErrorAlert,
    setOpenModal,
    setUp,
  } = props;
  const [option, setOption] = useState<any>(null);
  const [description, setDescription] = useState('');
  const [checkFields, setCheckField] = useState(false);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [stateId, setStateId] = useState<number>(0);
  const [stateName, setStateName] = useState('');
  const [stateOptions, setStateOptions] = useState<CollectionState[]>([]);
  const [completedCheckbox, setCompletedCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactMethod, setContactMethod] = useState('');
  const today = moment()
    .utcOffset(new Date().getTimezoneOffset() * -1)
    .format('YYYY-MM-DD');
  const [dueDate, setDueDate] = useState(today);
  const [completedDate, setCompletedDate] = useState(today);

  const dispatch = useDispatch();

  const checkInputFields = () => {
    if (
      !description.length ||
      option === '' ||
      stateId === 0 ||
      contactMethod === '' ||
      dueDate === ''
    ) {
      return true;
    }
    return false;
  };

  const handleSend = async () => {
    setCheckField(checkInputFields());
    if (checkInputFields()) return;
    setLoading(true);
    try {
      await postCollectionManagement(dispatch, {
        description: description,
        type: contactMethod,
        optionId: option,
        documentId: document.id,
        state: stateName,
        dueDate: new Date(dueDate).toISOString(),
        completedDate: completedCheckbox
          ? new Date(completedDate).toISOString()
          : null,
      });
      setOpenModal(false);
      setOpenSucessAlert('Tu gestión de cobranza ha sido creada');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await setUp();
    } catch (error) {
      setOpenErrorAlert('Hubo un error al crear la gestión de cobranza');
      console.log(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSelectState = async (value: number) => {
    setDisabled(true);
    setStateId(value);
    const selectedState = stateOptions.find((element) => element.id === value);
    setStateName(selectedState ? selectedState.name : '');
    await getOptions(value);
    setDisabled(false);
  };

  const getOptions = async (selectedStateId: number) => {
    setLoading(true);
    try {
      const result = await getCollectionManagementOptionsByStateId(
        dispatch,
        selectedStateId,
      );
      setOptions(result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getStatesOptions = async () => {
    setLoading(true);
    try {
      const result = await getCollectionManagementStates(dispatch);
      setStateOptions(result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStatesOptions();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog open={props.openModal} onClose={() => handleClose()}>
      <DialogTitle> Crear nueva gestión de cobranza</DialogTitle>
      <DialogContent>
        <div>
          <Typography variant="body1" fontWeight="bold">
            Documento
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">Folio</Typography>
            <Typography variant="body1">{document.folio}</Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1">Cliente</Typography>
            <Typography variant="body1">{document.businessName}</Typography>
          </div>
        </div>
        <Divider style={{ margin: '12px 0px' }} />

        <Grid container spacing={2} style={{ paddingTop: '12px' }}>
          <Grid item xs={12}>
            <TextField
              error={checkFields && option === ''}
              select
              value={stateId}
              label="Estado gestión"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                handleSelectState(e.target.value as unknown as number)
              }
              disabled={loading}
            >
              {stateOptions.map((element: CollectionState) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={checkFields && option === ''}
              select
              value={option}
              label="Subestado de gestión"
              variant="outlined"
              fullWidth
              onChange={(e) => setOption(e.target.value)}
              disabled={loading || !stateId}
            >
              {options.map((element: any) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Método de contacto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value)}
                disabled={disabled || !stateId || !option}
                label="Método de contacto"
              >
                {['Llamada', 'Correo', 'Whatsapp'].map((element) => {
                  return (
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {!completed && (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Fecha de vencimiento"
                variant="outlined"
                type="date"
                value={dueDate}
                fullWidth
                onChange={(e) => {
                  setDueDate(e.target.value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              error={checkFields && description === ''}
              id="outlined-basic"
              label="Observación"
              variant="outlined"
              fullWidth
              multiline
              disabled={disabled || !stateId || !option}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={completedCheckbox}
                  onChange={(e) => setCompletedCheckbox(e.target.checked)}
                  name="completed"
                  color="primary"
                />
              }
              label="Esta tarea ya fue completada?"
            />
          </Grid>
          {completedCheckbox && (
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Fecha de completado"
                variant="outlined"
                type="date"
                value={completedDate}
                fullWidth
                onChange={(e) => {
                  setCompletedDate(e.target.value);
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => handleClose()}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => handleSend()}
          disabled={
            loading ||
            !stateId ||
            !option ||
            !contactMethod ||
            !description.length ||
            !dueDate.length
          }
        >
          {' '}
          Crear
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewManagement;
