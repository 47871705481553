import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import CustomButton from '../Button';

interface ConfirmAndExecuteDialogProps {
  open: boolean;
  title: string;
  description: string;
  buttonColor: 'primary' | 'error' | 'warning';
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  setOpen: (open: boolean) => void;
}

const ConfirmAndExecuteDialog: React.FC<ConfirmAndExecuteDialogProps> = ({
  open,
  title,
  description,
  buttonColor = 'primary',
  onConfirm,
  onCancel,
  setOpen,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleCancel} color="secondary">
          Cancelar
        </CustomButton>
        <CustomButton onClick={handleConfirm} color={buttonColor}>
          Confirmar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAndExecuteDialog;
